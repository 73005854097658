import React, {useRef} from 'react'
import { components } from 'cng-web-lib'
import { Card, CardContent } from '@material-ui/core'
import TranslationText from '../../TranslatedText'
import FormProperties from './CeCPartyConfig_SavedAddressFormProperties'
import PartyConfigUrls from "../../../../../apiUrls/cec-admin/party-config/PartyConfigUrls";
import SCOAdminApiUrls from "../../../../../apiUrls/SCOAdminApiUrls";


const {
    table: {
        CngServerModeDialogFormTable, useFetchCustomLookup, useDefaultNotification
    }
} = components


const CrudTable = ({ partyId, isViewOnly, showNotification }) => {

    const fetchCustomLookup = useFetchCustomLookup()
    const translatedTextObject = TranslationText()
    const notification = useDefaultNotification(showNotification)

    const cngTableRef = useRef()

    const columns = [
        {
            field: 'code',
            title: translatedTextObject.code
        },
        {
            field: 'firstName',
            title: translatedTextObject.firstName
        },
        {
            field: 'lastName',
            title: translatedTextObject.lastName
        },
        {
            field: 'address',
            title: translatedTextObject.address
        },
        {
            field: 'city',
            title: translatedTextObject.city
        },
        {
            field: 'countryCode',
            title: translatedTextObject.country
        },
        {
            field: 'postCode',
            title: translatedTextObject.postalCode
        },
        {
            field: 'partyTypeId',
            title: translatedTextObject.partyType,
            customLookup: () => {
                return fetchCustomLookup(
                    `${SCOAdminApiUrls.GET_CODE_MASTER_LIST}`,
                    {
                        codeType: 'CEC_PARTY_TYPE'
                    },
                    'content',
                    'name',
                    'id',
                    (error) => notification.error(error.message)
                )
            }
        },
    ]

    return (
        <Card>
            <CardContent>
                <CngServerModeDialogFormTable
                    fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                    disabled={isViewOnly}
                    showNotification={showNotification}
                    tableColumns={columns}
                    pageSize={10}
                    formProperties={{
                        ...FormProperties, formikProps: {
                            ...FormProperties.formikProps, initialValues: {
                                ...FormProperties.formikProps.initialValues, partyId : partyId
                            }
                        }
                    }}
                    toClientDataFormat={(serverData) => {
                        return serverData
                    }}
                    toServerDataFormat={(localDatum) => {
                        return localDatum
                    }}
                    fetch={{
                        url: PartyConfigUrls.SAVED_ADDRESS_GET, body: {
                            partyId: partyId
                        }
                    }}
                    create={{
                        url: PartyConfigUrls.SAVED_ADDRESS_CREATE, body: {
                            partyId: partyId,
                        },
                        onSuccess: () => {
                            notification.success("Create record has succeeded.")
                            if(cngTableRef.current.onQueryChange)
                                cngTableRef.current.onQueryChange()
                        }
                    }}
                    update={{ url: PartyConfigUrls.SAVED_ADDRESS_UPDATE,
                        onSuccess: () => {
                            notification.success("Update record has succeeded.")
                            if(cngTableRef.current.onQueryChange)
                                cngTableRef.current.onQueryChange()
                        }
                    }}
                    del={{ url: PartyConfigUrls.SAVED_ADDRESS_DELETE,
                        onSuccess: () => {
                            notification.success("Delete record has succeeded.")
                            if(cngTableRef.current.onQueryChange)
                                cngTableRef.current.onQueryChange()
                        }
                    }}
                    idAccessor='id'
                    tableRef = {cngTableRef}
                />
            </CardContent>
        </Card>


    )
}


export default CrudTable


