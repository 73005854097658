import {
  Grid,
  Typography,

  makeStyles
} from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  text: {
    color: theme.palette.text.textSecondary,
    fontSize: '16px',
    fontWeight: '700'
  },
  number_grid: {
    color: '#7CE7AC',
    fontSize: '28px',
    fontWeight: '700',
  },
  box_grid: {
    border: '1px solid #006BC929',
    boxShadow: '0px 2px 10px #00000005',
    borderRadius: "12px",
    padding: theme.spacing(1),
    marginRight: "5px",
    background: 'transparent linear-gradient(105deg, #5E81F400 0%, #5E81F40A 100%) 0% 0% no-repeat padding-box',
  },
  flag: {
    width: '1.1em',
    height: '1.1em'
  },
  box: {
    minWidth: '2vw',
    padding: theme.spacing(.25, 1.5, .5)
  }
}))
function TransactionBox(props) {
  const classes = useStyles()

  return (
    <Grid container item spacing={1}>
      <Grid container item spacing={1}>
        <Grid item xs={4} >
          <Grid className={classes.box_grid}>
            <Typography variant='caption' className={classes.text}>
              Total transactions
            </Typography>
            <Grid container spacing={1} wrap='nowrap'>
              <Grid item className={classes.number_grid}>{props.total_transactions}</Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} >
          <Grid className={classes.box_grid}>
            <Typography variant='caption' className={classes.text}>
              Month transactions
            </Typography>
            <Grid container spacing={1} wrap='nowrap'>
              <Grid item className={classes.number_grid}>{props.current_month_transactions}</Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} >
          <Grid className={classes.box_grid}>
            <Typography variant='caption' className={classes.text}>
              Current week transactions
            </Typography>
            <Grid container spacing={1} wrap='nowrap'>
              <Grid item className={classes.number_grid}>{props.current_week_transactions}</Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item spacing={1}>
        <Grid item xs={4} >
          <Grid className={classes.box_grid}>
            <Typography variant='caption' className={classes.text}>
              HS Smart search transactions
            </Typography>
            <Grid container spacing={1} wrap='nowrap'>
              <Grid item className={classes.number_grid}>{props.hsrecommend_transaction}</Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} >
          <Grid className={classes.box_grid}>
            <Typography variant='caption' className={classes.text}>
              eAdvisory transactions
            </Typography>
            <Grid container spacing={1} wrap='nowrap'>
              <Grid item className={classes.number_grid}>{props.eadvisory_transaction}</Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} >
          <Grid className={classes.box_grid}>
            <Typography variant='caption' className={classes.text}>
              Landed cost transactions
            </Typography>
            <Grid container spacing={1} wrap='nowrap'>
              <Grid item className={classes.number_grid}>{props.landedcost_transaction}</Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid >
  )
}

TransactionBox.defaultProps = {
  color: '#7CE7AC'
}

TransactionBox.propTypes = {
  color: PropTypes.string,
}

export default TransactionBox
