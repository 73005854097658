import React from 'react'
import { components, constants, Yup } from 'cng-web-lib'
import TranslationText from '../../TranslatedText'
import { Card, CardContent, Grid } from '@material-ui/core'
import SCOAdminApiUrls from '../../../../../apiUrls/SCOAdminApiUrls'

const {
    form: {
        field: {
            CngTextField, CngLookupAutocompleteField, CngDateField
        }
    }, CngGridItem
} = components


const {
    filter: { EQUAL }
} = constants


function Fields({ ...props }) {
    const { disabled } = props

    const {
        webhookType,
        webhookURL,
        createdAt,
        createdBy,
        updatedAt,
        updatedBy
    } = TranslationText()


    return (<>
            <Grid container spacing={3}>
                <CngGridItem xs={12} sm={12}>
                    <Card>
                        <CardContent>
                            <Grid container>
                                <Grid container spacing={2}>
                                    <CngGridItem xs={12} sm={6}>
                                        <CngLookupAutocompleteField
                                            name='webhookTypeId'
                                            label={webhookType}
                                            disabled={disabled}
                                            required
                                            lookupProps={{
                                                url: SCOAdminApiUrls.GET_CODE_MASTER_LIST,
                                                label: 'name',
                                                value: 'id',
                                                filters: [
                                                    {
                                                        field: 'codeType',
                                                        operator: EQUAL,
                                                        value: 'CEC_WEBHOOK_TYPE'
                                                    }
                                                ]
                                            }}
                                        >
                                        </CngLookupAutocompleteField>
                                    </CngGridItem>

                                    <CngGridItem xs={12} sm={12}>
                                        <CngTextField
                                            name='webhookURL'
                                            label={webhookURL}
                                            disabled={disabled}
                                            required
                                        />
                                    </CngGridItem>

                                    <CngGridItem xs={12} sm={6}>
                                        <CngDateField
                                            name="createdDate"
                                            label={createdAt}
                                            format='YYYY-MM-DDTHH:mm:ss'
                                            disabled={disabled}
                                            hidden={!disabled}
                                        />
                                    </CngGridItem>

                                    <CngGridItem xs={12} sm={6}>
                                        <CngTextField
                                            name='createdBy'
                                            label={createdBy}
                                            disabled={disabled}
                                            hidden={!disabled}
                                        />
                                    </CngGridItem>

                                    <CngGridItem xs={12} sm={6}>
                                        <CngDateField
                                            name="updatedDate"
                                            label={updatedAt}
                                            format='YYYY-MM-DDTHH:mm:ss'
                                            disabled={disabled}
                                            hidden={!disabled}
                                        />
                                    </CngGridItem>

                                    <CngGridItem xs={12} sm={6}>
                                        <CngTextField
                                            name='updatedBy'
                                            label={updatedBy}
                                            disabled={disabled}
                                            hidden={!disabled}
                                        />
                                    </CngGridItem>

                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </CngGridItem>
            </Grid>
        </>

    )
}


function toClientDataFormat(serverData) {
    return serverData
}

function toServerDataFormat(localData) {
    return localData
}

const DEFAULT_INITIAL_VALUES = {
    webhookTypeId: '',
    webhookURL: ''
}

const ValidationSchema = () => {
    return Yup.object({

    })
}

const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES }, makeValidationSchema: ValidationSchema
}


const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties