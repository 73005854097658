import React from 'react'
import Namespace from '../../../constants/locale/Namespace'
import ConnectorConfigKeys from '../../../constants/locale/key/cec/ConnectorConfigKeys'
import { constants, useTranslation } from 'cng-web-lib'

const NAMESPACE = Namespace.CONNECTOR_APPS

const {
  locale: {
    key: {
      UiComponentKeys
    }
  }
} = constants

const ConnectorTranslationText = () => {

  const { translate } = useTranslation([NAMESPACE])

  const moduleTitle = translate(NAMESPACE, ConnectorConfigKeys.CONNECTOR_CONFIGURATION)
  const connectorDetails = translate(NAMESPACE, ConnectorConfigKeys.CONNECTOR_DETAILS)

  //connector details
  const connectorName = translate(NAMESPACE, ConnectorConfigKeys.CONNECTOR_NAME)
  const connectorCode = translate(NAMESPACE, ConnectorConfigKeys.CONNECTOR_CODE)
  const connectorServiceURL = translate(NAMESPACE, ConnectorConfigKeys.CONNECTOR_SERVICE_URL)
  const connectorAppType = translate(NAMESPACE, ConnectorConfigKeys.CONNECTOR_APP_TYPE)

  // connector endpoint
  const connectorEndpoints = translate(NAMESPACE, ConnectorConfigKeys.CONNECTOR_ENDPOINTS)
  const connectorEndpointName = translate(NAMESPACE, ConnectorConfigKeys.CONNECTOR_ENDPOINT_NAME)
  const connectorEndpointUrl = translate(NAMESPACE, ConnectorConfigKeys.CONNECTOR_ENDPOINT_URL)
  const connectorEndpointCode = translate(NAMESPACE, ConnectorConfigKeys.CONNECTOR_ENDPOINT_CODE)

  // connector Credentials
  const connectorCredentials = translate(NAMESPACE, ConnectorConfigKeys.CONNECTOR_CREDENTIALS)
  const credentialName = translate(NAMESPACE, ConnectorConfigKeys.CREDENTIAL_NAME)
  const isActive = translate(NAMESPACE, ConnectorConfigKeys.IS_ACTIVE)
  const key = translate(NAMESPACE, ConnectorConfigKeys.KEY)
  const accountNumber = translate(NAMESPACE, ConnectorConfigKeys.ACCOUNT_NUMBER)
  const password = translate(NAMESPACE, ConnectorConfigKeys.PASSWORD)
  const existingPassword = translate(NAMESPACE, ConnectorConfigKeys.EXISTING_PASSWORD)
  const meterNumber = translate(NAMESPACE, ConnectorConfigKeys.METER_NUMBER)
  const secret = translate(NAMESPACE, ConnectorConfigKeys.SECRET)

  //connection Mappings
  const connectorMappings = translate(NAMESPACE, ConnectorConfigKeys.CONNECTOR_MAPPINGS)
  const connectorMappingName = translate(NAMESPACE, ConnectorConfigKeys.CONNECTOR_MAPPING_NAME)
  const connectorMappingInputSource = translate(NAMESPACE, ConnectorConfigKeys.CONNECTOR_MAPPING_INPUT_SOURCE)
  const connectorMappingOutputSource = translate(NAMESPACE, ConnectorConfigKeys.CONNECTOR_MAPPING_OUTPUT_SOURCE)

  //connection Mapping Items
  const connectorMappingItems = translate(NAMESPACE, ConnectorConfigKeys.CONNECTOR_MAPPING_ITEMS)
  const connectorMappingItemInput = translate(NAMESPACE, ConnectorConfigKeys.CONNECTOR_MAPPING_ITEM_INPUT)
  const connectorMappingItemOutput = translate(NAMESPACE, ConnectorConfigKeys.CONNECTOR_MAPPING_ITEM_OUTPUT)


  const tabLockedMessage = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Tabs.PLEASE_SUBMIT_FIRST,
    {
      title: connectorDetails
    }
  )

  return {
    connectorDetails,
    moduleTitle,
    connectorName,
    connectorCode,
    connectorServiceURL,
    connectorAppType,
    connectorEndpoints,
    connectorEndpointName,
    connectorEndpointUrl,
    connectorEndpointCode,
    connectorCredentials,
    credentialName,
    isActive,
    key,
    accountNumber,
    password,
    existingPassword,
    meterNumber,
    secret,
    connectorMappings,
    connectorMappingName,
    connectorMappingInputSource,
    connectorMappingOutputSource,
    connectorMappingItems,
    connectorMappingItemInput,
    connectorMappingItemOutput,
    tabLockedMessage
  }
}

export default ConnectorTranslationText


