import React from 'react'
import PropTypes from 'prop-types'
import { components } from 'cng-web-lib'
import ConnectorTranslationText from '../ConnectorTranslationText'
import ConnectorMappingItemsApiUrls from '../../../../apiUrls/cec-admin/connector-config/ConnectorMappingItemsApiUrls'
import FormProperties from './FormProperties'
import FormPropertiesForMeasurementMapping from './FormPropertiesForMeasurementMapping'

const {
  table: {
    CngServerModeDialogFormTable, useDefaultNotification
  }
} = components


const CrudTable = ({ mappingId, isViewOnly, showNotification, mappingName }) => {

  const { connectorMappingItemInput, connectorMappingItemOutput } = ConnectorTranslationText()
  const { GET, CREATE, UPDATE, DELETE } = ConnectorMappingItemsApiUrls(mappingId)

  const commonColumns = [{
    field: 'input', title: connectorMappingItemInput
  }, {
    field: 'output', title: connectorMappingItemOutput
  }]

  const getColumnsOfTable = (mappingNameValue) => {
    let columns
    if (mappingNameValue === 'Dimension UOM' || mappingNameValue === 'Weight UOM') {
      columns = [...commonColumns, { field: 'priority.priority', title: 'Priority' }]
    } else {
      columns = commonColumns
    }
    return columns
  }


  const getFormProperties = (mappingNameValue) => {

    if (!(mappingNameValue === 'Dimension UOM' || mappingNameValue === 'Weight UOM')) {
      return {
        ...FormProperties, formikProps: {
          ...FormProperties.formikProps, initialValues: {
            ...FormProperties.formikProps.initialValues, mappingId: mappingId
          }
        }
      }
    } else {
      return {
        ...FormPropertiesForMeasurementMapping, formikProps: {
          ...FormPropertiesForMeasurementMapping.formikProps, initialValues: {
            ...FormPropertiesForMeasurementMapping.formikProps.initialValues, mappingId: mappingId
          }
        }
      }
    }
  }

  return (

    <CngServerModeDialogFormTable
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      disabled={isViewOnly}
      showNotification={showNotification}
      tableColumns={getColumnsOfTable(mappingName)}
      pageSize={10}
      formProperties={getFormProperties(mappingName)}
      toClientDataFormat={(serverData) => {
        return serverData
      }}
      toServerDataFormat={(localDatum) => {
        return localDatum
      }}
      fetch={{
        url: GET, body: {
          mappingId: mappingId
        }
      }}
      create={{
        url: CREATE, body: {
          mappingId: mappingId
        }
      }}
      update={{ url: UPDATE }}
      del={{ url: DELETE }}
      idAccessor='id'
    />


  )
}

CrudTable.propTypes = {
  showNotification: PropTypes.func, mappingId: PropTypes.number, isViewOnly: PropTypes.bool
}

export default CrudTable


