import React from 'react'
import { components } from 'cng-web-lib'

import ProductListFormProperties from './CiaPartyConfig_ProductListViewFormProperties'
import CalistaAdvisoryPartyConfigApiUrls from 'src/apiUrls/CalistaAdvisoryPartyConfigApiUrls'

const { form: { CngViewForm } } = components

function ProductListViewForm({ history, showNotification, id }) {

	return (

		//* Start JSX ----------------------------------------------------------
		<CngViewForm
			fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
			history={history}
			showNotification={showNotification}
			bodySection={
				<ProductListFormProperties.Fields
					disabled={true}
					showNotification={showNotification}
					id={id}
				/>
			}
			formikProps={ProductListFormProperties.formikProps}
			toClientDataFormat={ProductListFormProperties.toClientDataFormat}
			toServerDataFormat={ProductListFormProperties.toServerDataFormat}
			fetch={{
				url: CalistaAdvisoryPartyConfigApiUrls.GET,
				id: id
			}}
		/>
		//* End JSX ------------------------------------------------------------
	)

}

export default ProductListViewForm
