import React, { useState } from 'react'
import { useParams } from 'react-router'
import { Grid, Card, Divider, CardContent } from '@material-ui/core'
import { constants, components, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import CalistaAdvisoryKey from 'src/constants/locale/key/CalistaAdvisory'
import PartySummaryViewForm from './CiaPartyConfig_PartySummaryViewForm'
import CiaConfigEditForm from './tabs/CiaConfig/CiaPartyConfig_CiaConfigEditForm'
import ProductListEditForm from './tabs/ProductList/CiaPartyConfig_ProductListEditForm'

const { card: { CngSimpleCardHeader },
		CngTabs
} = components

const {
	locale: {
	  key: {
		UiComponentKeys,
	  },
	},
  } = constants

function EditPage({ showNotification }) {
	const [lockedOnFirstTab, setLockedOnFirstTab] = useState(true)
	const { id } = useParams()
	const { translate } = useTranslation([Namespace.CALISTA_ADVISORY])
	const translatedTextsObject = makeTranslatedTextsObject()

	//* Translation function ---------------------------------------------------
	function makeTranslatedTextsObject() {

		let title = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.SUB_MODULE_TITLE.CALISTA_ADVISORY_PARTY_CONFIG)
		let partySummarySectionHeader = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.PARTY_SUMMARY)
		let partyConfigSectionHeader = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.SUB_MODULE_TITLE.CALISTA_ADVISORY_PARTY_CONFIG)
		let partyCiaConfigHeader = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.TABS.CIA_CONFIG)
		let partyProductListHeader = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.TABS.PRODUCT_LIST)
		let tabLockedMessage = translate(
			Namespace.UI_COMPONENT,
			UiComponentKeys.Tabs.PLEASE_SUBMIT_FIRST,
			{
			  title: partyCiaConfigHeader
			}
		  )

		return {
			title,
			partySummarySectionHeader,
			partyConfigSectionHeader,
			partyCiaConfigHeader,
			partyProductListHeader,
			tabLockedMessage
		}
	}

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Card>
					<CngSimpleCardHeader title={translatedTextsObject.partySummarySectionHeader} />
					<Divider />
					<CardContent>
						<PartySummaryViewForm
							showNotification={showNotification}
							id={id}
						/>
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12}>
				<Card>
					<CngSimpleCardHeader title={translatedTextsObject.partyConfigSectionHeader} />
					<Divider />
					<CngTabs
						headerColor='primary'
						tabs={[
							{
								tabName: translatedTextsObject.partyCiaConfigHeader,
								tabContent: (
									<CiaConfigEditForm
										showNotification={showNotification}
										id={id}
										onFetchPreSuccess={(data) => {
											if ( data.contractActive != null )
											{
												setLockedOnFirstTab(false)
											}
										}}
									/>
								)
							},
							{
								tabName: translatedTextsObject.partyProductListHeader,
								tabContent: (
									<ProductListEditForm
										showNotification={showNotification}
										id={id}
									/>
								)
							}
						]}
						lockedOnFirstTab={lockedOnFirstTab}
						tabLockedMessage={translatedTextsObject.tabLockedMessage}
					/>
				</Card>
			</Grid>
		</Grid>
		//* End JSX ------------------------------------------------------------

	);

	//* End of function --------------------------------------------------------
}

export default EditPage