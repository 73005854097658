import { Card, CardContent, Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import AccordionHeaderComponent from '../../../common/ui/AccordionHeaderComponent'
import CrudTable from '../connector-variables/CrudTable'
import ConnectorVariablesTranslationText from '../connector-variables/TranslationText'
import ConnectorMappingAddForm from './forms/AddForm'
import ConnectorMappingEditForm from './forms/EditForm'


const {
  CngGridItem
} = components

function AddPage({ history, showNotification }) {

  const location = useLocation()
  const connectorType = location.state.connectorType
  const shipmentProviderId = location.state.shipmentProviderId
  const connectorTypeId = location.state.connectorTypeId
  const [connectorMappingId, setConnectorMappingId] = useState()
  const [mappingDetails, setMappingDetails] = useState()

  const {
    variables
  } = ConnectorVariablesTranslationText()

  return (

    <Grid container spacing={1}>
      <CngGridItem item xs={12}>

        <Card>
          <CardContent>

            {connectorMappingId === undefined

              ?


              <ConnectorMappingAddForm
                history={history}
                showNotification={showNotification}
                connectorType={connectorType}
                shipmentProviderId={shipmentProviderId}
                connectorTypeId={connectorTypeId}
                setConnectorMappingId={setConnectorMappingId}
              />

              :

              <ConnectorMappingEditForm
                history={history}
                showNotification={showNotification}
                connectorRecord={{ connectorApp: { type: connectorTypeId, id: connectorTypeId } }}
                connectorType={connectorType}
                mappingId={connectorMappingId}
                setMappingDetails={setMappingDetails}
              />


            }
          </CardContent>
        </Card>

      </CngGridItem>

      {connectorMappingId === undefined ? null :

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container>
                <AccordionHeaderComponent
                  title={variables} hideMandatory />

              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12}>

                  <CrudTable
                    mappingId={parseInt(connectorMappingId)}
                    isViewOnly={false}
                    showNotification={showNotification}
                    key={mappingDetails}
                  />

                </Grid>
              </Grid>

            </CardContent>
          </Card>
        </Grid>}
    </Grid>)

}

export default AddPage