import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import CalistaAdvisoryKey from 'src/constants/locale/key/CalistaAdvisory'
import CIAApiUrls from 'src/apiUrls/CIAApiUrls'
import { useFormContext } from 'react-hook-form'

import CountryListSelector from './CountryListSelector'

const {
    form: { field: { CngSelectField } },
    CngGridItem
} = components

//* Field default values -------------------------------------------------------
const initialValues = Object.freeze({
    sourceCountryListRuleType: "ALLOW",
    sourceCountryList: '',
})

//* Fields function
const FormBody = ({ id, disabled }) => {
    const { getValues } = useFormContext()
    const { translate } = useTranslation(Namespace.CALISTA_ADVISORY)
    const translatedTextsObject = makeTranslatedTextsObject()
    const countryArray = getValues('sourceCountryList') ? getValues('sourceCountryList').split(',') : []
    const [sourceCountryList, setSourceCountryList] = useState(countryArray ? [...countryArray] : null)

    //* Translation function
    function makeTranslatedTextsObject() {
        let sourceCountryListRuleType = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.SOURCE_COUNTRY_LIST_RULE_TYPE)
        let sourceCountryListLabel = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.SOURCE_COUNTRY_LIST)

        return {
            sourceCountryListRuleType,
            sourceCountryListLabel,
        }
    }

    //* return JSX
    return (

        //* Start JSX
        <Grid container spacing={3}>
            <CngGridItem xs={12} sm={3}>
                <CngSelectField name="sourceCountryListRuleType"
                    label={translatedTextsObject.sourceCountryListRuleType}
                    disabled={disabled}
                    items={[
                        { text: 'ALLOW', value: 'ALLOW' },
                        { text: 'DENY', value: 'DENY' }
                    ]}
                />
            </CngGridItem>

            <CngGridItem xs={12} sm={9}>
                <CountryListSelector 
                    name="sourceCountryList"
                    label={translatedTextsObject.sourceCountryListLabel}
                    disabled={disabled}
                    id={id}
                    countryList={sourceCountryList}
                    setCountryList={setSourceCountryList}
                    url={CIAApiUrls.ORGN_CTRY}
                />
            </CngGridItem>
        </Grid>
        //* End JSX
    )
    //* End of function
}

const SourceCountryList = Object.freeze({
    initialValues: initialValues,
    FormBody: FormBody
})

export default SourceCountryList