import React from 'react'
import { components } from 'cng-web-lib'
import {useParams} from "react-router-dom";
import TranslationText from "../party-config/TranslatedText";
import {Card, CardContent, Divider, Grid} from "@material-ui/core";
import PartySummaryViewForm from "../party-config/party-summary/CeCPartyConfig_PartySummaryViewForm";
import SavedAddressCrudTable from "./tabs/saved-address/CeCPartyConfig_SavedAddressTablePage"
import WebhookCrudTable from "./tabs/webhook/CeCPartyConfig_WebhookTablePage";


const { card: { CngSimpleCardHeader },
    CngTabs
} = components

function EditPage({ showNotification, props }) {

    const { id } = useParams()
    const translatedTextObject = TranslationText()


    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Card>
                    <CngSimpleCardHeader title={translatedTextObject.headerSummary} />
                    <Divider />
                    <CardContent>
                        <PartySummaryViewForm
                            showNotification={showNotification}
                            id={id}
                        />
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12}>
                <Card>
                    <CngSimpleCardHeader title={translatedTextObject.headerPartyConfig} />
                    <Divider />
                    <CngTabs
                        headerColor='primary'
                        tabs={[
                            {
                                tabName: translatedTextObject.savedAddressTab,
                                tabContent: (
                                    <SavedAddressCrudTable partyId={id} isViewOnly={false} showNotification={showNotification} />
                                )
                            },
                            {
                                tabName: translatedTextObject.webhookTab,
                                tabContent: (
                                    <WebhookCrudTable partyId={id} isViewOnly={false} showNotification={showNotification} />
                                )
                            }
                        ]}
                    />
                    {/* </CardContent> */}
                </Card>
            </Grid>
        </Grid>
        //* End JSX ------------------------------------------------------------

    );
    //* End of function --------------------------------------------------------
}

export default EditPage