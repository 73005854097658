import React from 'react'
import { BasePage as CngBasePage, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import CalistaAdvisoryKey from 'src/constants/locale/key/CalistaAdvisory'
import BreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_CalistaAdvisory'
import AddPage from './CiaCountry_AddPage'
import TablePage from './CiaCountry_TablePage'
import ViewPage from './CiaCountry_ViewPage'
import EditPage from './CiaCountry_EditPage'

//* Module Title
function ModuleTitle(){
    const { translate } = useTranslation(Namespace.CALISTA_ADVISORY)
    let title = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.SUB_MODULE_TITLE.CALISTA_ADVISORY_COUNTRY)

    return (title);
}

//* Wrapped Table Page
function WrappedTablePage(props){
    const breadcrumbNameMap = BreadcrumbNameMap()
    return (
        <CngBasePage renderPage={(showSnackbar) => (
            <TablePage showNotification = {showSnackbar} {...props}/>
        )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
            moduleTitle={ModuleTitle()}
        />
    )
}

//* Wrapped View Page
function WrappedViewPage(props) {
    const breadcrumbNameMap = BreadcrumbNameMap()
    return (
        <CngBasePage renderPage={(showSnackbar) => (
            <ViewPage showNotification={showSnackbar} {...props}/>
        )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
            moduleTitle={ModuleTitle()}
        />
    )
}

//* Wrapped Edit Page
function WrappedEditPage(props){
    const breadcrumbNameMap = BreadcrumbNameMap()
    return (
        <CngBasePage renderPage={(showSnackbar) => (
            <EditPage showNotification={showSnackbar} {...props}/>
        )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
            moduleTitle={ModuleTitle()}
        />
    )
}

//* Wrapped Add Page
function WrappedAddPage(props) {
	const breadcrumbNameMap = BreadcrumbNameMap()
	return (
		<CngBasePage renderPage={(showSnackbar) => (
			<AddPage showNotification={showSnackbar} {...props} />
		)}
			extraCngBreadcrumbNameMap={breadcrumbNameMap}
			moduleTitle={ModuleTitle()}
		/>
	)
}

export {
    WrappedTablePage as TablePage,
    WrappedViewPage as ViewPage,
    WrappedEditPage as EditPage,
    WrappedAddPage as AddPage
}