import React from 'react'
import { Grid, Card, CardContent } from '@material-ui/core'
import { components, constants } from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import SCOAdminApiUrls from 'src/apiUrls/SCOAdminApiUrls'
import TranslationText from './TranslationText'

const {
  form: {
    adapter: {
      useFormAdapter: { useFormikContext }
    },
    field: {
      CngTextField,
      CngLookupAutocompleteField

    }
  },
  CngGridItem,
} = components

const {
  filter: { EQUAL },
  NotificationType
} = constants

const DEFAULT_INITIAL_VALUES = ({
  country: "",
  regex: ""
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap, mode }) {
  const translatedTextsObject = TranslationText();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <CngGridItem xs={12} sm={4}>
                <CngLookupAutocompleteField
                  name="country"
                  label={translatedTextsObject.country}
                  disabled={mode == "add" || mode == "edit" ? false : true}
                  lookupProps={{
                    url: SCOAdminApiUrls.GET_COUNTRY_LIST,
                    label: 'name',
                    value: 'code'
                  }}
                >
                </CngLookupAutocompleteField>
              </CngGridItem>
              <CngGridItem xs={12} sm={4}>
                <CngTextField
                  name="regex"
                  label={translatedTextsObject.regex}
                  disabled={disabled}
                />
              </CngGridItem>
            </Grid>
          </CardContent>
        </Card>

      </Grid>
    </Grid>
  )
}


function toClientDataFormat(serverData) {
  return serverData;
}

function toServerDataFormat(localData) {
  return localData;
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
