import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  DataFlattener
} from 'cng-web-lib'

import CecUomConfigApiUrls from 'src/apiUrls/CecUomConfigApiUrls'
import SCOAdminApiUrls from 'src/apiUrls/SCOAdminApiUrls'
import React, { useRef } from 'react'
import TranslationText from './TranslationText'

const {
  table: {
    CngCrudTable,
    useDefaultNotification,
    useFetchCustomLookup

  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  },
} = constants

function TablePage(props) {

  const {
    location: { pathname },
    showNotification
  } = props
  const notification = useDefaultNotification(showNotification)
  const translatedTextsObject = TranslationText();
  const fetchCustomLookup = useFetchCustomLookup();
  const cngTableRef = useRef()

  const fetchCodeMaster = fetchCustomLookup(
    `${SCOAdminApiUrls.GET_CODE_MASTER_LIST}`,
    {//null filter
      "codeType": ["COM_UOM_VOLM", "COM_UOM_WEGH", "COM_UOM_DIMN"]
    },
    'content',
    'name', // label accessor
    'id', // value accessor
    (error) => console.error(error)
  )

  const columns = [
    {
      field: "codeType",
      title: translatedTextsObject.uomType,
      customLookup: () => {
        return fetchCustomLookup(
          `${SCOAdminApiUrls.GET_CODE_MASTER_LIST}`,
          {
            "codeType": "CAL_CEC_UNITS_TYPE",
          },
          'content',
          'name', // label accessor
          'code', // value accessor
          (error) => console.error(error)
        )
      }
    },
    {
      field: "inputUomId",
      title: translatedTextsObject.inputUom,
      customLookup: () => {
        return fetchCodeMaster
      }
    },
    {
      field: "outputUomId",
      title: translatedTextsObject.outputUom,
      customLookup: () => {
        return fetchCodeMaster
      }
    },
    {
      field: "conversion",
      title: translatedTextsObject.conversion,
      filtering: false,
    }
  ]

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              columns={columns}
              idAccessor="id"
              notification={notification}
              addRoute={`${pathname}/add`}
              viewRoute={`${pathname}/view`}
              editRoute={`${pathname}/edit`}
              del={{ url: CecUomConfigApiUrls.DELETE_UOM_CONVERSION }}
              fetch={{ url: CecUomConfigApiUrls.GET_UOM_CONVERSION }}
              cngTaviewRoutebleRef={cngTableRef}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )

}

function toServerDataFormat(localData) {
  const reqData = {
    "data": [{
      "uomType": localData.uomType,
      "inputUom": localData.inputUom,
      "outputUom": localData.outputUom,
      "conversion": localData.conversion
    }]
  }
  return DataFlattener.unflatten(reqData);
}

export default TablePage
