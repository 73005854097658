import React from 'react'
import PropTypes from 'prop-types'
import { components } from 'cng-web-lib'
import ConnectorTranslationText from '../ConnectorTranslationText'
import ConnectorEndpointApiUrls from '../../../../apiUrls/cec-admin/connector-config/ConnectorEndpointApiUrls'
import ConnectorEndpointsFormProperties from './ConnectorEndpointsFormProperties'

const {
  table: {
    CngServerModeDialogFormTable, useDefaultNotification
  }
} = components


const ConnectorEndpointsCrudTable = ({ connectorAppId, isViewOnly, showNotification }) => {

  const { connectorEndpointCode, connectorEndpointName, connectorEndpointUrl } = ConnectorTranslationText()
  const { GET, CREATE, UPDATE, DELETE } = ConnectorEndpointApiUrls(connectorAppId)

  const columnsOfConnectorEndpoints = [{
    field: 'name', title: connectorEndpointName
  }, {
    field: 'code', title: connectorEndpointCode
  }, {
    field: 'endpoint', title: connectorEndpointUrl
  }]

  return (

    <CngServerModeDialogFormTable
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      disabled={isViewOnly}
      showNotification={showNotification}
      tableColumns={columnsOfConnectorEndpoints}
      pageSize={5}
      formProperties={{
        ...ConnectorEndpointsFormProperties, formikProps: {
          ...ConnectorEndpointsFormProperties.formikProps, initialValues: {
            ...ConnectorEndpointsFormProperties.formikProps.initialValues, appId: connectorAppId
          }
        }
      }}
      toClientDataFormat={(serverData) => {
        return serverData
      }}
      toServerDataFormat={(localDatum) => {
        return localDatum
      }}
      fetch={{
        url: GET, body: {
          appId: connectorAppId
        }
      }}
      create={{
        url: CREATE, body: {
          appId: connectorAppId
        }
      }}
      update={{ url: UPDATE }}
      del={{ url: DELETE }}
      idAccessor='id'
    />


  )
}

ConnectorEndpointsCrudTable.propTypes = {
  showNotification: PropTypes.func, connectorAppId: PropTypes.number, isViewOnly: PropTypes.bool
}

export default ConnectorEndpointsCrudTable


