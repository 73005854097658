import { Card, CardContent, Grid } from '@material-ui/core'
import React, { useState } from 'react'
import { components } from 'cng-web-lib'
import ConnectorTranslationText from '../ConnectorTranslationText'
import FormProperties from './FormProperties'
import CecAdminPathMap from '../../../../paths/PathMap_CeCAdmin'
import ConnectorAppsApiUrls from '../../../../apiUrls/cec-admin/connector-config/ConnectorAppsApiUrls'
import AccordionHeaderComponent from '../../../common/ui/AccordionHeaderComponent'
import ConnectorEndpointsCrudTable from '../endpoints/ConnectorEndpointsCrudTable'
import ConnectorCredentialsCrudTable from '../credentials/ConnectorCredentialsCrudTable'
import ConnectorMappingsCrudTable from '../mappings/ConnectorMappingsCrudTable'
import { useLocation } from 'react-router-dom'

const {
  button: {
    CngSecondaryButton
  }, form: {
    CngAddForm, CngEditForm, CngEditFormButtonSection
  }, CngGridItem, CngTabs
} = components


function AddPage({ history, showNotification }) {

  const location = useLocation()
  const [connectorAppId, setConnectorAppId] = useState(location.state && location.state.connectorAppId ? location.state.connectorAppId : '')
  const {
    connectorDetails,
    connectorEndpoints,
    connectorCredentials,
    connectorMappings,
    tabLockedMessage
  } = ConnectorTranslationText()

  const onTabChange = indexOfTheActiveTab => {
    history.replace({
      pathname: location.pathname,
      state: { indexOfTheActiveTab: indexOfTheActiveTab, connectorAppId: connectorAppId }
    })
  }

  const getIndexOfActiveTab = () => {
    return location.state && location.state.indexOfTheActiveTab ? location.state.indexOfTheActiveTab : 0
  }


  return (

    <Grid container spacing={1}>
      <CngGridItem item xs={12}>
        <CngTabs
          activeTabIndex={getIndexOfActiveTab()} onChange={onTabChange}
          lockedOnFirstTab={connectorAppId === ''}
          tabLockedMessage={tabLockedMessage}
          headerColor='primary'
          tabs={[{
            tabName: connectorDetails, tabContent: (<Grid container spacing={3}>

                <Grid item xs={12}>

                  <Card>
                    <CardContent>


                      {connectorAppId === ''
                        ?

                        <CngAddForm
                          fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                          history={history}
                          showNotification={showNotification}
                          bodySection={
                            <FormProperties.Fields
                              showNotification={showNotification}
                            />
                          }
                          formikProps={FormProperties.formikProps}
                          toClientDataFormat={FormProperties.toClientDataFormat}
                          toServerDataFormat={FormProperties.toServerDataFormat}
                          create={{
                            url: ConnectorAppsApiUrls.CREATE,
                            onPostSubmitSuccess: (datum) => {
                              setConnectorAppId(datum.id)
                            }
                          }}
                        />

                        : <CngEditForm
                          fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                          showNotification={showNotification}
                          bodySection={<FormProperties.Fields
                            disabled={false}
                            showNotification={showNotification}
                            id={connectorAppId}
                          />}
                          renderButtonSection={() => (<CngEditFormButtonSection>
                              <CngGridItem>
                                <CngSecondaryButton
                                  onClick={() => {
                                    history.push(CecAdminPathMap.CONNECTOR_APPS_LIST_VIEW)
                                  }}>
                                  Back
                                </CngSecondaryButton>
                              </CngGridItem>
                            </CngEditFormButtonSection>

                          )}
                          formikProps={{
                            ...FormProperties.formikProps
                          }}
                          toClientDataFormat={FormProperties.toClientDataFormat}
                          toServerDataFormat={FormProperties.toServerDataFormat}
                          fetch={{
                            url: `${ConnectorAppsApiUrls.GET}`
                          }}
                          update={{
                            url: ConnectorAppsApiUrls.UPDATE,
                            successRedirect: CecAdminPathMap.CONNECTOR_APPS_LIST_VIEW
                          }}
                          id={connectorAppId}
                        />
                      }


                    </CardContent>
                  </Card>

                </Grid>

                {connectorAppId !== ''
                  ?
                  <>

                    <Grid item xs={12}>
                      <Card>
                        <CardContent>
                          <Grid container>
                            <AccordionHeaderComponent
                              title={connectorEndpoints} hideMandatory />

                          </Grid>

                          <Grid container spacing={3}>
                            <Grid item xs={12}>

                              <ConnectorEndpointsCrudTable
                                connectorAppId={parseInt(connectorAppId)}
                                isViewOnly={false}
                                showNotification={showNotification}
                              />

                            </Grid>
                          </Grid>

                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item xs={12}>

                      <Card>
                        <CardContent>
                          <Grid container>
                            <AccordionHeaderComponent
                              title={connectorCredentials} hideMandatory />

                          </Grid>

                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <ConnectorCredentialsCrudTable
                                connectorAppId={parseInt(connectorAppId)}
                                isViewOnly={false}
                                showNotification={showNotification}
                              />
                            </Grid>
                          </Grid>

                        </CardContent>
                      </Card>
                    </Grid>

                  </>

                  : null
                }


              </Grid>


            )
          }

            , {
              tabName: connectorMappings, tabContent: (<Grid container spacing={3}>

                <Grid item xs={12}>

                  <Card>
                    <CardContent>
                      <Grid container>
                        <AccordionHeaderComponent
                          title={connectorMappings} hideMandatory />

                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <ConnectorMappingsCrudTable
                            connectorAppId={parseInt(connectorAppId)}
                            isViewOnly={false}
                            showNotification={showNotification}
                          />
                        </Grid>
                      </Grid>

                    </CardContent>
                  </Card>
                </Grid>

              </Grid>)
            }


          ]}
        />


      </CngGridItem>
    </Grid>)
}

export default AddPage