import CodeMaintainanceApi from 'src/views/common/api/CodeMatainanceApi'
import Utils from 'src/views/common/utils/Utils'
import { constants } from 'cng-web-lib'

const { CodeMaintenanceType } = constants

export function populatePortDesc(origData, fetchRecords, setPortcodeToDescList) {
    const combinedCodes = origData.map((obj) => obj.placeOfDeliveryCode).concat(origData.map((obj) => obj.placeOfReceiptCode))

    if (!Utils.isEmptyString(combinedCodes)) {
        CodeMaintainanceApi.fetchCodeMaintainance(
            fetchRecords,
            CodeMaintenanceType.PORT,
            [
                {
                    field: 'code',
                    operator: 'in',
                    value: combinedCodes
                }
            ],
            false,
            (data) => {
                const codeToDescription = {}
                data.forEach((item) => {
                    codeToDescription[item.code] = item.descriptionEn
                })
                setPortcodeToDescList(codeToDescription)
            }
        )
    }
}
