import React from 'react'
import ValidationSchema from './ValidationSchema'
import { components } from 'cng-web-lib'
import ConnectorTranslationText from '../ConnectorTranslationText'
import { Card, CardContent, Grid } from '@material-ui/core'
import AccordionHeaderComponent from '../../../common/ui/AccordionHeaderComponent'


const {
  form: {
    field: {
      CngTextField
    }
  }, CngGridItem
} = components


function Fields({ ...props }) {

  const { disabled } = props
  const {
    connectorMappingItems, connectorMappingItemInput, connectorMappingItemOutput
  } = ConnectorTranslationText()


  return (<>
      <Grid container spacing={3}>
        <CngGridItem xs={12} sm={12}>
          <Card>
            <CardContent>
              <Grid container>

                <AccordionHeaderComponent
                  title={connectorMappingItems} />

                <Grid container spacing={2}>
                  <CngGridItem xs={12} sm={6}>
                    <CngTextField
                      isRequired
                      name='input'
                      label={connectorMappingItemInput}
                      disabled={disabled}
                    />

                  </CngGridItem>

                  <CngGridItem xs={12} sm={6}>
                    <CngTextField
                      isRequired
                      name='output'
                      label={connectorMappingItemOutput}
                      disabled={disabled}
                    />
                  </CngGridItem>


                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </CngGridItem>
      </Grid>
    </>

  )
}


function toClientDataFormat(serverData) {
  return serverData
}

function toServerDataFormat(localData) {
  return localData
}

const COMMON_DEFAULT_INITIAL_VALUES = {
  input: '', output: ''
}

const FORMIK_PROPS_NON_MEASUREMENT_MAPPING = {
  initialValues: { ...COMMON_DEFAULT_INITIAL_VALUES }, makeValidationSchema: ValidationSchema
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS_NON_MEASUREMENT_MAPPING,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties