import React, { useState } from 'react'
import { ValidationSchemaForCourierConfig, ValidationSchemaForCustomerConfig } from '../ValidationSchema'
import { components, constants } from 'cng-web-lib'
import TranslationText from '../TranslationText'
import { Card, CardContent, Grid } from '@material-ui/core'
import AccordionHeaderComponent from '../../../../common/ui/AccordionHeaderComponent'
import ConnectorAppsApiUrls from '../../../../../apiUrls/cec-admin/connector-config/ConnectorAppsApiUrls'
import ConnectorEndpointApiUrls from '../../../../../apiUrls/cec-admin/connector-config/ConnectorEndpointApiUrls'
import ConnectorCredentialsApiUrls from '../../../../../apiUrls/cec-admin/connector-config/ConnectorCredentialsApiUrls'
import { useFormContext } from 'react-hook-form'
import ConnectorType from '../../../../../constants/cec/ConnectorType'


const {
  filter: {
    EQUAL
  }
} = constants

const {
  form: {
    field: {
      CngLookupAutocompleteField
    }
  }, CngGridItem
} = components

function Fields({ ...props }) {

  const { disabled, connectorRecord, connectorType, connectorTypeId } = props
  const {
    connectorName,
    endpointName,
    credentialName,
    courierConnectorConfigurations,
    customerConnectorConfigurations,
    fulfilmentConnectorConfigurations
  } = TranslationText()

  const { setValue, watch, getValues } = useFormContext()

  const connectorTypeIdValue = connectorTypeId ? connectorTypeId : connectorRecord ? connectorRecord.connectorApp.type : null
  const [connectorId, setConnectorId] = useState(getValues('connectorId'))
  const filterByAppId = connectorId ? [{
    field: 'appId', operator: EQUAL, value: connectorId
  }] : []

  const getTitleText = () => {
    let title
    switch (connectorType) {
      case ConnectorType.ShipperApplication:
        title = courierConnectorConfigurations
        break
      case ConnectorType.CustomerApplication:
        title = customerConnectorConfigurations
        break
      case ConnectorType.FulfilmentApplication:
        title = fulfilmentConnectorConfigurations
        break
      default:
        title = ''
    }
    return title
  }

  return (<>
      <Grid container spacing={3}>
        <CngGridItem xs={12} sm={12}>
          <Card>
            <CardContent>
              <Grid container>

                <AccordionHeaderComponent
                  title={getTitleText()} />

                <Grid container spacing={2}>
                  <CngGridItem xs={12} sm={6}>

                    <CngLookupAutocompleteField
                      name='connectorId'
                      label={connectorName}
                      disabled={disabled}
                      onChange={(newValue) => {
                        setValue('endpointId', '')
                        setValue('credentialId', '')
                        setConnectorId(newValue)
                      }}
                      lookupProps={{
                        url: ConnectorAppsApiUrls.GET, label: 'name', value: 'id', filters: [{
                          field: 'type', operator: EQUAL, value: connectorTypeIdValue
                        }]
                      }}
                      isRequired
                    />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={6}>
                    <CngLookupAutocompleteField
                      key={connectorId}
                      name='endpointId'
                      label={endpointName}
                      disabled={disabled || !watch('connectorId')}
                      lookupProps={{
                        url: ConnectorEndpointApiUrls(connectorId).GET,
                        label: 'name',
                        value: 'id',
                        filters: filterByAppId
                      }}
                      isRequired
                    />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={6} shouldHide={connectorType === ConnectorType.CustomerApplication}>
                    <CngLookupAutocompleteField
                      name='credentialId'
                      key={connectorId}
                      label={credentialName}
                      disabled={disabled || !watch('connectorId')}
                      lookupProps={{
                        url: ConnectorCredentialsApiUrls(connectorId).GET,
                        label: 'name',
                        value: 'id',
                        filters: filterByAppId
                      }}
                      isRequired={connectorType === ConnectorType.ShipperApplication}
                    />
                  </CngGridItem>

                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </CngGridItem>
      </Grid>
    </>

  )
}

const toClientDataFormat = serverData => serverData

const toServerDataFormat = localData => localData

const COMMON_DEFAULT_INITIAL_VALUES = {
  connectorId: '', endpointId: ''
}

const DEFAULT_INITIAL_VALUES_ONLY_COURIER_CONFIG = { credentialId: '' }

const FORMIK_PROPS_FOR_COURIER_CONFIG = {
  initialValues: { ...COMMON_DEFAULT_INITIAL_VALUES, ...DEFAULT_INITIAL_VALUES_ONLY_COURIER_CONFIG },
  makeValidationSchema: ValidationSchemaForCourierConfig
}

const FORMIK_PROPS_FOR_CUSTOMER_CONFIG = {
  initialValues: { ...COMMON_DEFAULT_INITIAL_VALUES }, makeValidationSchema: ValidationSchemaForCustomerConfig
}

const FormProperties = Object.freeze({
  formikPropsForCourierConfig: FORMIK_PROPS_FOR_COURIER_CONFIG,
  formikPropsForCustomerConfig: FORMIK_PROPS_FOR_CUSTOMER_CONFIG,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties