import React, { useState, useEffect } from 'react'
import { Grid, Card, CardContent } from '@material-ui/core'
import { components, constants } from 'cng-web-lib'
import { useFormContext, useWatch } from "react-hook-form"
import makeValidationSchema from './MakeValidationSchema'
import SCOAdminApiUrls from 'src/apiUrls/SCOAdminApiUrls'
import TranslationText from './TranslationText'

const {
  form: {
    adapter: {
      useFormAdapter: { useFormikContext }
    },
    field: {
      CngTextField,
      CngLookupAutocompleteField

    }
  },
  CngGridItem,
} = components

const {
  filter: { EQUAL },
  NotificationType
} = constants

const DEFAULT_INITIAL_VALUES = ({
  codeType: "",
  inputUomId: "",
  outputUomId: "",
  conversion: ""
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap, mode }) {
  const { setFieldValue } = useFormikContext()
  const translatedTextsObject = TranslationText();
  const [uomTypeOption, setUomTypeOption] = useState([])
  const uomValue = useWatch({ name: 'codeType' })


  useEffect(() => {
    // This is refresh uomTypeOption
  }, [uomTypeOption])


  const handleUomTypeChange = (val) => {
    setFieldValue('inputUomId', '')
    setFieldValue('outputUomId', '')
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <CngGridItem xs={12} sm={4}>
                <CngLookupAutocompleteField
                  onChange={(val) => {
                    setUomTypeOption('COM_UOM_' + val);
                    handleUomTypeChange(val);
                  }}
                  name="codeType"
                  label={translatedTextsObject.uomType + " *"}
                  disabled={mode == "add" ? false : true}
                  lookupProps={{
                    url: SCOAdminApiUrls.GET_CODE_MASTER_LIST,
                    label: 'name',
                    value: 'code',
                    filters: [
                      {
                        field: 'codeType',
                        operator: EQUAL,
                        value: 'CAL_CEC_UNITS_TYPE'
                      }
                    ]
                  }}
                >
                </CngLookupAutocompleteField>
              </CngGridItem>
              <CngGridItem xs={12} sm={4}>
                <CngLookupAutocompleteField
                  name="inputUomId"
                  label={translatedTextsObject.inputUom + " *"}
                  key={uomTypeOption}
                  // disabled={mode == "add" ? false : true}
                  disabled={mode == "add" && uomValue ? false : true}
                  lookupProps={{
                    url: SCOAdminApiUrls.GET_CODE_MASTER_LIST,
                    label: 'name',
                    value: 'id',
                    filters: [
                      {
                        field: 'codeType',
                        operator: EQUAL,
                        value: uomTypeOption
                      }
                    ]
                  }}
                >
                </CngLookupAutocompleteField>
              </CngGridItem>
              <CngGridItem xs={12} sm={4}>
                <CngLookupAutocompleteField
                  name="outputUomId"
                  key={uomTypeOption}
                  label={translatedTextsObject.outputUom + " *"}
                  // disabled={mode == "add" ? false : true}
                  disabled={mode == "add" && uomValue ? false : true}
                  lookupProps={{
                    url: SCOAdminApiUrls.GET_CODE_MASTER_LIST,
                    label: 'name',
                    value: 'id',
                    filters: [
                      {
                        field: 'codeType',
                        operator: EQUAL,
                        value: uomTypeOption
                      }
                    ]
                  }}
                >
                </CngLookupAutocompleteField>

              </CngGridItem>
            </Grid>

            <Grid container spacing={3}>
              <CngGridItem xs={12} sm={4}>
                <CngTextField
                  name="conversion"
                  label={translatedTextsObject.conversion + " *"}
                  disabled={disabled}
                  type='number'
                />
              </CngGridItem>
            </Grid>
          </CardContent>
        </Card>

      </Grid>
    </Grid>
  )
}


function toClientDataFormat(serverData) {
  return serverData;
}

function toServerDataFormat(localData) {
  return localData;
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat,

})

export default FormProperties
