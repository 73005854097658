import React from 'react'
import { useParams } from 'react-router'
import { components } from 'cng-web-lib'

import FormProperties from './CiaFtaMapping_FormProperties'
import CalistaAdvisoryFtaMappingApiUrls from 'src/apiUrls/cia-admin/CalistaAdvisoryFtaMappingApiUrls'

const { form: { CngEditForm } } = components

function EditPage({ history, showNotification }) {

    const { id } = useParams()

    return (
        <CngEditForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            history={history}
            showNotification={showNotification}
            bodySection={
                <FormProperties.Fields
                    disabled={false}
                    isNotEditable={true}
                    showNotification={showNotification}
                    id={id}
                />
            }
            formikProps={FormProperties.formikProps}
            toClientDataFormat={FormProperties.toClientDataFormat}
            toServerDataFormat={FormProperties.toServerDataFormat}
            fetch={{ url: CalistaAdvisoryFtaMappingApiUrls.GET }}
            update={{ url: CalistaAdvisoryFtaMappingApiUrls.PUT }}
            id={id}
        />
    )
}

export default EditPage