import { Grid } from '@material-ui/core'
import React from 'react'
import { components, useTranslation } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import CecAccountEditForm from './CecAccountEditPage'
import Namespace from 'src/constants/locale/Namespace'
import CecAccountKeys from 'src/constants/locale/key/CecAccount'

const { CngTabs } = components

function EditPage({ history, showNotification }) {
	const { accountNumber } = useParams()
  const { translate } = useTranslation([Namespace.CEC_ACCOUNT])

  const translatedTextsObject = makeTranslatedTextsObject()
  
  function makeTranslatedTextsObject() {
    let cecAccount = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.TITLE
    )
    let cecAccountEditPage = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.CEC_ACCOUNT_EDIT
    )

    return {
      cecAccount,
      cecAccountEditPage
    }
  }

  return (

  <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.cecAccountEditPage,
              tabContent: (
                <CecAccountEditForm
                  showNotification={showNotification}
                  accountNumber={accountNumber}
                  history={history}
                />
              )
            }
          ]}
        />
      </Grid>
    </Grid>
  );
}

export default EditPage