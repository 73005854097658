import React from 'react'
import {Doughnut} from 'react-chartjs-2';


export default function PieChart(props) {

    const barChartColor = [ '#7CE7AC', '#F4BE5E', '#40E1FA']

    const getDataSet = () => {
        let dataSet = []
        if(props.graphData.data[props.module] != null) {
            props.graphData.data[props.module].forEach((d, idx) => {
                if(d.count != null)
                    dataSet.push(d.count.reduce((a, b) => a + b, 0))
            })
        }

        return dataSet
    }

    const getBgColor = () => {
        let colorList = []
        for(let i = 0 ; i < props.graphData.dataLabel.length; i++) {
            colorList.push(barChartColor[i])
        }

        return colorList
    }

    const getLabelData = () => {
        let labels = []
        let dataset = getDataSet()
        for(let i = 0 ; i < props.graphData.dataLabel.length; i++) {
            let label = props.graphData.dataLabel[i].concat(' - ', dataset[i])
            labels.push(label)
        }

        return labels
    }

    const data = {
        labels : getLabelData() ,
        datasets: [
            {
                data: getDataSet(),
                backgroundColor: getBgColor()
            }
        ],
    }

    const legend = {
        position: 'bottom',
        align: 'start'
    }

    const options = {
        responsive: true
    }

    return (
        <Doughnut options={options} data={data} legend={legend} />
    )
}
