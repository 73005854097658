import { Yup ,constants} from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants
const today = new Date();
  today.setHours(0, 0, 0, 0);
function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  

  return Yup.object(
    {
    accountNumber: Yup.string().required(requiredMessage).matches(/^[a-zA-Z0-9_., ]+$/,"Cannot contain special characters like )(@*:etc.").max(255,"Max length allowed is 255").min(10,"Min length required is 10").nullable(),
    displayName: Yup.string().required(requiredMessage).matches(/^[a-zA-Z0-9_., ]+$/,"Cannot contain special characters like )(@*:etc.").max(255,"Max length allowed is 255").min(3,"Min length required is 3").nullable(),
    street: Yup.string().required(requiredMessage).max(1000,"Max length allowed is 1000").min(10,"Min length required is 10").nullable(),
    city: Yup.string().max(255,"Max length allowed is 255").nullable(),
    postcode: Yup.string().required(requiredMessage).max(20,"Max length allowed is 20").min(3,"Min length required is 3").nullable(),
    addressTypeId: Yup.string().required(requiredMessage).nullable(),
    stateId: Yup.string().nullable(),
    status: Yup.string().required(requiredMessage).nullable()
    })  
}

export default makeValidationSchema
