import {  Grid } from '@material-ui/core'
import React   ,{useState} from 'react'
import { components,useTranslation,constants } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import CecAccountKeys from 'src/constants/locale/key/CecAccount'
import CecAccountAddForm from './CecAccountAddPage'
import CecAccountEditForm from './CecAccountEditPage'



const {
  locale: {
    key: {
      UiComponentKeys,
    },
  },
} = constants

const { CngTabs } = components


function AddPage({ history, showNotification }) {
  const [secretKey,setSecretKey] = useState('')
  const { translate } = useTranslation([
    Namespace.CEC_ACCOUNT,
    Namespace.UI_COMPONENT
  ])
 
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let cecAccount = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.CEC_ACCOUNT_TITLE
    )
    let tabLockedMessage = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Tabs.PLEASE_SUBMIT_FIRST,
      {
        title: cecAccount
      }
    )

    return {
      cecAccount,
      tabLockedMessage
    }
  }
 
function makeCecAccountAddForm() {
  
  if (secretKey === '') {
    return (
      <CecAccountAddForm
        history={history}
        showNotification={showNotification}
        onPostSubmitSuccess={(datum) => {
          setSecretKey(datum.secretKey)
        }}
      />
    )
  } else {
    return (
      <CecAccountEditForm
        history={history}
        showNotification={showNotification}
        secretKey={secretKey}
      />
    )
  }
}

return (
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <CngTabs
        headerColor='primary'
        tabs={[
          {
            tabName: translatedTextsObject.cecAccount,
            tabContent: makeCecAccountAddForm()
          },
        ]}
      />
    </Grid>
  </Grid>
)
}


export default AddPage
