import React from 'react'
import { Yup } from 'cng-web-lib'

const ValidationSchema = () => {

  return Yup.object({
    // partyId: Yup.string().require('Party Name is required.')
  })
}

export default ValidationSchema