import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import CalistaAdvisoryKey from 'src/constants/locale/key/CalistaAdvisory'
import CIAApiUrls from 'src/apiUrls/CIAApiUrls'
import { useFormContext } from 'react-hook-form'

import CountryListSelector from './CountryListSelector'

const {
    form: { field: { CngSelectField } },
    CngGridItem
} = components

//* Field default values -------------------------------------------------------
const initialValues = Object.freeze({
    destCountryListRuleType: "ALLOW",
    destCountryList: "",
})

//* Fields function
const FormBody = ({ id, disabled }) => {
    const { getValues } = useFormContext()
    const { translate } = useTranslation(Namespace.CALISTA_ADVISORY)
    const translatedTextsObject = makeTranslatedTextsObject()
    const countryArray = getValues('destCountryList') ? getValues('destCountryList').split(',') : []
    const [destCountryList, setDestCountryList] = useState(countryArray ? [...countryArray] : null)

    //* Translation function
    function makeTranslatedTextsObject() {
        let destCountryListRuleType = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.DEST_COUNTRY_LIST_RULE_TYPE)
        let destCountryListLabel = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.DEST_COUNTRY_LIST)

        return {
            destCountryListRuleType,
            destCountryListLabel,
        }
    }

    //* return JSX
    return (

        //* Start JSX
        <Grid container spacing={3}>
            <CngGridItem xs={12} sm={3}>
                <CngSelectField name="destCountryListRuleType"
                    label={translatedTextsObject.destCountryListRuleType}
                    disabled={disabled}
                    items={[
                        { text: 'ALLOW', value: 'ALLOW' },
                        { text: 'DENY', value: 'DENY' }
                    ]}
                />
            </CngGridItem>

            <CngGridItem xs={12} sm={9}>
                <CountryListSelector 
                    name="destCountryList"
                    label={translatedTextsObject.destCountryListLabel}
                    disabled={disabled}
                    id={id}
                    countryList={destCountryList}
                    setCountryList={setDestCountryList}
                    url={CIAApiUrls.DEST_CTRY}
                />
            </CngGridItem>
        </Grid>
        //* End JSX
    )
    //* End of function
}

const DestinationCountryList = Object.freeze({
    initialValues: initialValues,
    FormBody: FormBody
})

export default DestinationCountryList