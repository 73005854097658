import React, { useState } from 'react'
import { Grid, Divider, Typography } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'
import { useFormContext } from 'react-hook-form'

import Namespace from 'src/constants/locale/Namespace'
import CalistaAdvisoryKey from 'src/constants/locale/key/CalistaAdvisory'

import TransactionLimitTypeSelector from './TransactionLimitTypeSelector'

const {
    form: { field: { CngTextField, CngSwitchField, CngSelectField } },
    CngGridItem
} = components

//* Field default values -------------------------------------------------------
const initialValues = Object.freeze({
    tasHsCodeApiEnabled: true,
    tasHsCodeTransactionLimitType: "",
    tasHsClassificationLimitValue: "",
    tasOutofRestrictedCountrySearchAllowed: false,
})

//* Fields function
const FormBody = ({disabled}) => {
    const form = useFormContext()
    const { translate } = useTranslation(Namespace.CALISTA_ADVISORY)
    const translatedTextsObject = makeTranslatedTextsObject()

    const [isApiEnabled, setApiEnabled] = useState(form.getValues('tasHsCodeApiEnabled'))
    const [hasLimit, setHasLimit] = useState(form.getValues('tasHsCodeTransactionLimitType') != 'No Limit')

    const handleSwitchChange = (e) => {
        form.setValue('tasHsCodeApiEnabled', e.target.checked)
        setApiEnabled(e.target.checked)
    }

    //* Translation function
    function makeTranslatedTextsObject() {
        // HS Code API Settings
        let tasHsCodeSettings = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.TAS_HS_CODE_SETTINGS)
        let tasHsCodeApiEnabled = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.TAS_HS_CODE_API_ENABLED)
        let tasHsCodeTransactionLimitType = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.TAS_HS_CODE_TRANSACTION_LIMIT_TYPE)
        let tasHsClassificationLimitValue = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.TAS_HS_CLASSIFICATION_LIMIT_VALUE)

        let tasOutofRestrictedCountrySearchAllowed = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.TAS_OUTOF_RESTRICTED_COUNTRY_SEARCH_ALLOWED)

        return {
            tasHsCodeSettings,
            tasHsCodeApiEnabled,
            tasHsCodeTransactionLimitType,
            tasHsClassificationLimitValue,
            tasOutofRestrictedCountrySearchAllowed,
        }
    }

    //* return JSX
    return (

        //* Start JSX
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Typography variant='h5' display="block" className='font-bolder'>
                    {translatedTextsObject.tasHsCodeSettings}
                </Typography>
            </Grid>

            <CngGridItem xs={12} sm={6}>
                <CngSwitchField name="tasHsCodeApiEnabled"
                    label={translatedTextsObject.tasHsCodeApiEnabled}
                    disabled={disabled} 
                    checked={isApiEnabled}
                    onChange={handleSwitchChange}
                />
            </CngGridItem>

            <CngGridItem xs={12} sm={6}>
                <CngSwitchField name="tasOutofRestrictedCountrySearchAllowed"
                    label={translatedTextsObject.tasOutofRestrictedCountrySearchAllowed}
                    disabled={disabled || !isApiEnabled} />
            </CngGridItem>

            <TransactionLimitTypeSelector
                name="tasHsCodeTransactionLimitType"
                label={translatedTextsObject.tasHsCodeTransactionLimitType}
                setHasLimit={setHasLimit}
                disabled={disabled || !isApiEnabled}
            />

            <CngGridItem xs={12} sm={6}>
                <CngTextField name="tasHsClassificationLimitValue"
                    label={translatedTextsObject.tasHsClassificationLimitValue}
                    disabled={disabled || !isApiEnabled || !hasLimit}
                    type="number" />
            </CngGridItem>
 
        </Grid>
        //* End JSX
    )
    //* End of function
}

const HsCodeSettingsComponent = Object.freeze({
    initialValues: initialValues,
    FormBody: FormBody
})

export default HsCodeSettingsComponent