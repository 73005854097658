import React, { useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'
import { useFormContext } from 'react-hook-form'

import Namespace from 'src/constants/locale/Namespace'
import CalistaAdvisoryKey from 'src/constants/locale/key/CalistaAdvisory'

const {
    form: { field: { CngDateField, CngSwitchField } },
    CngGridItem
} = components

//* Field default values -------------------------------------------------------
const initialValues = Object.freeze({
    contractActive: true,
    contractStartDate: "",
    contractEndDate: "",
})

//* Fields function
const FormBody = ({disabled}) => {
    const form = useFormContext()
    const { translate } = useTranslation(Namespace.CALISTA_ADVISORY)
    const translatedTextsObject = makeTranslatedTextsObject()

    const [isContractActive, setContractActive] = useState(form.getValues('contractActive'))

    const handleSwitchChange = (e) => {
        form.setValue('contractActive', e.target.checked)
        setContractActive(e.target.checked)
    }

    //* Translation function
    function makeTranslatedTextsObject() {
        let contractSettings = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.CONTRACT_SETTINGS)
        let contractActive = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.CONTRACT_ACTIVE)
        let contractStartDate = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.CONTRACT_START_DATE)
        let contractEndDate = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.CONTRACT_END_DATE)

        return {
            contractSettings,
            contractActive,
            contractStartDate,
            contractEndDate,
        }
    }

    //* return JSX
    return (

        //* Start JSX
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant='h5' display="block" className='font-bolder'>
                    {translatedTextsObject.contractSettings}
                </Typography>
            </Grid>
            <CngGridItem xs={12} sm={12}>
                <CngSwitchField name="contractActive"
                    label={translatedTextsObject.contractActive}
                    disabled={disabled} 
                    checked={isContractActive}
                    onChange={e => handleSwitchChange(e)}
                />
            </CngGridItem>

            <CngGridItem xs={12} sm={6}>
                <CngDateField name="contractStartDate"
                    label={translatedTextsObject.contractStartDate}
                    disabled={disabled || !isContractActive} />
            </CngGridItem>

            <CngGridItem xs={12} sm={6}>
                <CngDateField name="contractEndDate"
                    label={translatedTextsObject.contractEndDate}
                    disabled={disabled || !isContractActive} />
            </CngGridItem>
 
        </Grid>
        //* End JSX
    )
    //* End of function
}

const ContractSettingsComponent = Object.freeze({
    initialValues: initialValues,
    FormBody: FormBody
})

export default ContractSettingsComponent