import React from 'react'
import Namespace from '../../../constants/locale/Namespace'
import { constants, useTranslation } from 'cng-web-lib'
import QuotationKeys from "../../../constants/locale/key/cec/QuotationKeys"

const NAMESPACE = Namespace.CEC_QUOTATION
const {
    locale: {
        key: {
            UiComponentKeys
        }
    }
} = constants

const TranslationText = () => {
    const { translate } = useTranslation([NAMESPACE])
    const moduleTitle = translate(NAMESPACE, QuotationKeys.MODULE_TITLE)
    const titleQuotationList = translate(NAMESPACE, QuotationKeys.QUOTATION_LIST)
    const quotationSummary = translate(NAMESPACE, QuotationKeys.SUMMARY_HEADER)
    const quotationInfo = translate(NAMESPACE, QuotationKeys.QUOTATION_HEADER)

    // quotation fields
    const party = translate(NAMESPACE, QuotationKeys.FIELDS.PARTY)
    const uuid = translate(NAMESPACE, QuotationKeys.FIELDS.REQUEST_UUID)
    const quotationRefNo = translate(NAMESPACE, QuotationKeys.FIELDS.QUOTATION_REF_NO)
    const status = translate(NAMESPACE, QuotationKeys.FIELDS.STATUS)
    const requestInfo = translate(NAMESPACE, QuotationKeys.FIELDS.REQUEST_INFO)
    const createdAt = translate(NAMESPACE, QuotationKeys.FIELDS.CREATED_AT)
    const updatedAt = translate(NAMESPACE, QuotationKeys.FIELDS.UPDATED_AT)

    return {
        moduleTitle,
        titleQuotationList,
        quotationSummary,
        quotationInfo,
        party,
        uuid,
        quotationRefNo,
        status,
        requestInfo,
        createdAt,
        updatedAt
    }
}

export default TranslationText
