import React from 'react'
import { components } from 'cng-web-lib'

import CiaConfigFormProperties from './CiaPartyConfig_CiaConfigViewFormProperties'
import CalistaAdvisoryPartyConfigApiUrls from 'src/apiUrls/CalistaAdvisoryPartyConfigApiUrls'

const { form: { CngViewForm } } = components

function CiaConfigViewForm({ history, showNotification, id }) {

	return (

		//* Start JSX ----------------------------------------------------------
		<CngViewForm
			fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
			history={history}
			showNotification={showNotification}
			bodySection={
				<CiaConfigFormProperties.Fields
					disabled={true}
					showNotification={showNotification}
					id={id}
				/>
			}
			formikProps={CiaConfigFormProperties.formikProps}
			toClientDataFormat={CiaConfigFormProperties.toClientDataFormat}
			toServerDataFormat={CiaConfigFormProperties.toServerDataFormat}
			fetch={{
				url: CalistaAdvisoryPartyConfigApiUrls.GET,
				id: id
			}}
		/>
		//* End JSX ------------------------------------------------------------
	)

}

export default CiaConfigViewForm
