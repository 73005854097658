import FormProperties from './FormProperties'
import Urls from '../../../../../apiUrls/cec-admin/shipment-provider/ShipmentProviderConnectorMappingUrls'
import React from 'react'
import { components } from 'cng-web-lib'
import ConnectorType from '../../../../../constants/cec/ConnectorType'


const {
  form: {
    CngAddForm
  }
} = components

function AddForm({
                   history,
                   showNotification,
                   connectorType,
                   shipmentProviderId,
                   setConnectorMappingId,
                   connectorTypeId
                 }) {

  return (
    <CngAddForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <FormProperties.Fields
          connectorType={connectorType}
          showNotification={showNotification}
          connectorTypeId={connectorTypeId}
        />
      }
      formikProps={
        connectorType === ConnectorType.CustomerApplication ? {
          initialValues: {
            ...FormProperties.formikPropsForCustomerConfig.initialValues, shipmentProviderId: shipmentProviderId
          },
          makeValidationSchema: FormProperties.formikPropsForCustomerConfig.makeValidationSchema
        } : {
          initialValues: {
            ...FormProperties.formikPropsForCourierConfig.initialValues, shipmentProviderId: shipmentProviderId
          },
          makeValidationSchema: FormProperties.formikPropsForCourierConfig.makeValidationSchema
        }}
      toClientDataFormat={FormProperties.toClientDataFormat}
      toServerDataFormat={FormProperties.toServerDataFormat}
      create={{
        url: Urls.CREATE,
        onPostSubmitSuccess: (datum) => {
          setConnectorMappingId(datum.id)
        }
      }}
    />
  )

}

export default AddForm