import CecAccountApiUrls from 'src/apiUrls/CecAccountApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React, { useState } from 'react'
import { components } from 'cng-web-lib'
import { useParams } from 'react-router-dom'


const {
  form: { CngViewForm }
} = components

function ViewPage({ showNotification}) {
  const { id } = useParams()
  const [countryCode, setCountryCode] = useState('')

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngViewForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          showNotification={showNotification}
          bodySection={
            <FormProperties.Fields
              disabled={true}
              showNotification={showNotification}
              country={countryCode}
              mode={'view'}
            />
          }
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          //idAccessor={accountNumber}
          fetch={{
            id: id,
            url: CecAccountApiUrls.GET_ACCOUNT,
            onPreSuccess: (serverData) => {
              setCountryCode(serverData.country)
            }
          }}
        />
      </Grid>
    </Grid>
  )
}

export default ViewPage
