import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation,
  DataFlattener,
  useServices
} from 'cng-web-lib'

import CecAccountApiUrls from 'src/apiUrls/CecAccountApiUrls'
import SCOAdminApiUrls from 'src/apiUrls/SCOAdminApiUrls'
import React, { useRef} from 'react'
import CecAccountKeys from 'src/constants/locale/key/CecAccount'
import Namespace from 'src/constants/locale/Namespace'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useDefaultNotification,
    useFetchCustomLookup,
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  },
  filter: {
    EQUAL
  }
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props
  
  const {
    error: showErrorNotification
  } = useDefaultNotification(showNotification)

  const notification = useDefaultNotification(showNotification)

  const fetchCustomLookup = useFetchCustomLookup();
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.CEC_ACCOUNT
  ])
 
  const translatedTextsObject = makeTranslatedTextsObject()
  const cngTableRef = useRef()
  
  const { securedSendRequest } = useServices();

  const getPartyID = () => (JSON.parse(localStorage.getItem('userPreference')) || {}).partyId
  const partyId = getPartyID();

  const onComplete = () => {
    console.log("Delete Completed")
    }

  function onError(error) {
    console.log("Error... "+ JSON.stringify(error))
      if(null != error && null != error.response && null != error.response.data){
        showErrorNotification("Delete records failed: "+ error.response.data);
      }
  }

  function makeTranslatedTextsObject() {
    let cecAccount = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.CEC_ACCOUNT_TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: cecAccount
      }
    )
   
    let accountNumber = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.ACCOUNT_NUMBER
    )
    let displayName = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.DISPLAY_NAME
    )
    let status = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.STATUS
    )
   
    return {
      tableTitle,
      cecAccount,
      accountNumber,
      displayName,
      status
    }
  }

  const columns = [
    {
      field: "accountNumber",
      title: translatedTextsObject.accountNumber
    },
    {
      field: "displayName",
      title: translatedTextsObject.displayName
    },
    {
      field: "status",
      title: translatedTextsObject.status,
      customLookup: () => {
        return fetchCustomLookup(
              `${SCOAdminApiUrls.GET_CODE_MASTER_LIST}`,
              {
                "codeType" : "CEC_TRNX_STATUS"
              },
              'content',
              'name',
              'id',
              (error) => console.error(error)
        )
    }
    },
    {
      field: "createdDate",
      title: "",
      defaultSort: "desc",
      type: 'datetime',
      filtering: false,
      hidden: true
    }
  ]


  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: CecAccountApiUrls.DELETE_ACCOUNT}}
              // exportData={{ url: CecAccountApiUrls.EXPORT }}
              deleteButtonProps= {{
                onClick: (e,rowData) => {
                  securedSendRequest.execute('delete',CecAccountApiUrls.DELETE_ACCOUNT, 
                  toServerDataFormat(rowData) ,
                  (data)=> {
                    showNotification("success", "Record deleted successfully")
                    if (cngTableRef.current.performRefresh) {
                      cngTableRef.current.performRefresh()
                    }
                  },
                  onError,
                  onComplete,
                  {
                     
                     headers : {
                      'Authorization': `Bearer `+localStorage.getItem("userToken"),
                      'X-ACCOUNT-KEY': rowData.secretKey
                     }
                   }
                  
                  )
                }
              }
              }
              editRoute={`${pathname}/edit`}              
              fetch ={{ url: CecAccountApiUrls.GET_ACCOUNT }}
              fetchFilters={
                [
                  {
                    field: 'partyId',
                    operator: EQUAL,
                    value: partyId
                  }
                ]
              }
              idAccessor="id"
              notification={notification}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
              viewRoute={`${pathname}/view`}
              cngTableRef={cngTableRef}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
  
}



  function toServerDataFormat(localData) {
    const reqData = { "account" : [localData]}
    
    return DataFlattener.unflatten(reqData);
  }

export default TablePage
