import { Grid } from '@material-ui/core'
import React from 'react'
import { components,useTranslation } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import Namespace from 'src/constants/locale/Namespace'
import CecAccountKeys from 'src/constants/locale/key/CecAccount'
import CecAccountViewForm from './CecAccountViewPage'


const { CngTabs } = components

function ViewPage({ showNotification }) {
  
  const { id } = useParams()
  const { translate } = useTranslation([Namespace.CEC_ACCOUNT])
  const translatedTextsObject = makeTranslatedTextsObject()
  
  function makeTranslatedTextsObject() {
    let viewCecAccount = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.TITLE
    )
    let cecAccountViewPage = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.CEC_ACCOUNT_VIEW
    )
    return {
      viewCecAccount,
      cecAccountViewPage
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.cecAccountViewPage,
              tabContent: (
                <CecAccountViewForm
                  showNotification={showNotification}
                  id={id}
                />
              )
            }
          ]}
        />
      </Grid>
    </Grid>
  );
}

export default ViewPage
