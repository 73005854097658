import React from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Card, CardContent} from '@material-ui/core'
import { components } from 'cng-web-lib'

import CalistaAdvisorySystemConfigApiUrls from 'src/apiUrls/cia-admin/CalistaAdvisorySystemConfigApiUrls'
import FormProperties from './CiaSysConfig_FormProperties'

const { card: { CngSimpleCardHeader },
        form: { CngViewForm } } = components

function ViewPage({ history, showNotification}) {

    const { id } = useParams()

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Card>
                    <CngSimpleCardHeader/>
                    {/* <Divider /> */}
                    <CardContent>
                    <CngViewForm
                        fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                        history={history}
                        showNotification={showNotification}
                        bodySection={
                            <FormProperties.Fields
                                disabled={true}
                                showNotification={showNotification}
                                id={id}
                            />
                        }
                        formikProps={FormProperties.formikProps}
                        toClientDataFormat={FormProperties.toClientDataFormat}
                        toServerDataFormat={FormProperties.toServerDataFormat}
                        fetch={{
                            url: CalistaAdvisorySystemConfigApiUrls.GET,
                            id: id
                        }}
                    />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default ViewPage