import ConnectorTranslationText from '../ConnectorTranslationText'
import { Card, CardContent, Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'
import FormProperties from './ConnectorMappingsFormProperties'
import React from 'react'
import { useParams } from 'react-router-dom'
import ConnectorMappingApiUrls from '../../../../apiUrls/cec-admin/connector-config/ConnectorMappingApiUrls'
import AccordionHeaderComponent from '../../../common/ui/AccordionHeaderComponent'
import MappingItemCrudTable from '../mapping-items/CrudTable'


const {
  button: {
    CngSecondaryButton
  },
  form: {
    CngViewForm
  }, CngGridItem
} = components

function ViewPage({ history, showNotification }) {

  const { mappingId } = useParams()

  const {
    connectorMappingItems
  } = ConnectorTranslationText()

  return (

    <Grid container spacing={1}>
      <CngGridItem item xs={12}>

        <Card>
          <CardContent>

            <CngViewForm
              fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
              showNotification={showNotification}
              bodySection={<FormProperties.Fields
                disabled={true}
                showNotification={showNotification}
              />}
              formikProps={{
                ...FormProperties.formikProps
              }}
              toClientDataFormat={FormProperties.toClientDataFormat}
              toServerDataFormat={FormProperties.toServerDataFormat}
              fetch={{
                id: parseInt(mappingId),
                url: `${ConnectorMappingApiUrls.GET}`
              }}

            />

            <Grid container spacing={3}>

              <CngGridItem xs={12} sm={12}>
                <CngSecondaryButton
                  onClick={() => {
                    history.goBack()
                  }}>
                  Back
                </CngSecondaryButton>
              </CngGridItem>
            </Grid>

          </CardContent>
        </Card>

      </CngGridItem>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container>
              <AccordionHeaderComponent
                title={connectorMappingItems} hideMandatory />

            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12}>

                <MappingItemCrudTable
                  mappingId={parseInt(mappingId)}
                  isViewOnly={true}
                  showNotification={showNotification}
                />

              </Grid>
            </Grid>

          </CardContent>
        </Card>
      </Grid>
    </Grid>)

}

export default ViewPage