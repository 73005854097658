import React from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Card, CardContent } from '@material-ui/core'
import { components } from 'cng-web-lib'

import CalistaAdvisoryFtaMappingApiUrls from 'src/apiUrls/cia-admin/CalistaAdvisoryFtaMappingApiUrls'
import FormProperties from './CiaFtaMapping_FormProperties'

const { card: { CngSimpleCardHeader },
    form: { CngViewForm } } = components

function ViewPage({ history, showNotification }) {

    const { id } = useParams()

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Card>
                    <CngSimpleCardHeader />
                    <CardContent>
                        <CngViewForm
                            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                            history={history}
                            showNotification={showNotification}
                            bodySection={
                                <FormProperties.Fields
                                    disabled={true}
                                    isEditable={false}
                                    showNotification={showNotification}
                                    id={id}
                                />
                            }
                            formikProps={FormProperties.formikProps}
                            toClientDataFormat={FormProperties.toClientDataFormat}
                            toServerDataFormat={FormProperties.toServerDataFormat}
                            fetch={{
                                url: CalistaAdvisoryFtaMappingApiUrls.GET,
                                id: id
                            }}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default ViewPage