import React from 'react'
import { BasePage as CngBasePage, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import CiaKeys from 'src/constants/locale/key/CalistaAdvisory'
import BreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_CalistaAdvisory'

import TablePage from './CiaAdminMassRecommend_TablePage'
import ViewPage from './CiaAdminMassRecommend_ViewPage'

//* Module Title ---------------------------------------------------------------
function ModuleTitle() {
    const { translate } = useTranslation(Namespace.CALISTA_ADVISORY)
    let title = translate(Namespace.CALISTA_ADVISORY, CiaKeys.SUB_MODULE_TITLE.CIA_ADMIN_MASS_RECOMMEND)

    return (title);
}

//* Wrapped Table Page ---------------------------------------------------------
function WrappedTablePage(props) {
    const breadcrumbNameMap = BreadcrumbNameMap()
    return (
        <CngBasePage renderPage={(showSnackbar) => (
            <TablePage showNotification={showSnackbar} {...props} />
        )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
            moduleTitle={ModuleTitle()}
        />
    )
}

//* Wrapped View Page ----------------------------------------------------------
function WrappedViewPage(props) {
    const breadcrumbNameMap = BreadcrumbNameMap()
    return (
        <CngBasePage renderPage={(showSnackbar) => (
            <ViewPage showNotification={showSnackbar} {...props} />
        )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
            moduleTitle={ModuleTitle()}
        />
    )
}

export {
    WrappedTablePage as TablePage,
    WrappedViewPage as ViewPage
}