import React from 'react'
import PropTypes from 'prop-types'
import { components } from 'cng-web-lib'
import ConnectorTranslationText from '../ConnectorTranslationText'
import ConnectorCredentialsApiUrls from '../../../../apiUrls/cec-admin/connector-config/ConnectorCredentialsApiUrls'
import ConnectorCredentialsFormProperties from './ConnectorCredentialsFormProperties'

const {
  table: {
    CngServerModeDialogFormTable, useFetchCustomLookup, useDefaultNotification
  }
} = components


const ConnectorCredentialsCrudTable = ({ connectorAppId, isViewOnly, showNotification }) => {

  const { credentialName, isActive } = ConnectorTranslationText()
  const { GET, CREATE, UPDATE, DELETE } = ConnectorCredentialsApiUrls(connectorAppId)

  const columnsOfConnectorCredentials = [{
    field: 'name', title: credentialName
  }, {
    field: 'isActive', title: isActive, type: 'boolean'
  }]


  return (

    <CngServerModeDialogFormTable
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      disabled={isViewOnly}
      showNotification={showNotification}
      tableColumns={columnsOfConnectorCredentials}
      pageSize={5}
      formProperties={{
        ...ConnectorCredentialsFormProperties, formikProps: {
          ...ConnectorCredentialsFormProperties.formikProps, initialValues: {
            ...ConnectorCredentialsFormProperties.formikProps.initialValues, appId: connectorAppId
          }
        }
      }}
      toClientDataFormat={(serverData) => {
        return serverData
      }}
      toServerDataFormat={(localDatum) => {
        return localDatum
      }}
      fetch={{
        url: GET, body: {
          appId: connectorAppId
        }
      }}
      create={{
        url: CREATE, body: {
          appId: connectorAppId
        }
      }}
      onEditButtonClick={(event, record) => record.existingPassword = null}
      onViewButtonClick={(event, record) => record.existingPassword = null}
      update={{ url: UPDATE }}
      del={{ url: DELETE }}
      idAccessor='id'
    />


  )
}

ConnectorCredentialsCrudTable.propTypes = {
  showNotification: PropTypes.func, connectorAppId: PropTypes.number, isViewOnly: PropTypes.bool
}

export default ConnectorCredentialsCrudTable