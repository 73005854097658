import React from 'react'
import { components } from 'cng-web-lib'

import FormProperties from './CiaCountry_FormProperties'
import CalistaAdvisoryCountryApiUrls from 'src/apiUrls/cia-admin/CalistaAdvisoryCountryApiUrls'
import CiaPathMap from 'src/paths/PathMap_CalistaAdvisory'

const { form: { CngAddForm } } = components

function AddPage({ history, showNotification }) {

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <CngAddForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            history={history}
            showNotification={showNotification}
            bodySection={
                <FormProperties.Fields
                    disabled={false}
                    isEditable={true}
                    showNotification={showNotification}
                />
            }
            formikProps={FormProperties.formikProps}
            toClientDataFormat={FormProperties.toClientDataFormat}
            toServerDataFormat={FormProperties.toServerDataFormat}
            create={{
                url: CalistaAdvisoryCountryApiUrls.POST,
                successRedirect: CiaPathMap.CALISTA_ADVISORY_ADMIN_COUNTRY_LIST_VIEW
            }}
        />
        //* End JSX ------------------------------------------------------------

    );

    //* End of function --------------------------------------------------------
}

export default AddPage