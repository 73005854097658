import CecAccountApiUrls from 'src/apiUrls/CecAccountApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React, { useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import pathMap from 'src/paths/pathMap'

const {
  form: { CngEditForm },
  table: { useDefaultNotification }
} = components

function EditPage({ history, showNotification }) {
  const { id } = useParams()
  const { securedSendRequest } = useServices();
  const [countryCode, setCountryCode] = useState('')
 
  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)
  
  const onSubmit = (localData) => {

    const reqData = { "account":[{"accountNumber" : localData.accountNumber ,
    "displayName" : localData.displayName,
    "status" : localData.status,
    "secretKey" : localData.secretKey,
    "address": {"city":localData.city,
    "street":localData.street,"state":localData.stateId,"stateOther":localData.stateOther,
    "country":localData.country,
    "postcode":localData.postcode,
    "type":localData.addressTypeId},
    "person": localData.person}]
    } 
    const secretKey = localData.secretKey
    const onSuccess = () => {
      showSuccessNotification("Update Successful");
      history.push(pathMap.CEC_ACCOUNT_LIST_VIEW)
    }
    const onComplete = () => {
      console.log("Edit Completed")
    }
    
    const onError = (error) => {
      console.log("Error... "+ JSON.stringify(error))
      if(null != error && null != error.response && null != error.response.data){
        showErrorNotification("Edit records failed: "+ error.response.data);
      }
    }
  
  securedSendRequest.execute(
    'put',
    CecAccountApiUrls.EDIT_ACCOUNT,
    reqData,
    onSuccess,
    onError,
    onComplete,
    { 
       headers : {
        Authorization: `Bearer `+localStorage.getItem("userToken"),
        'X-ACCOUNT-KEY': secretKey
       }
    }
  )
  }
 
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngEditForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          showNotification={showNotification}
          bodySection={
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              fetchCountryCode={countryCode}
              mode={'edit'}
            />
          }
          formikProps={{
            initialValues: FormProperties.formikProps.initialValues,
            makeValidationSchema: FormProperties.formikProps.makeValidationSchema,
            onSubmit: onSubmit
          }}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          fetch={{
            url: CecAccountApiUrls.GET_ACCOUNT,
            onPreSuccess: (serverData) => {
              setCountryCode(serverData.country)
            }
          }}
          idAccessor={id}
          id={id}
        />
      </Grid>
    </Grid>
  )
}


export default EditPage
