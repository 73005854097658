import { Card, CardContent, Grid } from '@material-ui/core'

import {
      components
} from 'cng-web-lib'

import CommonCityMappingApiUrls from '../../../apiUrls/cec-admin/CommonCityMappingApiUrls'
import React from 'react'
import CommonCityTranslationText from './CommonCityTranslationText'
import SCOAdminApiUrls from 'src/apiUrls/SCOAdminApiUrls'

const {
      table: {
            CngCrudTable,
            useDefaultNotification,
            useFetchCustomLookup,
      }
} = components



function TablePage(props) {
      const {
            location: { pathname },
            showNotification
      } = props

      const translatedTextObject = CommonCityTranslationText()
      const notification = useDefaultNotification(showNotification)
      const fetchCustomLookup = useFetchCustomLookup();

      const columns = [
            {
                  field: "countryId",
                  title: translatedTextObject.countryCode,
                  customLookup: () => {
                        return fetchCustomLookup(
                              `${SCOAdminApiUrls.GET_COUNTRY_LIST}`,
                              {
                              },
                              'content',
                              'code',
                              'id',
                              (error) => console.error(error)
                        )
                  },
            },
            {
                  field: "commonCityName",
                  title: translatedTextObject.commonCityName
            },
            {
                  field: "locCode",
                  title: translatedTextObject.locCode
            },
            ,
            {
			field: 'createdDate', 
                  title: "",
			type: "datetime", 
                  defaultSort: 'desc',
			hidden: true
		}
      ]

      return (
            <Card>
                  <CardContent>
                        <Grid container spacing={3}>
                              <CngCrudTable
                                    {...props}
                                    fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
                                    columns={columns}
                                    addRoute={`${pathname}/create`}
                                    fetch={{ url: CommonCityMappingApiUrls.GET }}
                                    idAccessor="id"
                                    notification={notification}
                                    viewRoute={`${pathname}/view`}
                                    editRoute={`${pathname}/edit`}
                                    del={{
                                          url: CommonCityMappingApiUrls.DELETE
                                    }}
                              />
                        </Grid>
                  </CardContent>
            </Card >
      )


}

export default TablePage