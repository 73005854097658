import { Card, CardContent, Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'

import React from 'react'
import ShipmentProviderUrls from '../../../../apiUrls/cec-admin/shipment-provider/ShipmentProviderUrls'
import TranslationText from './TranslationText'

const {
  table: {
    CngCrudTable,
    useDefaultNotification
  }
} = components

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)

  const translatedTextObject = TranslationText()

  const columns = [
    {
      field: 'name',
      title: translatedTextObject.shipmentProviderName
    },
    {
      field: 'code',
      title: translatedTextObject.shipmentProviderCode
    },
    {
      field: 'isActive',
      title: translatedTextObject.isActive,
      type: 'boolean'
    }

  ]

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
              columns={columns}
              fetch={{ url: ShipmentProviderUrls.GET }}
              addRoute={`${pathname}/add`}
              viewRoute={`${pathname}/view`}
              editRoute={`${pathname}/edit`}
              del={{ url: ShipmentProviderUrls.DELETE }}
              idAccessor='id'
              notification={notification}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TablePage
