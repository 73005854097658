import React, { useState } from 'react'
import { Grid, Divider, Typography } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'
import { useFormContext } from 'react-hook-form'

import Namespace from 'src/constants/locale/Namespace'
import CalistaAdvisoryKey from 'src/constants/locale/key/CalistaAdvisory'

import TransactionLimitTypeSelector from './TransactionLimitTypeSelector'

const {
    form: { field: { CngTextField, CngSwitchField, CngSelectField } },
    CngGridItem
} = components

//* Field default values -------------------------------------------------------
const initialValues = Object.freeze({
    tasComplianceApiEnabled: true,
    tasComplianceShowRooDetails: false,
    tasComplianceTransactionLimitType: "",
    tasComplianceDetailLimitValue: "",
    hngDataFreshness: "",
})

//* Fields function
const FormBody = ({disabled}) => {
    const form = useFormContext()
    const { translate } = useTranslation(Namespace.CALISTA_ADVISORY)
    const translatedTextsObject = makeTranslatedTextsObject()

    const [isApiEnabled, setApiEnabled] = useState(form.getValues('tasComplianceApiEnabled'))
    const [showRooDetails, setShowRooDetails] = useState(form.getValues('tasComplianceShowRooDetails'))
    const [hasLimit, setHasLimit] = useState(form.getValues('tasComplianceTransactionLimitType') != 'No Limit')

    const handleApiEnabledChange = (e) => {
        form.setValue('tasComplianceApiEnabled', e.target.checked)
        setApiEnabled(e.target.checked)
    }

    const handleShowRooChange = (e) => {
        form.setValue('tasComplianceShowRooDetails', e.target.checked)
        setShowRooDetails(e.target.checked)
    }

    //* Translation function
    function makeTranslatedTextsObject() {
        // Compliance eAdvisory Settings
        let tasComplianceEAdvisorySettings = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.TAS_COMPLIANCE_EADVISORY_SETTINGS)
        let tasComplianceApiEnabled = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.TAS_COMPLIANCE_API_ENABLED)
        let tasComplianceShowRooDetails = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.TAS_COMPLIANCE_SHOW_ROO_DETAILS)
        let tasComplianceTransactionLimitType = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.TAS_COMPLIANCE_TRANSACTION_LIMIT_TYPE)
        let tasComplianceDetailLimitValue = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.TAS_COMPLIANCE_DETAIL_LIMIT_VALUE)
        
        let hngDataFreshness = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.HNG_DATA_FRESHNESS)

        return {
            tasComplianceEAdvisorySettings,
            tasComplianceApiEnabled,
            tasComplianceShowRooDetails,
            tasComplianceTransactionLimitType,
            tasComplianceDetailLimitValue,
            hngDataFreshness,
        }
    }

    //* return JSX
    return (

        //* Start JSX
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Divider />
            </Grid>

            <Grid item xs={12}>
                <Typography variant='h5' display="block" className='font-bolder'>
                    {translatedTextsObject.tasComplianceEAdvisorySettings}
                </Typography>
            </Grid>

            <CngGridItem xs={12} sm={3}>
                <CngSwitchField name="tasComplianceApiEnabled"
                    label={translatedTextsObject.tasComplianceApiEnabled}
                    disabled={disabled} 
                    checked={isApiEnabled}
                    onChange={handleApiEnabledChange}
                />
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
                <CngSwitchField name="tasComplianceShowRooDetails"
                    label={translatedTextsObject.tasComplianceShowRooDetails}
                    disabled={disabled} 
                    checked={showRooDetails}
                    onChange={handleShowRooChange}
                />
            </CngGridItem>

            <CngGridItem xs={12} sm={6}>
                <CngTextField name="hngDataFreshness"
                    label={translatedTextsObject.hngDataFreshness}
                    disabled={disabled || !isApiEnabled}
                    type="number" />
            </CngGridItem>

            <TransactionLimitTypeSelector
                name="tasComplianceTransactionLimitType"
                label={translatedTextsObject.tasComplianceTransactionLimitType}
                setHasLimit={setHasLimit}
                disabled={disabled || !isApiEnabled}
            />

            <CngGridItem xs={12} sm={6}>
                <CngTextField name="tasComplianceDetailLimitValue"
                    label={translatedTextsObject.tasComplianceDetailLimitValue}
                    disabled={disabled || !isApiEnabled || !hasLimit}
                    type="number" />
            </CngGridItem>
 
        </Grid>
        //* End JSX
    )
    //* End of function
}

const ComplianceEAdvisorySettingsComponent = Object.freeze({
    initialValues: initialValues,
    FormBody: FormBody
})


export default ComplianceEAdvisorySettingsComponent