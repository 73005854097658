import React from 'react'
import {Bar} from 'react-chartjs-2';


export default function BarChart(props) {
    const labels = props.graphData.dataLabel

    const barChartColor = [ '#7CE7AC', '#F4BE5E', '#40E1FA']

    const barOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                align: 'start'
            },
            title: {
                display: true,
                text: 'Chart.js Line Chart',
            },
        },
        animation: {
            duration: 0
        }
    }

    const getDataSet = () => {
        console.log("updating..")
        let dataSet = []
        if(props.graphData.data[props.module] != null)
            props.graphData.data[props.module].forEach((d, idx) => {
                dataSet.push({ fill: true, label: d.party, data: d.count, backgroundColor: barChartColor[idx] })
            })

        return dataSet
    }

    const data = {
        labels ,
        datasets: getDataSet(),
    };

    const legend = {
        position: 'top',
        align: 'start'
    }

    return (
        <Bar options={barOptions} data={data} legend={legend} redraw={true} />
    )
}
