import CalistaUiComponentKeys from 'src/constants/locale/key/CalistaUiComponent'
import Namespace from 'src/constants/locale/Namespace'
import { useTranslation } from 'cng-web-lib'

const CalistaUiComponentTranslationText = () => {
  const { translate } = useTranslation([Namespace.CALISTA_UI_COMPONENT])

  let createPermit = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.CREATE_PERMIT
  )

  let mandatoryFields = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.MANDATORY_FIELDS
  )

  let mandatoryFieldsWithoutAsterisk = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.MANDATORY_FIELDS_WITHOUT_ASTERISK
  )

  let showOptionalFields = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.SHOW_OPTIONAL_FIELDS
  )

  let hideOptionalFields = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.HIDE_OPTIONAL_FIELDS
  )

  let orClonePrevious = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.OR_CLONE_PREVIOUS
  )

  let uploadFileFeature = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.UPLOAD_FILE_FEATURE
  )

  let saveDraft = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.SAVE_DRAFT
  )

  let saveChanges = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.SAVE_CHANGES
  )

  let saveChangesToDrafts = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.SAVE_CHANGES_TO_DRAFTS
  )

  let confirmReview = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.CONFIRM_REVIEW
  )

  let confirmNext = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.CONFIRM_NEXT
  )

  let edit = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.EDIT
  )

  let back = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.BACK
  )

  let goBack = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.GO_BACK
  )

  let submit = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.SUBMIT
  )

  let submitApplyChanges = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.SUBMIT_APPLY_CHANGES
  )

  let submitWithoutchange = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.SUBMIT_WITHOUT_CHANGE
  )

  let submitForApproval = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.SUBMIT_FOR_APPROVAL
  )

  let confirm = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.CONFIRM
  )
  let confirmApplyChanges = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.CONFIRM_APPLY_CHANGES
  )

  let confirmWithoutChange = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.CONFIRM_WITHOUT_CHANGE
  )

  let submitForConfirmation = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.SUBMIT_FOR_CONFIRMATION
  )

  let apporveDischardChanges = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.APPROVE_DISCHARD_CHANGES
  )

  let submitBooking = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.SUBMIT_BOOKING
  )

  let submitAmendments = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.SUBMIT_AMENDMENTS
  )

  let viewAmendments = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.VIEW_AMENDMENTS
  )

  let discard = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.DISCARD
  )

  let ok = translate(Namespace.CALISTA_UI_COMPONENT, CalistaUiComponentKeys.OK)

  let cancel = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.CANCEL
  )

  let copy = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.COPY
  )

  let deleteText = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.DELETE
  )

  let archive = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.ARCHIVE
  )

  let archived = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.ARCHIVED
  )

  let manage = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.MANAGE
  )

  let backToManageBooking = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.BACK_TO_MANAGE_BOOKING
  )
  let decline = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.DECLINE
  )

  let approve = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.APPROVE
  )
  let reject = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.REJECT
  )
  let printDocument = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.PRINT_DOCUMENT
  )

  let print = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.PRINT
  )

  let viewSI = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.VIEW_SI
  )
  let createSI = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.CREATE_SI
  )

  let createDirectSI = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.CREATE_DIRECT_SI
  )

  let trackContainers = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.TRACK_CONTAINERS
  )

  let amend = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.AMEND
  )
  let or = translate(Namespace.CALISTA_UI_COMPONENT, CalistaUiComponentKeys.OR)
  let seqNo = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.SEQ_NO
  )
  let action = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.ACTION
  )
  let prevValues = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.PREV_VALUES
  )
  let newValues = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.NEW_VALUES
  )
  let added = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.ADDED
  )
  let removed = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.REMOVED
  )
  let updated = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.UPDATED
  )
  let auditTrailRemarks = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.AUDIT_TRAIL_REMARKS
  )
  let itemsSelected = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.ITEMS_SELECTED
  )

  let lastModified = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.LAST_MODIFIED
  )

  let formErrorWarning = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.FORM_ERROR_WARNING
  )

  let addNew = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.ADD_NEW
  )
  let duplicate = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.DUPLICATE
  )
  let moveUp = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.MOVE_UP
  )
  let moveDown = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.MOVE_DOWN
  )
  let amendSchedule = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.AMEND_SCHEDULE
  )

  let close = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.CLOSE
  )

  let reset = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.RESET
  )

  let warning = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.WARNING
  )

  let apply = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.APPLY
  )

  let create = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.CREATE
  )

  let update = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.UPDATE
  )

  let history = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.HISTORY
  )
  let account_email = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.ACCOUNT_EMAIL
  )
  let action_on = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.ACTION_ON
  )
  let date_time_gmt = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.DATE_TIME_GMT
  )
  let date_time = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.DATE_TIME
  )
  let company = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.COMPANY
  )
  let status = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.STATUS
  )

  let templateExcel = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.TEMPLATE_EXCEL
  )
  let uploadFile = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.UPLOAD_FILE
  )
  let exportContactsExcel = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.EXPORT_CONTACTS_EXCEL
  )
  let addNewContact = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.ADD_NEW_CONTACT
  )
  let addNewTemplate = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.ADD_NEW_TEMPLATE
  )
  let noFilterResultDesc_1 = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.NO_FILTER_RESULT_DESC_1
  )
  let noFilterResultDesc_2 = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.NO_FILTER_RESULT_DESC_2
  )
  let resultNotFound = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.RESULT_NOT_FOUND
  )
  let resetFilter = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.RESET_FILTER
  )

  let fileName = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.FILE_NAME
  )
  let fileSize = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.FILE_SIZE
  )
  let templateName = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.TEMPLATE_NAME
  )
  let creationDate = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.CREATION_DATE
  )

  let noTakeMeBack = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.NO_TAKE_ME_BACK
  )

  let yesDelete = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.YES_DELETE
  )

  let yesDiscard = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.YES_DISCARD
  )

  let download = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.DOWNLOAD
  )

  let downloadErrorLog = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.DOWNLOAD_ERROR_LOG
  )

  let sortBy = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.SORT_BY
  )

  let accept = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.ACCEPT
  )

  let backToEditPermit = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.BACK_TO_CREATE_PERMIT
  )

  let submitPermit = translate(
    Namespace.CALISTA_UI_COMPONENT,
    CalistaUiComponentKeys.SUBMIT_PERMIT
  )

  return {
    status,
    company,
    date_time,
    history,
    account_email,
    action_on,
    date_time_gmt,
    auditTrailRemarks,
    added,
    removed,
    updated,
    newValues,
    prevValues,
    mandatoryFields,
    mandatoryFieldsWithoutAsterisk,
    showOptionalFields,
    hideOptionalFields,
    orClonePrevious,
    uploadFileFeature,
    saveDraft,
    saveChanges,
    saveChangesToDrafts,
    confirmReview,
    confirmNext,
    edit,
    back,
    goBack,
    submit,
    submitApplyChanges,
    submitWithoutchange,
    submitForApproval,
    confirm,
    confirmApplyChanges,
    confirmWithoutChange,
    submitForConfirmation,
    apporveDischardChanges,
    submitBooking,
    submitAmendments,
    viewAmendments,
    discard,
    ok,
    cancel,
    copy,
    deleteText,
    archive,
    archived,
    manage,
    createPermit,
    backToManageBooking,
    decline,
    amend,
    approve,
    reject,
    printDocument,
    print,
    viewSI,
    createSI,
    createDirectSI,
    trackContainers,
    or,
    seqNo,
    action,
    itemsSelected,
    lastModified,
    formErrorWarning,

    addNew,
    duplicate,
    moveUp,
    moveDown,
    amendSchedule,

    close,
    reset,

    warning,
    apply,
    create,
    update,

    templateExcel,
    uploadFile,
    exportContactsExcel,
    addNewContact,
    addNewTemplate,

    fileName,
    fileSize,
    templateName,
    creationDate,

    noFilterResultDesc_1,
    noFilterResultDesc_2,
    resultNotFound,
    resetFilter,

    noTakeMeBack,
    yesDelete,
    yesDiscard,

    download,
    downloadErrorLog,
    sortBy,

    accept,

    backToEditPermit,
    submitPermit
  }
}

export default CalistaUiComponentTranslationText
