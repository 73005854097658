import React from 'react'
import Namespace from '../../../../constants/locale/Namespace'
import ShipmentProviderKeys from '../../../../constants/locale/key/cec/ShipmentProviderKeys'
import { constants, useTranslation } from 'cng-web-lib'

const NAMESPACE = Namespace.SHIPMENT_PROVIDERS

const {
  locale: {
    key: {
      UiComponentKeys
    }
  }
} = constants


const TranslationText = () => {

  const { translate } = useTranslation([NAMESPACE])

  //form fields
  const variableName = translate(NAMESPACE, ShipmentProviderKeys.VARIABLE_NAME)
  const variableValue = translate(NAMESPACE, ShipmentProviderKeys.VARIABLE_VALUE)
  const variables = translate(NAMESPACE, ShipmentProviderKeys.VARIABLES)
  const firstTabTitle = translate(NAMESPACE, ShipmentProviderKeys.MODULE_TITLE)

  const tabLockedMessage = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Tabs.PLEASE_SUBMIT_FIRST,
    {
      title: firstTabTitle
    }
  )


  return {
    variableName,
    variableValue,
    variables,
    tabLockedMessage
  }
}

export default TranslationText