import {
    Box,
    Button,
    Grid
} from '@material-ui/core'
import React, {useState} from 'react'

import AddBoxIcon from '@material-ui/icons/AddBox'
import CalistaUiComponentTranslationText from '../CalistaUiComponentTranslationText'

function FooterAddComponent(props){

    const translatedTextsObject = CalistaUiComponentTranslationText()
    const [captionDisplay, setCaptionDisplay] = useState(false)

    const handleMouseOverOn = () => {
        setCaptionDisplay(true);
      };
    
    const handleMouseOverOff = () => {
        setCaptionDisplay(false);
    };

    return (
        <Grid item onClick={props.handleAddChild}>
            <Button 
                variant="contained"
                color="default" 
                fullWidth
                startIcon={<AddBoxIcon/>}
                className={'accordion-footer-add-component'}
                onMouseEnter={handleMouseOverOn}
                onMouseLeave={handleMouseOverOff}
            >
                <Box style={{ textAlign:'left' }}>
                    <Box mt={captionDisplay ? 0 : 1 } fontWeight={600} fontSize={13}>
                        {props.footerText}
                    </Box>
                    <Box mt={captionDisplay ? 0 : -1 } 
                        fontSize={11} 
                        visibility={captionDisplay ? 'visible' : 'hidden'} 
                        >
                        {translatedTextsObject.orClonePrevious}
                    </Box>
                </Box>
            </Button>
        </Grid>
    )
}

export default FooterAddComponent