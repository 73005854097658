import React from 'react'
import SharedServiceApiUrls from 'src/apiUrls/SharedServiceApiUrls.js'

function fetchContainerType(fetchRecords, dropdown = false, onSuccess) {

    let returnData = []

    console.log('fetchCodeMaster...')
    fetchRecords.execute(
      SharedServiceApiUrls.GET_CONTAINER_TYPE,
      // { customData: { codeMType: CodeMaintenanceType.CONTAINER_TYPE } },
      {},
      (data) => {
        /*data massage here*/
        // alert('data' + JSON.stringify(data))
        if(dropdown){
          let dropdownItems = data.content.map((datum) => ({
              text:datum['containerIsoType'],
              value: datum["isoCode"]
            }));

          returnData.current = dropdownItems
          // setReturnData(dropdownItems)
          onSuccess(dropdownItems)
        }else{
          returnData.current = data.content
          // setReturnData(data.content)
          onSuccess(data.content)
        }

      },
      (error) => {
          console.log('SharedServiceApi : ' + error)
          // alert('SharedServiceApi : ' + JSON.stringify(error))
      }
  )
}

const SharedServiceApi = Object.freeze({
  fetchContainerType
})

export default SharedServiceApi;