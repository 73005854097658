import React from 'react'
import { Card, CardContent, Divider, Grid } from '@material-ui/core'
import { components, constants, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import CalistaAdvisorySystemConfigApiUrls from 'src/apiUrls/cia-admin/CalistaAdvisorySystemConfigApiUrls'
import CalistaAdvisoryKey from 'src/constants/locale/key/CalistaAdvisory'

const {
    card: {CngSimpleCardHeader},
    table: { CngCrudTable, useDefaultNotification}
} = components
const { locale: {key: {UiComponentKeys} } } = constants

function TablePage(props){

    const { location: { pathname }, showNotification } = props
    const notification = useDefaultNotification(showNotification)
    const { translate } = useTranslation([
        Namespace.UI_COMPONENT, Namespace.CALISTA_ADVISORY
    ])
    const translatedTextsObject = makeTranslatedTextsObject()

    function makeTranslatedTextsObject(){
        let title = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.SUB_MODULE_TITLE.CALISTA_ADVISORY_SYSTEM_CONFIG)

        let tableTitle = translate(
            Namespace.UI_COMPONENT,
            UiComponentKeys.Table.TITLE,
            { nameTitleised: title}
        )

        // columns
        let groupName = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.CONFIG_GROUP)
        let configCode = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.CONFIG_CODE)
		let value = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.VALUE)
		let description = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.DESCRIPTION)

        return {
            tableTitle,
            groupName,
            configCode,
			value,
			description
        }
    }

    // * Table Columns
    const columns = [
        {
            field: 'groupName', title: translatedTextsObject.groupName,
            lookup: {
                // "General Settings": "General Settings",
                "CIA Mass Rec Settings": "CIA Mass Rec Settings",
                "TAS Settings": "TAS Settings",
                "Honeyguide Settings": "Honeyguide Settings",
                "Transaction Settings": "Transaction Settings",
                "Amber Road Settings": "Amber Road Settings"
            },
        },
        { field: 'name', title: translatedTextsObject.configCode },
		{ field: 'value', title: translatedTextsObject.value },
		{ field: 'description', title: translatedTextsObject.description }
    ];

    //* return JSX
    return (

        //* Start JSX
        <Card>
            <CngSimpleCardHeader title={translatedTextsObject.tableTitle}/>
            <Divider/>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} style={{wordBreak: "break-word"}}>
                        <CngCrudTable {...props}
                        notification = {notification}
                        
                        // table fetch and data details
                        fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                        fetch={{ url: CalistaAdvisorySystemConfigApiUrls.GET}}
                        idAccessor='id'
                        columns={columns}

                        // view route
                        viewRoute={`${pathname}/view`}

                        // edit route
                        editRoute={`${pathname}/edit`}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        //* End JSX
    );
    //* End of function
}

export default TablePage