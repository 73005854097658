import React, { useState, useEffect } from 'react'
import { CardContent, Grid, RadioGroup, FormControlLabel, Radio, makeStyles } from '@material-ui/core'

import { useTranslation, components } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import CalistaAdvisoryKeys from 'src/constants/locale/key/CalistaAdvisory'
import { KeyboardDatePicker } from "@material-ui/pickers"


const {
    form: { field: { CngDateField } },
} = components

const useStyles = makeStyles(theme => ({
    date_box: {
        border: "1px solid #EEEEEE",
        borderRadius: "8px",
        display: 'inline-block',
        padding: theme.spacing(1),
        marginLeft: '5px',
        marginBottom: '5px',
        width: "156px"
    }
}))

function DateFilter({
    clearAllFilterClickedTime,
    onChange,
    title,
    setStartingDateChange,
    setEndingDateChange,
    setDateOptionValue
}) {

    const classes = useStyles()

    const [startingDateChange, handleStartingDateChange] = useState(new Date())
    const [endingDateChange, handleEndingDateChange] = useState(new Date())

    // Translations ------------------------------------------------------------
    const { translate } = useTranslation(Namespace.CALISTA_ADVISORY)
    const FilterKeys = CalistaAdvisoryKeys.CIA_ADMIN_MANAGEMENT.FILTERS;

    const Labels = {
        DATE: translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKeys.FIELDS.DATE),
        ALL: translate(Namespace.CALISTA_ADVISORY, FilterKeys.ALL),
        PAST_WEEK: translate(Namespace.CALISTA_ADVISORY, FilterKeys.PAST_WEEK),
        PAST_30_DAYS: translate(Namespace.CALISTA_ADVISORY, FilterKeys.PAST_30_DAYS),
        PAST_60_DAYS: translate(Namespace.CALISTA_ADVISORY, FilterKeys.PAST_60_DAYS),
        CUSTOM_RANGE: translate(Namespace.CALISTA_ADVISORY, FilterKeys.CUSTOM_RANGE),
    }

    //* ------------------------------------------------------------------------
    const DEFAULT_VALUE = 'WEEK'
    const [value, setValue] = useState(DEFAULT_VALUE)

    const handleChange = (event) => {
        setValue(event.target.value)

        if (onChange) {
            onChange(event.target.value)

        }
    }

    // Monitor the Clear All clicked time. When changed, reset the value.
    useEffect(() => {
        setValue(DEFAULT_VALUE)
    }, [clearAllFilterClickedTime])

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container>
            <Grid item xs={12}>
                <CardContent style={{
                    background: '#8996AF10',
                    padding: '1em',
                    marginTop: '1em',
                    textTransform: 'uppercase',
                    fontWeight: 'bold'
                }}>
                    {title}
                </CardContent>
            </Grid>
            <Grid item xs={12}>
                <CardContent>
                    <RadioGroup value={value} onChange={handleChange}>
                        <FormControlLabel value='ALL' control={<Radio />} label={Labels.ALL} />
                        <FormControlLabel value='WEEK' control={<Radio />} label={Labels.PAST_WEEK} />
                        <FormControlLabel value='DAYS_30' control={<Radio />} label={Labels.PAST_30_DAYS} />
                        <FormControlLabel value='DAYS_60' control={<Radio />} label={Labels.PAST_60_DAYS} />
                        <FormControlLabel value='CUSTOM_RANGE' control={<Radio />} label={Labels.CUSTOM_RANGE} />
                    </RadioGroup>
                    <Grid style={value === 'CUSTOM_RANGE' ? {} : { display: 'none' }} >
                        <KeyboardDatePicker
                            className={classes.date_box}
                            label='Starting date'
                            name='selectedFromDate'
                            format="yyyy-MM-DD"
                            value={startingDateChange}
                            onChange={(newValue) => {
                                setStartingDateChange(newValue);
                                handleStartingDateChange(newValue);
                            }}
                            maxDate={new Date()}
                            InputProps={{
                                disableUnderline: true
                            }}
                        />

                        <KeyboardDatePicker
                            className={classes.date_box}
                            label='Ending date'
                            name='selectedFromDate'
                            format="yyyy-MM-DD"
                            value={endingDateChange}
                            onChange={(newValue) => {
                                setEndingDateChange(newValue);
                                handleEndingDateChange(newValue);
                            }}
                            maxDate={new Date()}
                            InputProps={{
                                disableUnderline: true
                            }}
                        />

                    </Grid>
                </CardContent>
            </Grid>
        </Grid>
        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default DateFilter
