import React, { useState } from 'react'
import { Grid, Divider, Typography, Hidden } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'
import { useFormContext } from 'react-hook-form'

import Namespace from 'src/constants/locale/Namespace'
import CalistaAdvisoryKey from 'src/constants/locale/key/CalistaAdvisory'

import TransactionLimitTypeSelector from './TransactionLimitTypeSelector'

const {
    form: { field: { CngTextField, CngSwitchField, CngSelectField } },
    CngGridItem
} = components

//* Field default values -------------------------------------------------------
const initialValues = Object.freeze({
    tasLandedCostApiEnabled: true,
    tasLandedCostTransactionLimitType: "",
    tasLandedCostLimitValue: ""
})

//* Fields function
const FormBody = ({disabled}) => {
    const form = useFormContext()
    const { translate } = useTranslation(Namespace.CALISTA_ADVISORY)
    const translatedTextsObject = makeTranslatedTextsObject()

    const [isApiEnabled, setApiEnabled] = useState(form.getValues('tasLandedCostApiEnabled'))
    const [hasLimit, setHasLimit] = useState(form.getValues('tasLandedCostTransactionLimitType') != 'No Limit')

    const handleSwitchChange = (e) => {
        form.setValue('tasLandedCostApiEnabled', e.target.checked)
        setApiEnabled(e.target.checked)
    }

    //* Translation function
    function makeTranslatedTextsObject() {
        let tasLandedCostSettings = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.TAS_LANDED_COST_SETTINGS)
        let tasLandedCostApiEnabled = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.TAS_LANDED_COST_API_ENABLED)
        let tasLandedCostTransactionLimitType = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.TAS_LANDED_COST_TRANSACTION_LIMIT_TYPE)
        let tasLandedCostLimitValue = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.TAS_LANDED_C0ST_LIMIT_VALUE)

        return {
            tasLandedCostSettings,
            tasLandedCostApiEnabled,
            tasLandedCostTransactionLimitType,
            tasLandedCostLimitValue,
        }
    }

    //* return JSX
    return (

        //* Start JSX
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Divider />
            </Grid>

            <Grid item xs={12}>
                <Typography variant='h5' display="block" className='font-bolder'>
                    {translatedTextsObject.tasLandedCostSettings}
                </Typography>
            </Grid>
 
            <CngGridItem xs={12} sm={6}>
                <CngSwitchField name="tasLandedCostApiEnabled"
                    label={translatedTextsObject.tasLandedCostApiEnabled}
                    disabled={disabled} 
                    checked={isApiEnabled}
                    onChange={handleSwitchChange}
                />
            </CngGridItem>

            <Hidden only={['xs']}>
                <CngGridItem sm={6}>
                    {/* Just to align things properly */}
                </CngGridItem>
            </Hidden>

            <TransactionLimitTypeSelector
                name="tasLandedCostTransactionLimitType"
                label={translatedTextsObject.tasLandedCostTransactionLimitType}
                setHasLimit={setHasLimit}
                disabled={disabled || !isApiEnabled}
            />

            <CngGridItem xs={12} sm={6}>
                <CngTextField name="tasLandedCostLimitValue"
                    label={translatedTextsObject.tasLandedCostLimitValue}
                    disabled={disabled || !isApiEnabled || !hasLimit}
                    type="number" />
            </CngGridItem>
        </Grid>
        //* End JSX
    )
    //* End of function
}

const LandedCostSettingsComponent = Object.freeze({
    initialValues: initialValues,
    FormBody: FormBody
})


export default LandedCostSettingsComponent