import React from 'react'
import { Yup } from 'cng-web-lib'

const requiredErrorMessage = 'Required/Mandatory'


const ValidationSchemaForCourierConfig = () => {

  return Yup.object({
    connectorId: Yup.string().nullable().required(requiredErrorMessage),
    endpointId: Yup.string().nullable().required(requiredErrorMessage),
    credentialId: Yup.string().nullable().required(requiredErrorMessage)
  })
}

const ValidationSchemaForCustomerConfig = () => {

  return Yup.object({
    connectorId: Yup.string().nullable().required(requiredErrorMessage),
    endpointId: Yup.string().nullable().required(requiredErrorMessage)
  })
}

export { ValidationSchemaForCourierConfig, ValidationSchemaForCustomerConfig }

