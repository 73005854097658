import React from 'react'
import { Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'
import TranslationText from "../TranslatedText";

const {
    form: { field: { CngTextField } },
    CngGridItem,
} = components

//* Field default values -------------------------------------------------------
const DEFAULT_INITIAL_VALUES = Object.freeze({
    name: ""
})

//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES }
}

//* Fields function ------------------------------------------------------------
function Fields({ disabled, showNotification, shouldHideMap, id }) {

    const translatedTextObject = TranslationText()

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX --------------------------------------------------------------
        <Grid container spacing={3}>

            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyName}>
                <CngTextField name="name"
                              label={translatedTextObject.partyName}
                              disabled={disabled}
                />
            </CngGridItem>
        </Grid>
        //* End JSX ----------------------------------------------------------------

    )
    //* End of function --------------------------------------------------------
}

function toClientDataFormat(serverData) {
    let localData = serverData;
    return localData;
}

function toServerDataFormat(localData) {
    return localData;
}

const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties
