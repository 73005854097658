import { constants, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

// function CommonValidationTranslationText(translate) {
const CommonValidationTranslationText = () => {
    const { translate } = useTranslation([
      Namespace.COMMON_VALIDATION_MSG
    ])

    let requiredMessage = translate(
      Namespace.COMMON_VALIDATION_MSG,
      CommonValidationMessageKeys.REQUIRED
    )
    
    let dateTypeErrorMessage = translate(
      Namespace.COMMON_VALIDATION_MSG,
      CommonValidationMessageKeys.DATE_TYPE_ERROR
    )

    return {
        requiredMessage,
        dateTypeErrorMessage
    }
}

export default CommonValidationTranslationText