import React from 'react';
import { constants, useTranslation } from 'cng-web-lib'

import Namespace from "../../../constants/locale/Namespace";
import CecUomConversionKeys from 'src/constants/locale/key/CecUomConversion'

const NAMESPACE = Namespace.CEC_UOM_CONVERSION;

const {
    locale: {
        key: {
            UiComponentKeys,
        },
    },
} = constants

const TranslationText = () => {

    const { translate } = useTranslation([NAMESPACE])

    const moduleTitle = translate(NAMESPACE, CecUomConversionKeys.CEC_UOM_CONVERSION_TITLE);

    const uomType = translate(NAMESPACE, CecUomConversionKeys.CEC_UOM_CONVERSION_UOMTYPE)
    const inputUom = translate(NAMESPACE, CecUomConversionKeys.CEC_UOM_CONVERSION_INPUTUOM)
    const outputUom = translate(NAMESPACE, CecUomConversionKeys.CEC_UOM_CONVERSION_OUTPUTUOM)
    const conversion = translate(NAMESPACE, CecUomConversionKeys.CEC_UOM_CONVERSION_CONVERSION)

    let cecUomConversion = translate(Namespace.CEC_UOM_CONVERSION, CecUomConversionKeys.CEC_UOM_CONVERSION_TITLE)


    return {
        moduleTitle,
        uomType,
        inputUom,
        outputUom,
        conversion,
        cecUomConversion
    }
}

export default TranslationText;


