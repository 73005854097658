import makeValidationSchema from './PersonMakeValidationSchema'
import { useTranslation,components } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import CecAccountKeys from 'src/constants/locale/key/CecAccount'
import { Card, CardContent, Grid } from '@material-ui/core'


const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngSwitchField,
      CngSelectField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
    salutation: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    designation:"",
    isPrimary: false
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES } ,
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.CEC_ACCOUNT)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let person = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.PERSON_TITLE
    )
    let salutation = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.SALUTATION
    )
    let firstName = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.FIRST_NAME
    )
    let lastName = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.LAST_NAME
    )
    let email = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.EMAIL
    )
    let phone = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.PHONE
    )
    let designation = translate(
        Namespace.CEC_ACCOUNT,
        CecAccountKeys.DESIGNATION
    )
    let isPrimary = translate(
        Namespace.CEC_ACCOUNT,
        CecAccountKeys.IS_PRIMARY
    )

    return {
      person,
      salutation,
      firstName,
      lastName,
      email,
      phone,
      designation,
      isPrimary
    }
  }

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.person} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.salutation}>
            <CngSelectField
                  name="salutation"
                  label={translatedTextsObject.salutation  +" *"}
                  disabled={disabled}    
                  items={[{text: "Mr", value: "Mr"}, 
                 {text: "Ms", value: "Ms"},
                 {text: "Dr", value: "Dr"},
                 {text: "Mdm", value: "Mdm"},
                 {text: "Mrs", value: "Mrs"}
                ]}>
            </CngSelectField>
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.firstName}>
          <CngTextField
                  name="firstName"
                  label={translatedTextsObject.firstName + " *"} 
                  disabled={disabled}
                  inputProps={
                    {maxLength: 255}
                  }
                />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.lastName}>
          <CngTextField
                  name="lastName"
                  label={translatedTextsObject.lastName + " *"} 
                  disabled={disabled}
                  inputProps={
                    {maxLength: 255}
                  }
                />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.email}>
          <CngTextField
                  name="email"
                  label={translatedTextsObject.email + " *"} 
                  disabled={disabled}
                  inputProps={
                    {maxLength: 255}
                  }
                />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.phone}>
            <CngTextField
              name="phone"
              label={translatedTextsObject.phone}
              inputProps={
                {maxLength: 255}
              }
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.designation}>
            <CngTextField
              name="designation"
              label={translatedTextsObject.designation}
              inputProps={
                {maxLength: 255}
              }
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.isPrimary}>
            <CngSwitchField
              name="isPrimary"
              label={translatedTextsObject.isPrimary }
              
              disabled={disabled}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
	)
}

const AccountFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default AccountFormProperties
