import React, { useState, useEffect, useRef } from 'react'
import { Grid, makeStyles, Typography, CircularProgress } from '@material-ui/core'
import { useServices } from 'cng-web-lib'

import TransactionBox from './TransactionBox'
import TransactionRow from './TransactionRow'
import TransactionChart from './TransactionChart'
import PanelLoading from './PanelLoading'
import PanelError from './PanelError'
import { getPartyID } from 'src/store/intelligent-advisory'
import { CIAApiUrls } from 'src/apiUrls'
import moment from 'moment'
import LoadMoreObserver from 'src/components/misc/LoadMoreObserver'

const useStyles = makeStyles(theme => ({
    text: {
        fontSize: '20px',
        fontWeight: '700'
    }
}))


function AdminMgmt_LeftPanel({
    filterData,
    selectedStartingDate,
    selectedEndingDate,
    ...props
}) {

    const classes = useStyles()

    //** Calista Variables
    const { securedSendRequest } = useServices();

    //* ------------------------------------------------------------------------

    const [transactionResult, setTransactionResult] = useState(null)

    const [initialLoadingState, setInitialLoadingState] = useState({
        isInitialLoading: false,
        isInitialLoadingError: false
    })
    const [loadingMoreState, setLoadingMoreState] = useState({
        isLoadingMore: false,
        isLoadingMoreError: false,
        isNoMoreData: false
    })

    const fromDate = useRef()
    const toDate = useRef()
    const fetchRow = 10;
    const offSetRow = useRef();
    const isForInfiniteList = useRef();
    const TransactionListData = useRef([])

    //** Initial load effect. Will also be triggered by the sort option onChange.
    useEffect(() => {
        callCiaAcctMgmtApi({
            onBeforeStart: () => {
                // reset the ref data
                offSetRow.current = 0
                TransactionListData.current = []
                getDateValue()
                isForInfiniteList.current = false

                // reset the load more data
                setLoadingMoreState({
                    isLoadingMore: false,
                    isLoadingMoreError: false,
                    isNoMoreData: false
                })

                setInitialLoadingState({ ...initialLoadingState, isInitialLoading: true })
            },
            onSuccess: (response) => {
                console.debug(response)

                processResponse(response.data)
                setInitialLoadingState({ ...initialLoadingState, isInitialLoading: false })
            },
            onError: (error) => {
                console.error(error)

                setInitialLoadingState({
                    ...initialLoadingState,
                    isInitialLoading: false,
                    isInitialLoadingError: true
                })
            },
            onComplete: () => { /* Do nothing */ }
        })

    }, [filterData])

    function processResponse(response) {
        console.log("response", response)
        if (response.transaction_records != null) {
            let newTransactionListData = [...TransactionListData.current, ...response.transaction_records]
            TransactionListData.current = newTransactionListData

            setTransactionResult(response)

            offSetRow.current = offSetRow.current + fetchRow
        }

    }


    function callCiaAcctMgmtApi({ onBeforeStart, onSuccess, onError, onComplete }) {

        if (onBeforeStart) { onBeforeStart() }

        let partyId = null
        if (props.partyId == null || props.partyId == undefined)
            partyId = getPartyID()
        else
            partyId = props.partyId

        let url = CIAApiUrls.GET_MGMT_DETAIL
        let data = {
            party_id: partyId,
            from_date: fromDate.current,
            to_date: toDate.current,
            offSetRow: offSetRow.current,
            fetchRow: fetchRow,
            modules: filterData.transactionTypeFilter,
            forInfiniteUse: isForInfiniteList.current
        }

        console.log("request body", data)

        //** call CIA api /cal-cia/tas/common/getacctmgmtdetail
        securedSendRequest.execute("POST", url, data,
            (response) => {
                console.debug(response)
                if (onSuccess) { onSuccess(response) }
            },
            (error) => {
                console.error(error)
                if (onError) { onError(error) }
            },
            () => { if (onComplete) { onComplete() } }
        )
    }


    //** the infinite scroll
    const handleInfiniteScroll = (entries, observer) => {

        if (initialLoadingState.isInitialLoading) {
            //** do nothing. it is still initial loading.
            return;
        }

        if (loadingMoreState.isLoadingMore) {
            //** Is still loading more.
            return;
        }

        if (loadingMoreState.isNoMoreData) {
            //** do nothing also. There is no more data to load.
            return;
        }

        callCiaAcctMgmtApi({
            onBeforeStart: () => {
                setLoadingMoreState({ ...loadingMoreState, isLoadingMore: true })
                isForInfiniteList.current = true

            },
            onSuccess: (response) => {
                console.debug(response)
                //** if ((response) && (response.data) && (response.data.length)) {
                if ((response) && (response.data) && response.data.transaction_records != null) {
                    let newTransactionListData = [...TransactionListData.current, ...response.data.transaction_records]
                    TransactionListData.current = newTransactionListData

                    offSetRow.current = offSetRow.current + fetchRow

                    setLoadingMoreState({ ...loadingMoreState, isLoadingMore: false })
                }
                else {
                    //** no more data.
                    setLoadingMoreState({
                        ...loadingMoreState,
                        isLoadingMore: false,
                        isNoMoreData: true
                    })
                }

            },
            onError: (error) => {
                console.error(error)

                setLoadingMoreState({
                    ...loadingMoreState,
                    isLoadingMore: false,
                    isLoadingMoreError: true
                })
            },
            onComplete: () => { /* Do nothing */ }
        })
    }

    const getDateValue = () => {
        var today = moment(new Date());
        toDate.current = today.format("YYYY-MM-DD")

        switch (filterData.dateFilter) {
            case 'ALL':
                fromDate.current = moment(new Date('2018-01-01')).format("YYYY-MM-DD")
                break
            case 'WEEK':
                fromDate.current = today.add(-7, 'day').format("YYYY-MM-DD")
                break;
            case 'DAYS_30':
                fromDate.current = today.add(-30, 'day').format("YYYY-MM-DD")
                break;
            case 'DAYS_60':
                fromDate.current = today.add(-60, 'day').format("YYYY-MM-DD")
                break;
            case 'CUSTOM_RANGE':
                toDate.current = moment(selectedEndingDate).format("YYYY-MM-DD")
                fromDate.current = moment(selectedStartingDate).format("YYYY-MM-DD")
                break;
            default:
            //do nothing
        }
    }

    //* ------------------------------------------------------------------------
    //* Return JSX - Loading...
    //* ------------------------------------------------------------------------
    if (initialLoadingState.isInitialLoading == true) {
        return (<PanelLoading />)
    }

    //* ------------------------------------------------------------------------
    //* Return JSX - Error...
    //* ------------------------------------------------------------------------
    if (initialLoadingState.isInitialLoadingError == true) {
        return (<PanelError />)
    }


    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TransactionBox {...transactionResult} />
                <br />
                <TransactionChart {...transactionResult} />
                <br />
                <Grid>
                    {TransactionListData.current && (
                        <Typography variant='caption' className={classes.text}>
                            Transaction records
                        </Typography>
                    )}
                    <br />
                    <Grid item xs={12} >
                        {
                            TransactionListData.current.map((transaction, index) => {
                                let key = "TransactionRow_" + index;

                                return (
                                    <TransactionRow
                                        key={key}
                                        transaction={transaction}
                                    />
                                )
                            })
                        }
                        <Grid className="load_more" item xs={12}>
                            {(
                                () => { // use annon function to return if-else condition.
                                    if (loadingMoreState.isNoMoreData) {
                                        return (<span>No more data to load.</span>)
                                    }
                                    else if (loadingMoreState.isLoadingMoreError) {
                                        return (<span>Error loading more data.</span>)
                                    }
                                    else {
                                        return (
                                            <LoadMoreObserver onInView={handleInfiniteScroll}>
                                                {
                                                    loadingMoreState.isLoadingMore && // show spinner only when isLoadingMore
                                                    <CircularProgress />
                                                }
                                            </LoadMoreObserver>
                                        )
                                    }
                                }
                            )()}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default AdminMgmt_LeftPanel
