import React, {useRef} from 'react'

import CodeMaintainanceApiUrls from 'src/apiUrls/CodeMaintainanceApiUrls'
import { constants, } from 'cng-web-lib'

const {
  CodeMaintenanceType
} = constants

function fetchCodeMaster(fetchRecords, codeMasterType, dropdown = false, onSuccess) {
    let returnData = []

    console.log('fetchCodeMaster...')
fetchRecords.execute(
        CodeMaintainanceApiUrls.MAINTAINANCE_CODE,
        { 
          customData: { codeMType: CodeMaintenanceType.CODE_MASTER },
          filters: [
            {
              field: 'codeType',
              operator: 'EQUAL',
              value: codeMasterType
            }
          ]
        },
        (data) => {
          /*data massage here*/
          if(dropdown){
            let dropdownItems = data.content.map((datum) => ({
                text:datum['descriptionEn'],
                value: datum["code"]
            }));

            returnData = dropdownItems
          }else{
            returnData = data.content
          }
            
            onSuccess(returnData)
        },
        (error) => {
            console.log('useFetchCodeMaster : ' + error)
            // alert('useFetchCodeMaster : ' + error)
        }
    )
}

function fetchMultiCodeMaster(fetchRecords, codeMasterTypes, onSuccess) {
  console.log('fetchMultiCodeMaster...')
  fetchRecords.execute(
      CodeMaintainanceApiUrls.MAINTAINANCE_CODE,
      { 
        customData: { codeMType: CodeMaintenanceType.CODE_MASTER },
        filters: [
          {
            field: 'codeType',
            operator: 'IN',
            value: codeMasterTypes
          }
        ]
      },
      (data) => {        
          
          onSuccess(data.content)
      },
      (error) => {
          console.log('useFetchCodeMaster : ' + error)
      }
  )
}
const CodeMasterApi = Object.freeze({
    fetchCodeMaster,
    fetchMultiCodeMaster  
})

export default CodeMasterApi;