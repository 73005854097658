import CecAccountApiUrls from 'src/apiUrls/CecAccountApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React from 'react'
import { components, useServices } from 'cng-web-lib'
import pathMap from 'src/paths/pathMap'
import { useLocation } from 'react-router-dom'

const {
  form: { CngAddForm },
  table: { useDefaultNotification }
} = components

function AddPage({ history, showNotification }) {
  const { securedSendRequest } = useServices();
  const loc = useLocation()

  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)
  
  const onSubmit = (localData) => {

    const reqData = { "account" : [{"accountNumber" : localData.accountNumber ,
    "displayName" : localData.displayName,
    "status" : localData.status,
    "address": {"city":localData.city,
    "street":localData.street,"state":localData.stateId,"stateOther":localData.stateOther,
    "country":localData.country,
    "postcode":localData.postcode,
    "type":localData.addressTypeId},
    "person": localData.person }]
      } 
    const onSuccess = () => {
      showSuccessNotification("Create Successful");
      history.push(pathMap.CEC_ACCOUNT_LIST_VIEW)
    }
    const onComplete = () => {
      console.log("Create Completed")
    }
    
    const onError = (error) => {
      console.log("Error... "+ JSON.stringify(error))
      if(null != error && null != error.response && null != error.response.data){
        showErrorNotification("Create records failed: "+ error.response.data);
      }
    }
  securedSendRequest.execute(
    'post',
    CecAccountApiUrls.CREATE_ACCOUNT,
    reqData,
    onSuccess,
    onError,
    onComplete,
    { 
       headers : {
        Authorization: `Bearer `+localStorage.getItem("userToken")
       }
    }
  )
  }

  let formikPropsData;
  if(loc.state !== undefined) {
  formikPropsData = {
  ...FormProperties.formikProps,
  initialValues: loc.state 
  }
 } else {
 
  formikPropsData = {
    ...FormProperties.formikProps,
    onSubmit:onSubmit
  }
 }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngAddForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          history={history}
          showNotification={showNotification}
          formikProps={formikPropsData}
          bodySection={
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              mode={'add'}
            />
          }
           
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
        />
      </Grid>
    </Grid>
  )
}

export default AddPage
