import React from 'react'
import PropTypes from 'prop-types'
import { components } from 'cng-web-lib'
import TranslationText from './TranslationText'
import ConnectorMappingUrls from '../../../../apiUrls/cec-admin/shipment-provider/ShipmentProviderConnectorMappingUrls'
import FormProperties from './forms/FormProperties'
import CecAdminPathMap from '../../../../paths/PathMap_CeCAdmin'
import { useHistory } from 'react-router-dom'
import ConnectorType from '../../../../constants/cec/ConnectorType'

const {
  table: {
    CngServerModeDialogFormTable, useFetchCustomLookup, useDefaultNotification
  }
} = components


const CrudTable = ({ shipmentProviderId, isViewOnly, showNotification, connectorType, connectorTypeId }) => {

  const history = useHistory()

  const onEditButtonClick = (event, record) => {
    history.push({
      pathname: CecAdminPathMap.SHIPMENT_PROVIDER_CONNECTOR_MAPPING + '/edit/' + record['id'],
      state: { connectorRecord: record, connectorType: connectorType }
    })

  }

  const onAddButtonClick = () => {
    history.push({
      pathname: CecAdminPathMap.SHIPMENT_PROVIDER_CONNECTOR_MAPPING + '/add',
      state: { shipmentProviderId: shipmentProviderId, connectorType: connectorType, connectorTypeId: connectorTypeId }
    })
  }

  const onViewButtonClick = (event, record) => {
    history.push({
      pathname: CecAdminPathMap.SHIPMENT_PROVIDER_CONNECTOR_MAPPING + '/view/' + record['id'],
      state: { connectorRecord: record, connectorType: connectorType }
    })
  }

  const {
    connectorName,
    endpointName,
    credentialName
  } = TranslationText()
  const { GET, CREATE, DELETE } = ConnectorMappingUrls

  const commonColumns = [{
    field: 'connectorApp.name',
    title: connectorName
  }, {
    field: 'connectorAppEndpoint.name',
    title: endpointName
  }
  ]

  const columnsOnlyInShipperConfigTable = [{
    field: 'connectorAppCredential.name',
    title: credentialName
  }
  ]


  return (

    <CngServerModeDialogFormTable
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      disabled={isViewOnly}
      showNotification={showNotification}
      tableColumns={connectorType === ConnectorType.CustomerApplication ? commonColumns : [...commonColumns, ...columnsOnlyInShipperConfigTable]}
      pageSize={5}
      formProperties={{
        formikProps: {
          initialValues: (connectorType === ConnectorType.CustomerApplication) ? FormProperties.formikPropsForCourierConfig.initialValues : null
        }
      }}
      toClientDataFormat={(serverData) => {
        return serverData
      }}
      toServerDataFormat={(localDatum) => {
        return localDatum
      }}
      fetch={{
        url: GET, body: {
          shipmentProviderId: shipmentProviderId, connectorType: connectorType
        }
      }}
      create={{
        url: CREATE, body: {
          shipmentProviderId: shipmentProviderId, connectorType: connectorType
        }
      }}
      onEditButtonClick={onEditButtonClick}
      onAddButtonClick={onAddButtonClick}
      onViewButtonClick={onViewButtonClick}
      del={{ url: DELETE }}
      idAccessor='id'
    />


  )
}

CrudTable.propTypes = {
  showNotification: PropTypes.func,
  shipmentProviderId: PropTypes.number,
  isViewOnly: PropTypes.bool,
  connectorType: PropTypes.string
}

export default CrudTable


