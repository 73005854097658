import React from 'react'
import SearchScheduleApiUrls from 'src/apiUrls/SearchScheduleApiUrls.js'

function fetchCarrier(fetchRecords, onSuccess) {
    let returnData = []

    console.log('fetch Carrier...')
    fetchRecords.execute(
      SearchScheduleApiUrls.CARRIER_ENROLL_GET,
      { 
        
      },
      (data) => {
        returnData.current = data.content
        
        onSuccess(returnData.current)
      },
      (error) => {
          console.log('fetchCarrierApi : ' + error)
          // alert('fetchCarrierApi : ' + error)
      }
    )
}

const CarrierApi = Object.freeze({
  fetchCarrier
})

export default CarrierApi;