import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Grid, Box, Button } from '@material-ui/core'
import { styled } from '@material-ui/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Close';

import { useServices } from 'cng-web-lib'
import LoadingSpinnerLabel from 'src/components/label/LoadingSpinnerLabel'
import ErrorRetrievingDataLabel from 'src/components/label/ErrorRetrievingDataLabel'
import CIAApiUrls from 'src/apiUrls/CIAApiUrls'
import { useFormContext } from 'react-hook-form'

import {
  DataGrid,
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEditInputCell
} from '@mui/x-data-grid'

function EditToolbar(props) {
  const { setRows, setRowModesModel, rowNewId, setRowNewId, disabled } = props

  const handleAddHsCodeClick = () => {
    const id = rowNewId.toString();
    setRowNewId(rowNewId-1);
    setRows((oldRows) => [...oldRows, { id, chapter: '', heading: '', subHeading: '', remarks: '', isNew: true }])
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'chapter' }
    }))
  }

  return (
    <GridToolbarContainer>
      <Button disabled={disabled} color='primary' startIcon={<AddIcon />} onClick={handleAddHsCodeClick}>
        Add HS Code
      </Button>
    </GridToolbarContainer>
  )
}

EditToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,
  setRows: PropTypes.func.isRequired,
  rowNewId: PropTypes.number,
  setRowNewId: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
    },
}));

function NameEditInputCell(props) {
    const { error } = props;

    return (
        <StyledTooltip open={!!error} title={error}>
          <GridEditInputCell {...props} />
        </StyledTooltip>
    );
}

function renderEditName(params) {
    return <NameEditInputCell {...params} />;
}

export default function HsCodeCrudTable({ partyId, disabled }) {
  const { formState } = useFormContext()
  const [rows, setRows] = React.useState([])
  const [rowNewId, setRowNewId] = React.useState(-1)
  const [rowModesModel, setRowModesModel] = React.useState({})
  const [initialLoadingState, setInitialLoadingState] = useState({
    isInitialLoading: true,
    isInitialLoadingError: false
  })

  const { securedSendRequest } = useServices()

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const preProcessEditCellProps = (params) => {
    const hasError = params.props.value > 100 || params.props.value < 0;
    return { ...params.props, error: hasError ? "This value should be only 2 digits" : null };
  };

  const columns = [
    {
      field: 'chapter',
      headerName: 'Chapter',
      type: 'number',
      preProcessEditCellProps,
      headerAlign: 'center',
      align: 'center',
      width: 120,
      editable: true,
      renderEditCell: renderEditName,
    },
    {
      field: 'heading',
      headerName: 'Heading',
      type: 'number',
      preProcessEditCellProps,
      renderEditCell: renderEditName,
      headerAlign: 'center',
      align: 'center',
      width: 120,
      editable: true
    },
    {
      field: 'subHeading',
      headerName: 'Sub-Heading',
      type: 'number',
      preProcessEditCellProps,
      renderEditCell: renderEditName,
      headerAlign: 'center',
      align: 'center',
      width: 120,
      editable: true
    },
    {
      field: 'remarks',
      headerName: 'Remarks',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      editable: true
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            disabled={disabled}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            disabled={disabled}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      }
    }
  ]

  const callHsCodeCrudTableAjax = (method) => 
  {
    let url = CIAApiUrls.PARTY_HS_CODE_LIST
    let data = { id: partyId, hsCodeList: rows }

    securedSendRequest.execute(
        method,
        url,
        data,
        (response) => {
        // success
        setInitialLoadingState({
            isInitialLoading: false,
            isInitialLoadingError: false
        })

        setRows(response.data)
        },
        (error) => {
        // error
        console.error(error)
        setInitialLoadingState({
          isInitialLoading: false,
          isInitialLoadingError: true
        })
        },
        () => {
        // complete
        }
    )
  }

  useEffect(() => {
    // On page load, call Ajax to get the list of party hs codes.
    callHsCodeCrudTableAjax('POST');
  }, [])

  useEffect(() => {
    // effect that triggered by user pressing "Update"
    if( formState.isSubmitted && !formState.isSubmitting )
    {
      callHsCodeCrudTableAjax('PUT');
    }
  }, [formState])

  return (
    <Box sx={{ height: 600, width: '100%' }}>
      {initialLoadingState.isInitialLoading == true && (
        /* Show a loading spinner during initial loading. */
        <Grid item xs={12}>
          <LoadingSpinnerLabel />
        </Grid>
      )}
      {initialLoadingState.isInitialLoadingError == true && (
        /* Show error if failed to initial load. */
        <Grid item xs={12}>
          <ErrorRetrievingDataLabel />
        </Grid>
      )}
      {initialLoadingState.isInitialLoadingError == false &&
        initialLoadingState.isInitialLoading == false && (
          <DataGrid
            rows={rows}
            columns={columns}
            editMode="row"
            pageSize={20}
            rowsPerPageOptions={[20]}
            // checkboxSelection
            disableSelectionOnClick
            rowModesModel={rowModesModel}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            components={{
              Toolbar: EditToolbar
            }}
            componentsProps={{
              toolbar: { setRows, setRowModesModel, rowNewId, setRowNewId, disabled }
            }}
            experimentalFeatures={{ newEditingApi: true }}
          />
        )}
    </Box>
  )
}