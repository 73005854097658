import moment from 'moment';
import {
  Box,
} from '@material-ui/core'
import React from 'react'

function DocumentInfoDecoratorComponent(props) {

  function bytesToSize(bytes, decimals) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  
    const i = Math.floor(Math.log(bytes) / Math.log(k));
  
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  return (
    <Box className="view-card-content" >
      <Box className="view-card-content-value" >
        {props.bold ? <b>{props.name}</b> : props.name}


      </Box>
      <Box className="view-card-content-label" >
        {bytesToSize(props.size,2)}
        {props.displayDate ? ' - '+moment(props.displayDate).local().format('DD MMM YYYY, hh:mm A') : ''}
      </Box>
    </Box>
  )
}

export default DocumentInfoDecoratorComponent
