import React, { useState } from 'react'
import ValidationSchema from './ValidationSchema'
import { components, constants } from 'cng-web-lib'
import TranslationText from '../TranslationText'
import { Card, CardContent, Grid } from '@material-ui/core'
import AccordionHeaderComponent from '../../../../common/ui/AccordionHeaderComponent'
import CountrySelectAutoComplete from '../../../../common/ui/CountrySelectAutoComplete'
import SCOAdminApiUrls from '../../../../../apiUrls/SCOAdminApiUrls'
import { useFormContext } from 'react-hook-form'
import DefaultPartyType from '../../../../../constants/cec/DefaultPartyType'


const {
  form: {
    field: {
      CngTextField, CngLookupAutocompleteField
    }
  }, CngGridItem
} = components


const {
  filter: { EQUAL }
} = constants


function Fields({ ...props }) {
  const { disabled, partyType } = props
  const [countryCode, setCountryCode] = useState()
  const { setValue, watch } = useFormContext()
  const {
    shipperDetailsTitle,
    consigneeDetailsTitle,
    name,
    phone,
    fax,
    email,
    companyName,
    taxNo,
    address,
    city,
    state,
    country,
    postalCode,
    addressType
  } = TranslationText()


  function getTitle() {
    let returnValue = ''
    if (partyType) {
      if (partyType === DefaultPartyType.Shipper) {
        returnValue = shipperDetailsTitle
      } else if (partyType === DefaultPartyType.Consignee) {
        returnValue = consigneeDetailsTitle
      }
    }
    return returnValue
  }

  const clearExistingValues = (val) => {
    if (!val) {
      setValue('stateId', '')
      setValue('address', '')
      setValue('city', '')
      setValue('postalCode', '')
    } else {
      setValue('stateId', '')
    }
  }

  return (<>
      <Grid container spacing={3}>
        <CngGridItem xs={12} sm={12}>
          <Card>
            <CardContent>
              <Grid container>

                <AccordionHeaderComponent
                  title={getTitle()} />

                <Grid container spacing={2}>
                  <CngGridItem xs={12} sm={6}>
                    <CngTextField
                      name='contactName'
                      label={name}
                      disabled={disabled}
                    />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={6}>
                    <CngTextField
                      type='tel'
                      name='phoneNo'
                      label={phone}
                      disabled={disabled}
                    />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={6}>
                    <CngTextField
                      name='faxNo'
                      label={fax}
                      disabled={disabled}
                    />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={6}>
                    <CngTextField
                      type='email'
                      name='email'
                      label={email}
                      disabled={disabled}
                    />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={6}>
                    <CngTextField
                      name='companyName'
                      label={companyName}
                      disabled={disabled}
                    />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={6}>
                    <CngTextField
                      name='taxNo'
                      label={taxNo}
                      disabled={disabled}
                    />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={6}>
                    <CngTextField
                      name='address'
                      label={address}
                      disabled={disabled}
                    />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={6}>
                    <CngTextField
                      name='city'
                      label={city}
                      disabled={disabled}
                    />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={6}>

                    <CngLookupAutocompleteField
                      name='stateId'
                      key={countryCode}
                      label={state}
                      disabled={disabled || !watch('countryCode')}
                      lookupProps={{
                        url: SCOAdminApiUrls.GET_COUNTRY_STATE_LIST,
                        label: 'stateName',
                        value: 'id',
                        filters: [
                          {
                            field: 'countryCode',
                            operator: EQUAL,
                            value: watch('countryCode')
                          }
                        ]
                      }}
                    >
                    </CngLookupAutocompleteField>

                  </CngGridItem>

                  <CngGridItem xs={12} sm={6}>

                    <CountrySelectAutoComplete
                      name='countryCode'
                      label={country}
                      disabled={disabled}
                      onChangeAutoComplete={(val) => {
                        setCountryCode(val)
                        clearExistingValues(val)
                      }}
                    />

                  </CngGridItem>

                  <CngGridItem xs={12} sm={6}>
                    <CngTextField
                      name='postalCode'
                      label={postalCode}
                      disabled={disabled}
                    />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={6}>
                    <CngLookupAutocompleteField
                      name='addressTypeId'
                      label={addressType}
                      disabled={disabled}
                      lookupProps={{
                        url: SCOAdminApiUrls.GET_CODE_MASTER_LIST,
                        label: 'name',
                        value: 'id',
                        filters: [
                          {
                            field: 'codeType',
                            operator: EQUAL,
                            value: 'CEC_ADDRESS_TYPE'
                          }
                        ]
                      }}
                    >
                    </CngLookupAutocompleteField>
                  </CngGridItem>

                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </CngGridItem>
      </Grid>
    </>

  )
}


function toClientDataFormat(serverData) {
  return serverData
}

function toServerDataFormat(localData) {
  return localData
}

const DEFAULT_INITIAL_VALUES = {
  contactName: '',
  phoneNo: '',
  faxNo: '',
  email: '',
  companyName: '',
  taxNo: '',
  address: '',
  city: '',
  stateId: '',
  countryCode: '',
  postalCode: '',
  addressTypeId: ''
}

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }, makeValidationSchema: ValidationSchema
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties