import { Box, Chip, Grid } from '@material-ui/core'
import React, { Fragment, useEffect, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'

import CodeMaintainanceApi from 'src/views/common/api/CodeMatainanceApi'
import Loading from 'src/views/vesselschedule/searchschedule/Loading'
import TooltipsDiv from 'src/views/vesselschedule/searchschedule/cngcomponent/TooltipsDiv'
import searchScheduleApiUrls from 'src/apiUrls/SearchScheduleApiUrls'
import { withStyles } from '@material-ui/core/styles'

const {
  form: {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    field: { CngServerSideAutocompleteField }
  }
} = components

const AsteriskRedCngServerSideAutocompleteField = withStyles({
  root: {
    '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
      color: 'red'
    }
  }
})(CngServerSideAutocompleteField)

const { CodeMaintenanceType } = constants

const CountrySelectAutoComplete = (props) => {
  const {
    name,
    label,
    keyRef,
    value,
    disabled,
    onChangeAutoComplete,
    hideDesc,
    required
  } = props

  const [field] = useField(name)
  const [country, setCountry] = useState([])
  const { fetchRecords } = useServices()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    CodeMaintainanceApi.fetchCodeMaintainance(
      fetchRecords,
      CodeMaintenanceType.COUNTRY,
      [],
      false,
      onLoadCountrySuccess
    )

    function onLoadCountrySuccess(data) {
      setLoading(false)
      setCountry(data)
    }
  }, [])

  return (
    <Fragment>
      <Loading loading={loading} css={true} />
      {!loading ? (
        <AsteriskRedCngServerSideAutocompleteField
          name={name}
          label={label}
          key={keyRef}
          disabled={disabled}
          isRequired={required}
          lookupProps={{
            initFetch: true
          }}
          minFilterChars={2}
          searchableFields={['descriptionEn', 'code']}
          lookupPageableProps={{
            label: 'descriptionEn',
            value: 'code',
            url: searchScheduleApiUrls.MAINTAINANCE_CODE,
            customData: { codeMType: CodeMaintenanceType.COUNTRY },
            pageSize: 5
          }}
          onChange={onChangeAutoComplete}
          textFieldProps={{
            InputProps: {
              customStartAdornment: (autocompleteStartAdornment, options) => {
                const matched = options
                  .map((option) => option.data)
                  .find((port) => port?.code === field.value)
                if (matched) {
                  return (
                    // <Box m={1}>
                    <Box pt={1}>
                      {autocompleteStartAdornment}
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          '/static/images/countries/' +
                          matched.code +
                          '.svg'
                        }
                      />
                    </Box>
                  )
                }
                return autocompleteStartAdornment
              },
              customEndAdornment: (autocompleteEndAdornment, options) => {
                const matched = options
                  .map((option) => option.data)
                  .find((port) => port?.code === field.value)
                if (matched) {
                  return (
                    <Box>
                      <div>
                        {hideDesc ? null : (
                          <span className={'dp-showContentWithTransparent'} style={{paddingRight:'5px'}}>
                            {
                              country[
                                country.findIndex(
                                  (country) => country.code == matched.code
                                )
                              ].descriptionEn
                            }
                          </span>
                        )}
                        <Chip label={<b>{field.value}</b>} size='small' />
                      </div>
                      {autocompleteEndAdornment}
                    </Box>
                  )
                }
              }
            }
          }}
          renderOption={(option) => {
            return (
              <Grid container spacing={1}>
                <Grid item xs={3} sm={3}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      '/static/images/countries/' +
                      option.data.code +
                      '.svg'
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <div>
                    <div className={'dp-showContent'}>
                      <TooltipsDiv text={option.data.descriptionEn} />
                    </div>
                    <div className={'dp-showContentWithTransparent'}>
                      <div className={'truncate_portPair'}>
                        {country[
                          country.findIndex(
                            (country) => country.code == option.data.countryCode
                          )
                        ] !== undefined
                          ? country[
                              country.findIndex(
                                (country) =>
                                  country.code == option.data.countryCode
                              )
                            ].descriptionEn
                          : ''}
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={3} sm={3}>
                  <span>
                    <Chip label={<b>{option.data.code}</b>} size='small' />
                  </span>
                </Grid>
              </Grid>
            )
          }}
        />
      ) : null}
    </Fragment>
  )
}

export default CountrySelectAutoComplete
