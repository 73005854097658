import React from 'react'
import { components } from 'cng-web-lib'

import CecUomConfigApiUrls from 'src/apiUrls/CecUomConfigApiUrls'
import FormProperties from './FormProperties'
import pathMap from 'src/paths/PathMap_CeCAdmin'

const {
  form: {
    CngAddForm
  }
} = components

function AddPage({ history, showNotification }) {
  return (

    <CngAddForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      shouldDisableEnterToSubmit
      showNotification={showNotification}

      history={history}
      bodySection={
        < FormProperties.Fields
          disabled={false}
          showNotification={showNotification}
          mode={"add"}
        />
      }

      formikProps={FormProperties.formikProps}
      toClientDataFormat={FormProperties.toClientDataFormat}
      toServerDataFormat={FormProperties.toServerDataFormat}

      create={{
        url: CecUomConfigApiUrls.CREATE_UOM_CONVERSION,
        successRedirect: pathMap.CEC_UOM_CONVERSION_LIST_VIEW
      }}

    />
  )

}


export default AddPage
