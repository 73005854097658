import { HeightSharp } from '@material-ui/icons'
import React from 'react'
import FreightBookingApiUrls from 'src/apiUrls/FreightBookingApiUrls'
import ShippingInstructionApiUrls from 'src/apiUrls/ShippingInstructionApiUrls'
import * as CalistaConstant from 'src/constants/CalistaConstant'

function downloadDocument(securedSendRequest, doc, module) {
  securedSendRequest.execute(
    'POST',
    getURLBasedOnModule(module), //FreightBookingApiUrls.DOCUMENT_DOWNLOAD,
    { id: doc.docId },
    (data) => {
      var blob = data.data
      var link = document.createElement('a')

      link.href = window.URL.createObjectURL(blob)
      link.download = doc.filename

      document.body.appendChild(link)

      console.log(link)
      // Start download
      link.click()

      // Clean up and remove the link
      link.parentNode.removeChild(link)
    },
    (error) => {
      console.log(error)
    },
    onComplete,

    { responseType: 'blob' }
  )
}

function downloadComplianceDocument(securedSendRequest, doc, module) {
  securedSendRequest.execute(
    'POST',
    getURLBasedOnModule(module), //FreightBookingApiUrls.DOCUMENT_DOWNLOAD,
    { id: doc.docId },
    (data) => {
      let blob = data.data
      let link = document.createElement('a')

      link.href = window.URL.createObjectURL(blob)
      link.download = doc.fileName

      document.body.appendChild(link)

      console.log(link)
      // Start download
      link.click()

      // Clean up and remove the link
      link.parentNode.removeChild(link)
    },
    (error) => {
      console.log(error)
    },
    onComplete,

    { responseType: 'blob' }
  )
}

function onComplete() {
  console.log('complete')
}

function getURLBasedOnModule(module) {
  if (module != null && module === CalistaConstant.MODULE_SI) {
    return ShippingInstructionApiUrls.DOCUMENT_DOWNLOAD
  } else {
    return FreightBookingApiUrls.DOCUMENT_DOWNLOAD
  }
}

function downloadSIUploadedDoc(securedSendRequest, docId, fileName, onError) {
  securedSendRequest.execute(
    'POST',
    ShippingInstructionApiUrls.UPLOAD_SI_DOWNLOAD,
    {
      id: docId
    },
    (data) => {
      console.log(data)
      var blob = data.data
      let url = window.URL.createObjectURL(blob)

      let link = document.createElement('a')
      link.href = url
      link.download = fileName

      link.click()
    },
    (error) => {
      onError(error)
    },
    onComplete,
    { responseType: 'blob' }
  )
}

function downloadSIUploadedDocLog(
  securedSendRequest,
  docId,
  fileName,
  onError
) {
  securedSendRequest.execute(
    'POST',
    ShippingInstructionApiUrls.UPLOAD_SI_DOWNLOAD_LOG,
    {
      id: docId
    },
    (data) => {
      console.log(data)
      var blob = data.data
      let url = window.URL.createObjectURL(blob)

      let link = document.createElement('a')
      link.href = url
      link.download = fileName

      link.click()
    },
    (error) => {
      onError(error)
    },
    onComplete,
    { responseType: 'blob' }
  )
}

const DocumentApi = Object.freeze({
  downloadDocument,
  downloadSIUploadedDoc,
  downloadSIUploadedDocLog,
  downloadComplianceDocument
})

export default DocumentApi
