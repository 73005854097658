import React from 'react'
import Namespace from '../../../constants/locale/Namespace'
import { constants, useTranslation } from 'cng-web-lib'
import PartyConfigKeys from "../../../constants/locale/key/cec/PartyConfigKeys";

const NAMESPACE = Namespace.CEC_PARTY_CONFIG

const {
    locale: {
        key: {
            UiComponentKeys
        }
    }
} = constants

const TranslationText = () => {

    const { translate } = useTranslation([NAMESPACE])

    const moduleTitle = translate(NAMESPACE, PartyConfigKeys.MODULE_TITLE)
    const titleShipmentProviderList = translate(NAMESPACE, PartyConfigKeys.PARTY_CONFIG_LIST)

    // Header
    const headerSummary = translate(NAMESPACE, PartyConfigKeys.HEADER.SUMMARY)
    const headerPartyConfig = translate(NAMESPACE, PartyConfigKeys.HEADER.PARTY_CONFIG)

    // Tabs
    const savedAddressTab = translate(NAMESPACE, PartyConfigKeys.TABS.SAVED_ADDRESS)
    const webhookTab = translate(NAMESPACE, PartyConfigKeys.TABS.WEBHOOK)

    // Fields
    const partyName = translate(NAMESPACE, PartyConfigKeys.FIELDS.PARTY_NAME)

    // Saved Address fields
    const code = translate(NAMESPACE, PartyConfigKeys.FIELDS.CODE)
    const firstName = translate(NAMESPACE, PartyConfigKeys.FIELDS.FIRST_NAME)
    const lastName = translate(NAMESPACE, PartyConfigKeys.FIELDS.LAST_NAME)
    const phone = translate(NAMESPACE, PartyConfigKeys.FIELDS.PHONE)
    const fax = translate(NAMESPACE, PartyConfigKeys.FIELDS.FAX)
    const email = translate(NAMESPACE, PartyConfigKeys.FIELDS.EMAIL)
    const companyName = translate(NAMESPACE, PartyConfigKeys.FIELDS.COMPANY_NAME)
    const taxNo = translate(NAMESPACE, PartyConfigKeys.FIELDS.TAX_NO)
    const address = translate(NAMESPACE, PartyConfigKeys.FIELDS.ADDRESS)
    const city = translate(NAMESPACE, PartyConfigKeys.FIELDS.CITY)
    const state = translate(NAMESPACE, PartyConfigKeys.FIELDS.STATE)
    const country = translate(NAMESPACE, PartyConfigKeys.FIELDS.COUNTRY)
    const postalCode = translate(NAMESPACE, PartyConfigKeys.FIELDS.POSTAL_CODE)
    const addressType = translate(NAMESPACE, PartyConfigKeys.FIELDS.ADDRESS_TYPE)
    const stateOther = translate(NAMESPACE, PartyConfigKeys.FIELDS.STATE_OTHER)
    const partyType = translate(NAMESPACE, PartyConfigKeys.FIELDS.PARTY_TYPE)

    // Webhook fields
    const webhookType = translate(NAMESPACE, PartyConfigKeys.FIELDS.WEBHOOK_TYPE)
    const webhookURL = translate(NAMESPACE, PartyConfigKeys.FIELDS.WEBHOOK_URL)
    const createdAt = translate(NAMESPACE, PartyConfigKeys.FIELDS.CREATED_AT)
    const createdBy = translate(NAMESPACE, PartyConfigKeys.FIELDS.CREATED_BY)
    const updatedAt = translate(NAMESPACE, PartyConfigKeys.FIELDS.UPDATED_AT)
    const updatedBy = translate(NAMESPACE, PartyConfigKeys.FIELDS.UPDATED_BY)


    return {
        moduleTitle,
        titleShipmentProviderList,
        headerSummary,
        headerPartyConfig,
        savedAddressTab,
        webhookTab,
        partyName,
        code,
        firstName,
        lastName,
        phone,
        fax,
        email,
        companyName,
        taxNo,
        address,
        city,
        state,
        country,
        postalCode,
        addressType,
        stateOther,
        partyType,
        webhookType,
        webhookURL,
        createdAt,
        createdBy,
        updatedAt,
        updatedBy
    }
}

export default TranslationText


