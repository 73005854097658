import React from 'react'
import { Card, CardContent, Divider, Grid } from '@material-ui/core'
import { components, constants, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import CalistaAdvisoryCountryApiUrls from 'src/apiUrls/cia-admin/CalistaAdvisoryCountryApiUrls'
import CalistaAdvisoryKey from 'src/constants/locale/key/CalistaAdvisory'

const {
    card: {CngSimpleCardHeader},
    table: { CngCrudTable, useDefaultNotification}
} = components
const { locale: {key: {UiComponentKeys} } } = constants

function TablePage(props){

    const { location: { pathname }, showNotification } = props
    const notification = useDefaultNotification(showNotification)
    const { translate } = useTranslation([
        Namespace.UI_COMPONENT, Namespace.CALISTA_ADVISORY
    ])
    const translatedTextsObject = makeTranslatedTextsObject()

    function makeTranslatedTextsObject(){
        let title = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.SUB_MODULE_TITLE.CALISTA_ADVISORY_COUNTRY)

        let tableTitle = translate(
            Namespace.UI_COMPONENT,
            UiComponentKeys.Table.TITLE,
            { nameTitleised: title}
        )

        // columns
        let countryCode = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.COUNTRY_CODE)
        let countryName = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.COUNTRY_NAME)
		let active = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.ACTIVE)

        return {
            tableTitle,
            countryCode,
            countryName,
			active
        }
    }

    // * Table Columns
    const columns = [
        { field: 'code', title: translatedTextsObject.countryCode },
		{ field: 'name', title: translatedTextsObject.countryName },
		{ field: 'active', title: translatedTextsObject.active, type: 'boolean' }
    ];

    //* return JSX
    return (

        //* Start JSX
        <Card>
            <CngSimpleCardHeader title={translatedTextsObject.tableTitle}/>
            <Divider/>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} style={{wordBreak: "break-word"}}>
                        <CngCrudTable {...props}
                        notification = {notification}
                        
                        // table fetch and data details
                        fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                        fetch={{ url: CalistaAdvisoryCountryApiUrls.GET}}
                        idAccessor='id'
                        columns={columns}

                        // view route
                        viewRoute={`${pathname}/view`}

                        // edit route
                        editRoute={`${pathname}/edit`}
                        
                        // add route
                        addRoute={`${pathname}/add`}

                        // delete
                        del={{ url: CalistaAdvisoryCountryApiUrls.DELETE}}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        //* End JSX
    );
    //* End of function
}

export default TablePage