import React from 'react'
import { Yup } from 'cng-web-lib'

const ValidationSchema = () => {

  return Yup.object({
    isActive: Yup.boolean().required('Required/Mandatory'),
    name: Yup.string().required('Required/Mandatory').max(255, 'Name cannot be more than 255 characters.'),
    key: Yup.string().max(255, 'Key cannot be more than 255 characters.'),
    accountNumber: Yup.string().max(255, 'Account Number cannot be more than 255 characters.'),
    password: Yup.string().max(255, 'Password cannot be more than 255 characters.'),
    meterNumber: Yup.string().max(255, 'Meter Number cannot be more than 255 characters.'),
    secret: Yup.string().max(255, 'Secret cannot be more than 255 characters.')
  })
}

export default ValidationSchema

