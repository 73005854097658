import React from 'react'
import { Yup } from 'cng-web-lib'

const ValidationSchema = () => {

  const requiredErrorMessage = 'Required/Mandatory'
  return Yup.object({
    name: Yup.string().required(requiredErrorMessage).max(255, 'Name cannot be more than 255 characters.'),
    code: Yup.string().required(requiredErrorMessage).max(10, 'Code cannot be more than 10 characters.'),
    webserviceUrl: Yup.string().required('Required/Mandatory.').max(4000, 'URL cannot be more than 4000 characters.').url('Invalid URL'),
    type: Yup.string().nullable().required(requiredErrorMessage)
  })
}

export default ValidationSchema

