import React from 'react'
import { BasePage as CngBasePage } from 'cng-web-lib'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_CECADMIN'
import TranslationText from './TranslatedText'
import CeCPartyConfigTablePage from "./CeCPartyConfig_TablePage";
import CeCPartyConfigViewPage from "./CeCPartyConfig_ViewPage"
import CeCPartyConfigEditPage from "./CeCPartyConfig_EditPage"

function ModuleTitle() {
    const translatedTextsObject = TranslationText()
    return translatedTextsObject.moduleTitle
}

function WrappedTablePage(props) {
    const breadcrumbNameMap = useBreadcrumbNameMap()
    return (
        <CngBasePage
            moduleTitle={ModuleTitle()}
            renderPage={(showSnackbar) => (
                <CeCPartyConfigTablePage showNotification={showSnackbar} {...props} />
            )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
        />
    )
}

function WrappedViewPage(props) {
    const breadcrumbNameMap = useBreadcrumbNameMap()
    return (
        <CngBasePage
            moduleTitle={ModuleTitle()}
            renderPage={(showSnackbar) => (
                <CeCPartyConfigViewPage showNotification={showSnackbar} {...props} />
            )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
        />
    )
}

function WrappedEditPage(props) {
    const breadcrumbNameMap = useBreadcrumbNameMap()
    return (
        <CngBasePage
            moduleTitle={ModuleTitle()}
            renderPage={(showSnackbar) => (
                <CeCPartyConfigEditPage showNotification={showSnackbar} {...props} />
            )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
        />
    )
}

export {
    WrappedTablePage as TablePage,
    WrappedViewPage as ViewPage,
    WrappedEditPage as EditPage
}