import React from "react";
import {useHistory, useLocation} from "react-router-dom";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {Card, makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import pathMap from "../../../paths/PathMap_CalistaAdvisory";
import AdminMgmtIndexPage from "../cia-acct-management/AdminMgmtIndexPage";

const useStyles = makeStyles(theme => ({
    textBold: {
        fontWeight: 'bold'
    },
    filterItem: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: theme.spacing(2),
        backgroundColor: '#5E81F429'
    },
    filterHeader: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
        columnGap: theme.spacing(0.5)
    },
    filterTitle: {
        flexGrow: 1,
        maxWidth: '100%',
        flexBasis: 0,
        '& > svg': { margin: theme.spacing(0, 3) }
    },
    buttonTransform: {
        textTransform: 'none',
        color: '#FF808B',
        '&.MuiButton-outlinedPrimary': {
            border: '1px solid pink',
            backgroundColor: 'white'
        }
    },
}))


function AdminAcctMgmtCompanyIndexPage(props) {
    const classes = useStyles()
    const location = useLocation()
    const history = useHistory()

    const party = location.state.party
    const input = location.state.input

    const handleClickHome = () => {
        history.push(pathMap.CALISTA_ADMIN_ACCOUNT_MANAGEMENT, {input: input})
    }

    return (
        <Box>
            <Box className={classes.filterItem} component={Card} px={3} py={1.5} mr={2.5} mb={3}>
                <Box className={classes.filterHeader} >
                    <Box className={classes.filterTitle}>
                        <Typography className={classes.textBold} variant="h4">{party.name}</Typography>
                    </Box>
                    <Button className={classes.buttonTransform} startIcon={<FontAwesomeIcon icon={['fal', 'arrow-right-from-bracket']} />} variant="outlined" color="primary" onClick={handleClickHome} >Exit company view</Button>
                </Box>
            </Box>

            <AdminMgmtIndexPage partyId={party.id} />
        </Box>
    )

}

export default AdminAcctMgmtCompanyIndexPage