import { Card, CardContent, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import TranslationText from './TranslationText'
import PartyMappingTranslationText from '../party-mapping/TranslationText'
import ShipmentProviderPartyMappingCrudTable from '../party-mapping/CrudTable'
import AccordionHeaderComponent from '../../../common/ui/AccordionHeaderComponent'
import ConnectorConfigurationPanel from '../connector-mapping/ConnectorConfigurationPanel'
import ConnectorType from '../../../../constants/cec/ConnectorType'
import ShipmentProviderEditForm from './forms/EditForm'
import ShipmentProviderAddForm from './forms/AddForm'
import PartyInformationAddForm from '../default-contact-information/forms/AddForm'
import DefaultPartyType from '../../../../constants/cec/DefaultPartyType'
import PartyInformationEditForm from '../default-contact-information/forms/EditForm'
import SCOAdminApiUrls from '../../../../apiUrls/SCOAdminApiUrls'
import { useLocation } from 'react-router-dom'

const {
  CngGridItem, CngTabs
} = components

const {
  filter: {
    EQUAL
  },
  NotificationType
} = constants


function AddPage({ history, showNotification }) {

  const location = useLocation()
  const { fetchRecords } = useServices()
  const [shipmentProviderId, setShipmentProviderId] = useState(location.state && location.state.shipmentProviderId ? location.state.shipmentProviderId : '')
  const [shipperContactDetailsId, setShipperContactDetailsId] = useState(location.state && location.state.shipperContactDetailsId ? location.state.shipperContactDetailsId : undefined)
  const [consigneeContactDetailsId, setConsigneeContactDetailsId] = useState(location.state && location.state.consigneeContactDetailsId ? location.state.consigneeContactDetailsId : undefined)
  const [connectorAppTypes, setConnectorAppTypes] = useState(null)

  const {
    shipmentProviderDetails, defaultShipperDetails, defaultConsigneeDetails, connectorConfigurations, tabLockedMessage
  } = TranslationText()

  const { allowedParties } = PartyMappingTranslationText()

  const getIndexOfActiveTab = () => {
    return location.state && location.state.indexOfTheActiveTab ? location.state.indexOfTheActiveTab : 0
  }

  const onTabChange = indexOfTheActiveTab => {
    history.replace({
      pathname: location.pathname,
      state: {
        indexOfTheActiveTab: indexOfTheActiveTab,
        shipmentProviderId: shipmentProviderId,
        shipperContactDetailsId: shipperContactDetailsId,
        consigneeContactDetailsId: consigneeContactDetailsId
      }
    })
  }

  useEffect(() => {
    fetchRecords.execute(
      SCOAdminApiUrls.GET_CODE_MASTER_LIST,
      {
        filters: [
          {
            field: 'codeType',
            operator: EQUAL,
            value: 'CEC_CONNECTOR_APP_TYPE'
          }
        ]
      },
      (data) => {
        if (data.content) {
          setConnectorAppTypes(data.content.map(type => (({ code, id }) => ({ code, id }))(type)))
        }
      }, () => {
        showNotification(NotificationType.ERROR, 'Error while retrieving connector types.')
      }
    )
  }, [shipmentProviderId])

  const getConnectorTypeId = connectorType => {
    if (connectorAppTypes) {
      return connectorAppTypes.filter(type => type.code === connectorType).at(0)['id']
    }
  }

  return (

    <Grid container spacing={1}>
      <CngGridItem item xs={12}>
        <CngTabs
          activeTabIndex={getIndexOfActiveTab()} onChange={onTabChange}
          lockedOnFirstTab={shipmentProviderId === ''}
          tabLockedMessage={tabLockedMessage}
          headerColor='primary'
          tabs={[{
            tabName: shipmentProviderDetails, tabContent: (<Grid container spacing={3}>

                <Grid item xs={12}>

                  <Card>
                    <CardContent>

                      {shipmentProviderId === ''
                        ?

                        <ShipmentProviderAddForm
                          showNotification={showNotification}
                          history={history}
                          setShipmentProviderId={setShipmentProviderId}
                        />

                        :

                        <ShipmentProviderEditForm
                          shipmentProviderId={shipmentProviderId}
                          showNotification={showNotification}
                          history={history}
                        />

                      }

                    </CardContent>
                  </Card>

                </Grid>

                {shipmentProviderId !== ''
                  ?

                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Grid container>
                          <AccordionHeaderComponent
                            title={allowedParties} hideMandatory />

                        </Grid>

                        <Grid container spacing={3}>
                          <Grid item xs={12}>

                            <ShipmentProviderPartyMappingCrudTable
                              shipmentProviderId={parseInt(shipmentProviderId)}
                              isViewOnly={false}
                              showNotification={showNotification}
                            />

                          </Grid>
                        </Grid>

                      </CardContent>
                    </Card>
                  </Grid>

                  : null
                }

              </Grid>

            )
          }, {
            tabName: defaultShipperDetails, tabContent: (<Grid container spacing={3}>

              <Grid item xs={12}>

                <Card>
                  <CardContent>

                    {(shipperContactDetailsId === undefined ?

                        <PartyInformationAddForm
                          showNotification={showNotification}
                          history={history}
                          isViewOnly={false}
                          partyType={DefaultPartyType.Shipper}
                          setShipperContactDetailsId={setShipperContactDetailsId}
                          shipmentProviderId={shipmentProviderId}
                        />

                        :

                        <PartyInformationEditForm
                          key={shipperContactDetailsId}
                          showNotification={showNotification}
                          contactDetailsId={shipperContactDetailsId}
                          history={history}
                          partyType={DefaultPartyType.Shipper}
                        />

                    )}

                  </CardContent>
                </Card>

              </Grid>

            </Grid>)
          }, {
            tabName: defaultConsigneeDetails, tabContent: (<Grid container spacing={3}>

              <Grid item xs={12}>

                <Card>
                  <CardContent>

                    {(consigneeContactDetailsId === undefined ?

                        <PartyInformationAddForm
                          showNotification={showNotification}
                          history={history}
                          isViewOnly={false}
                          partyType={DefaultPartyType.Consignee}
                          setConsigneeContactDetailsId={setConsigneeContactDetailsId}
                          shipmentProviderId={shipmentProviderId}
                        />

                        :

                        <PartyInformationEditForm
                          key={consigneeContactDetailsId}
                          showNotification={showNotification}
                          contactDetailsId={consigneeContactDetailsId}
                          history={history}
                          partyType={DefaultPartyType.Consignee}
                        />

                    )}


                  </CardContent>
                </Card>

              </Grid>

            </Grid>)
          }, {
            tabName: connectorConfigurations, tabContent: (<Grid container spacing={3}>

              <Grid item xs={12}>

                <ConnectorConfigurationPanel showNotification={showNotification}
                                             shipmentProviderId={shipmentProviderId}
                                             isViewOnly={false} connectorType={ConnectorType.ShipperApplication}
                                             connectorTypeId={getConnectorTypeId(ConnectorType.ShipperApplication)} />

              </Grid>

              <Grid item xs={12}>

                <ConnectorConfigurationPanel showNotification={showNotification}
                                             shipmentProviderId={shipmentProviderId}
                                             isViewOnly={false} connectorType={ConnectorType.CustomerApplication}
                                             connectorTypeId={getConnectorTypeId(ConnectorType.CustomerApplication)} />

              </Grid>

              <Grid item xs={12}>
                <ConnectorConfigurationPanel showNotification={showNotification}
                                             shipmentProviderId={shipmentProviderId}
                                             isViewOnly={false} connectorType={ConnectorType.FulfilmentApplication}
                                             connectorTypeId={getConnectorTypeId(ConnectorType.FulfilmentApplication)} />
              </Grid>

            </Grid>)
          }]}
        />
      </CngGridItem>
    </Grid>)
}

export default AddPage