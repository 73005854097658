import React from 'react'
import { components } from 'cng-web-lib'

import ProductListEditFormProperties from './CiaPartyConfig_ProductListEditFormProperties'
import CalistaAdvisoryConfigApiUrls from 'src/apiUrls/CalistaAdvisoryPartyConfigApiUrls'

const { form: {CngEditForm}} = components

function ProductListEditForm({history, showNotification, id, disabled}){
    return(
        //* Start JSX
        <CngEditForm
			fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
			history={history}
			showNotification={showNotification}
			bodySection={
				<ProductListEditFormProperties.Fields
					showNotification={showNotification}
					id={id}
					disabled={disabled}
				/>
			}
			formikProps={ProductListEditFormProperties.formikProps}
			toClientDataFormat={ProductListEditFormProperties.toClientDataFormat}
			toServerDataFormat={ProductListEditFormProperties.toServerDataFormat}
			fetch={{ url: CalistaAdvisoryConfigApiUrls.GET }}
			update={{ url: CalistaAdvisoryConfigApiUrls.PUT }}
			id={id}
		/>
    )
    //* End of Function
}

export default ProductListEditForm