import React from 'react'
import ValidationSchema from './ValidationSchema'
import { components } from 'cng-web-lib'
import TranslationText from './TranslationText'
import { Card, CardContent, Grid } from '@material-ui/core'
import AccordionHeaderComponent from '../../../common/ui/AccordionHeaderComponent'


const {
  form: {
    field: {
      CngTextField
    }
  }, CngGridItem
} = components


function Fields({ ...props }) {

  const { disabled } = props
  const {
    variableName, variableValue, variables
  } = TranslationText()


  return (<>
      <Grid container spacing={3}>
        <CngGridItem xs={12} sm={12}>
          <Card>
            <CardContent>
              <Grid container>

                <AccordionHeaderComponent
                  title={variables} />

                <Grid container spacing={2}>
                  <CngGridItem xs={12} sm={6}>
                    <CngTextField
                      isRequired
                      name='name'
                      label={variableName}
                      disabled={disabled}
                    />

                  </CngGridItem>

                  <CngGridItem xs={12} sm={6}>
                    <CngTextField
                      isRequired
                      name='value'
                      label={variableValue}
                      disabled={disabled}
                    />
                  </CngGridItem>


                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </CngGridItem>
      </Grid>
    </>

  )
}


function toClientDataFormat(serverData) {
  return serverData
}

function toServerDataFormat(localData) {
  return localData
}

const DEFAULT_INITIAL_VALUES = {
  name: '', value: ''
}

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }, makeValidationSchema: ValidationSchema
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties