import React from 'react'
import { Grid } from '@material-ui/core'
import { components, useTranslation, Yup } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import CiaAdminMassRecommendApiUrls from 'src/apiUrls/cia-admin/CiaAdminMassRecommendApiUrls'
import CiaKeys from 'src/constants/locale/key/CalistaAdvisory'

const {
	form: { field: { CngTextField, CngDateTimeField, CngLookupAutocompleteField } }
} = components

//* Field default values -------------------------------------------------------
const DEFAULT_INITIAL_VALUES = Object.freeze({
	status: '',
	partyId: '',
	createdBy: '',
	createdDate: '',
	completedCount: '',
	totalCount: '',
	hngTrackingRef: '',
	hngSyncStatus: ''
})

//* Front end validations ------------------------------------------------------
function makeValidationSchema(translate) {
	return Yup.object({
	})
}

//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
	initialValues: { ...DEFAULT_INITIAL_VALUES },
	makeValidationSchema: makeValidationSchema
}

//* Fields function ------------------------------------------------------------
function Fields({ disabled, showNotification, shouldHideMap, id }) {

	// Translations ------------------------------------------------------------
	const ciaNamespace = Namespace.CALISTA_ADVISORY
	const { translate } = useTranslation(ciaNamespace, Namespace.UI_COMPONENT)

	let CiaFieldKeys = CiaKeys.FIELDS

	const Labels = {
		STATUS: translate(ciaNamespace, CiaFieldKeys.STATUS),
		PARTY: translate(ciaNamespace, CiaFieldKeys.PARTY),
		CREATED_BY: translate(ciaNamespace, CiaFieldKeys.CREATED_BY),
		CREATED_DATE: translate(ciaNamespace, CiaFieldKeys.CREATED_DATE),
		COMPLETED_COUNT: translate(ciaNamespace, CiaFieldKeys.COMPLETED_COUNT),
		TOTAL_COUNT: translate(ciaNamespace, CiaFieldKeys.TOTAL_COUNT),
		HNG_TRACKING_REF: translate(ciaNamespace, CiaFieldKeys.HNG_TRACKING_REF),
		HNG_SYNC_STATUS: translate(ciaNamespace, CiaFieldKeys.HNG_SYNC_STATUS)
	}

	//* return JSX -------------------------------------------------------------
	return (
		<Grid container spacing={3}>
			<Grid item xs={3}>
				<CngTextField name="status" label={Labels.STATUS} disabled={disabled} />
			</Grid>
			<Grid item xs={3}>
				<CngTextField name="partyId" label={Labels.PARTY} disabled={disabled} />
			</Grid>
			<Grid item xs={3}>
				<CngLookupAutocompleteField name="createdBy"
					label={Labels.CREATED_BY}
					disabled={disabled}
					lookupProps={{
						url: CiaAdminMassRecommendApiUrls.USER_DETAIL_GET,
						label: 'userProfile.loginId',
						value: 'id'
					}}
				/>
			</Grid>
			<Grid item xs={3}>
				<CngDateTimeField name="createdDate" label={Labels.CREATED_DATE} disabled={disabled} />
			</Grid>
			<Grid item xs={3}>
				<CngTextField name="completedCount" label={Labels.COMPLETED_COUNT} disabled={disabled} />
			</Grid>
			<Grid item xs={3}>
				<CngTextField name="totalCount" label={Labels.TOTAL_COUNT} disabled={disabled} />
			</Grid>
			<Grid item xs={3}>
				<CngTextField name="hngTrackingRef" label={Labels.HNG_TRACKING_REF} disabled={disabled} />
			</Grid>
			<Grid item xs={3}>
				<CngTextField name="hngSyncStatus" label={Labels.HNG_SYNC_STATUS} disabled={disabled} />
			</Grid>
		</Grid>
		//* End JSX ------------------------------------------------------------
	)
	//* End of function --------------------------------------------------------
}

function toClientDataFormat(serverData) {
	let localData = serverData;
	return localData;
}

function toServerDataFormat(localData) {
	return localData;
}

const FormProperties = Object.freeze({
	formikProps: FORMIK_PROPS,
	Fields: Fields,
	toClientDataFormat: toClientDataFormat,
	toServerDataFormat: toServerDataFormat
})

export default FormProperties
