import * as React from 'react';
import FormControl from "@material-ui/core/FormControl";
import {
    Card,
    IconButton,
    makeStyles,
    MenuItem,
    OutlinedInput,
    Select
} from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import pathMap from "../../../../paths/PathMap_CalistaAdvisory";
import {useHistory} from "react-router-dom";
import {useEffect} from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left"
    },
    getContentAnchorEl: null,
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const useStyles = makeStyles(theme => ({
    fullWidth: {
        width: '100%'
    },
    companyHeader: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
        fontWeight: 'bold',
        columnGap: theme.spacing(1.5)
    },
    companyTitle: {
        flexGrow: 1,
        maxWidth: '100%',
        flexBasis: 0,
        '& > svg': { margin: theme.spacing(0, 3) }
    },
    marginZero: {
        margin: 0,
        padding: 0
    }
}))


function getStyles(theme) {
    return {
        fontWeight: theme.typography.fontWeightMedium,
    };
}

export default function CompanyDropdown(props) {
    const theme = useTheme();
    const classes = useStyles()
    const history = useHistory()

    const[partyList, setPartyList] = React.useState([])
    const[selectedPartyList, setSelectedPartyList] = React.useState([])

    useEffect(() => {
        setPartyList(props.partyList)
        setSelectedPartyList(props.filterInput.selectedPartyList)
    }, [props])

    const handleChange = (event) => {
        const { target: { value: targetList } } = event;
        setSelectedPartyList(targetList);
        props.setFilterInput({...props.filterInput, selectedPartyList: targetList })
    }

    const handleRemoveParty = (party) => {
        const targetList = selectedPartyList.filter(p => party.id !== p.id )
        setSelectedPartyList(targetList)
        props.setFilterInput({...props.filterInput, selectedPartyList: targetList })
    }


    const companyViewOnclick = (party) => {
        const state = { input: props.filterInput, party: party }; // Your state object

        history.push(pathMap.CALISTA_ADMIN_ACCOUNT_MANAGEMENT_COMPANY_VIEW, state);
    }

    return (
        <div>
            <FormControl className={classes.fullWidth}>
                <Select
                    displayEmpty
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={selectedPartyList}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" />}
                    renderValue={(selectedPartyList) => {
                        if (selectedPartyList.length === 0)
                            return <em>Company*</em>;
                        else return (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', columnGap: theme.spacing(1.5), rowGap: theme.spacing(1.5) }} >
                                {selectedPartyList.map((value) => (
                                    <Chip key={value.id} label={value.name} />
                                ))}
                            </Box>
                        )
                    }}
                    MenuProps={MenuProps}
                >
                    {partyList.map((item) => (
                        <MenuItem
                            key={item.id}
                            value={item}
                            style={getStyles(theme)}
                        >
                            {item.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {selectedPartyList.map((party) => (
                <Box key={party.id} className={classes.companyHeader} component={Card} mt={1.5} px={3} py={1.5} >
                    <Typography className={classes.companyTitle} variant="h5">{party.name}</Typography>
                    <IconButton className={classes.marginZero} onClick={() => companyViewOnclick(party)} >
                        <FontAwesomeIcon icon={['fal', 'eye']} />
                    </IconButton>
                    <IconButton className={classes.marginZero} onClick={() => handleRemoveParty(party)} >
                        <FontAwesomeIcon icon={['fal', 'xmark']} />
                    </IconButton>
                </Box>
            ))}
        </div>
    );
}
