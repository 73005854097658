import { components } from 'cng-web-lib';
import React from 'react';
import { useParams } from 'react-router-dom'
import { Grid } from '@material-ui/core'

import CommonCityMappingApiUrls from 'src/apiUrls/cec-admin/CommonCityMappingApiUrls'
import pathMap from 'src/paths/PathMap_CeCAdmin'
import FormProperties from './CommonCityMapping_FormProperties';
import CommonCityTranslationText from './CommonCityTranslationText'

const {
    form: {
        CngViewForm,
    },
    button: { CngSecondaryButton }
} = components



function ViewPage({ history, showNotification }) {
    const { id } = useParams()
    const translatedTextObject = CommonCityTranslationText()

    return (
        <>
            <CngViewForm
                fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                showNotification={showNotification}
                history={history}
                bodySection={
                    <>
                        <FormProperties.Fields
                            disabled={true}
                            showNotification={showNotification}
                            id={id}
                        />

                        <Grid container spacing={3}>
                            <Grid item>
                                <CngSecondaryButton
                                    onClick={() => { history.push(pathMap.COMMON_CITY_MAPPING) }}>
                                    {translatedTextObject.back}
                                </CngSecondaryButton>
                            </Grid>
                        </Grid>
                    </>
                }
                formikProps={FormProperties.formikProps}
                toClientDataFormat={FormProperties.toClientDataFormat}
                toServerDataFormat={FormProperties.toServerDataFormat}
                fetch={{
                    id: id,
                    url: `${CommonCityMappingApiUrls.GET}`
                }}
            />
        </>
    )

}

export default ViewPage