import React, { useState, useEffect}  from 'react'
import Namespace from 'src/constants/locale/Namespace'
import CecAccountKeys from 'src/constants/locale/key/CecAccount'
import { Grid, Card, CardContent } from '@material-ui/core'
import {
  components,
  DataFlattener,
  useTranslation,
  constants,
  useServices
} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import PersonFormProperties from './PersonFormProperties'
import SCOAdminApiUrls from 'src/apiUrls/SCOAdminApiUrls'

const {
  card: {
    CngSimpleCardHeader
  },
  form: {
    field: {
      CngTextField,
      CngSelectField,
      CngLookupAutocompleteField
    },
    adapter: {
      useFormAdapter: { useFormikContext }
  }
  },
  table: {
    CngLocalModeDialogFormTable
  },
  CngGridItem,
} = components
const {
  filter: {EQUAL},
  NotificationType
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: "",
  accountNumber: "",
  displayName:"",
  secretKey:"",
  street:"",
  city:"",
  stateId:"",
  stateOther:"",
  postcode:"",
  country:"",
  addressTypeId:"",
  status:"",
  person:[]
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, fetchCountryCode, showNotification, shouldHideMap, mode}) {
  const { translate } = useTranslation(Namespace.CEC_ACCOUNT)
  const translatedTextsObject = makeTranslatedTextsObject();
  const { setFieldValue } = useFormikContext()

  const [stateRequired, setStateRequired] = useState(false)
  const [countryCode, setCountryCode] = useState()
  const [statusOption, setStatusOption] = useState([])
  const { fetchRecords } = useServices()

  const editAddressFields = (val) => {
    if (!val) {
        setFieldValue('stateOther', '');
        setFieldValue('stateId', '');
        setFieldValue('street', '');
        setFieldValue('city', '');
        setFieldValue('postCode', '');
    } else {
        setFieldValue('stateId', '');
    }
  }

  useEffect(() => {
    getStatusOptions();
  }, [])

  useEffect(() => {
    getStateOptions();
  }, [countryCode])

  const getStatusOptions = () => {
    const onSuccess = (response) => {
        const options = response.content.map((status) => {
          if(mode === 'add'){
            if(status.name === 'Draft'){
              setFieldValue('status', status.id);
            }
          }
          return { text: status.name, value: status.id }
        })
        setStatusOption(options.filter((item) => item.text !== 'Deleted'));
    }

    fetchRecords.execute(
        SCOAdminApiUrls.GET_CODE_MASTER_LIST,
          {
            filters: [
              {
                field: 'codeType',
                operator: EQUAL,
                value: 'CEC_TRNX_STATUS'
              }
            ]
          },
        onSuccess,
        () => {
            showNotification(NotificationType.ERROR, "Failed to fetch Status options")
        }
    )
  }

  const getStateOptions = () => {
    const onSuccess = (response) => {
      if(response.content.length == 0){
        setStateRequired(false)
      }else if(!response.content.length == 0 && response.content[0].countryCode !== countryCode){
        setStateRequired(false)
      }else{
        setStateRequired(true)
      }
      
      if(mode === "edit"){
        if(!countryCode && !response.content.length == 0 && response.content[0].countryCode !== fetchCountryCode){
          setStateRequired(false)
        }
      }
    }

    fetchRecords.execute(
      SCOAdminApiUrls.GET_COUNTRY_STATE_LIST,
          {
            filters: [
              {
                field: 'countryCode',
                operator: EQUAL,
                value: countryCode
              }
            ]
          },
        onSuccess
    )
  }

  function makeTranslatedTextsObject() {

    let title = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.CEC_ACCOUNT_TITLE
    ) 
    let accountNumber = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.ACCOUNT_NUMBER
    ) 
    let displayName = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.DISPLAY_NAME
    )
    
    let status = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.STATUS
    ) 
    
    let secretKey = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.SECRET_KEY
    )
    let address = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.ADDRESS
    )
    let street = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.STREET
    )
    let city = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.CITY
    )
    let state = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.STATE
    )
    let stateOther = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.STATE_OTHER
    )
    let postCode = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.POSTCODE
    )
    let country = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.COUNTRY
    )
    let type = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.TYPE
    )
    let person = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.PERSON
    )
    let salutation = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.SALUTATION
    )
    let firstName = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.FIRST_NAME
    )
    let lastName = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.LAST_NAME
    )
    let email = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.EMAIL
    )
    let phone = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.PHONE
    )
    let designation = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.DESIGNATION
    )
    let isPrimary = translate(
      Namespace.CEC_ACCOUNT,
      CecAccountKeys.IS_PRIMARY
    )
    return {
      title,
      accountNumber,
      displayName,
      status,
      secretKey,
      address,
      street,
      city,
      state,
      stateOther,
      postCode,
      country,
      type,
      person,
      salutation,
      firstName,
      lastName,
      email,
      phone,
      designation,
      isPrimary
    }
  }

	return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CngSimpleCardHeader title="Account" />
          <CardContent>
            <Grid container spacing={3}>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.accountNumber}>
             <CngTextField
                  name="accountNumber"
                  label={translatedTextsObject.accountNumber +" *"} 
                  inputProps={
                    {maxLength: 255, minLength:10}
                  }
                  disabled={mode === 'add' ? false: true}
                  />
             </CngGridItem> 

             <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.displayName}>
                <CngTextField
                  name="displayName"
                  label={translatedTextsObject.displayName +" *" }
                  inputProps={
                    {maxLength: 255, minLength:3}
                  }
                  disabled={disabled}
                />
              </CngGridItem>  
             
             <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.status}>
              <CngSelectField
                  name="status"
                  label={translatedTextsObject.status  +" *"}
                  disabled={mode === 'edit' ? false: true}
                  items={statusOption}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="secretKey"
                  label={translatedTextsObject.secretKey }
                  disabled={true}    
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.country}>
                <CngLookupAutocompleteField
                  name="country"
                  label={translatedTextsObject.country+" *" }
                  disabled={disabled}
                  lookupProps={{
                    url: SCOAdminApiUrls.GET_COUNTRY_LIST,
                    label: 'name',
                    value: 'code'
                  }}
                  onChange={(val) => {
                    setCountryCode(val);
                    editAddressFields(val);
                  }}
                  >
                </CngLookupAutocompleteField>
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.stateId}>
                <CngLookupAutocompleteField
                  name="stateId"
                  key={countryCode}
                  label={translatedTextsObject.state}
                  disabled={disabled}
                  lookupProps={{
                    url: SCOAdminApiUrls.GET_COUNTRY_STATE_LIST,
                    label: 'stateName',
                    value: 'id',
                    filters: [
                        {
                            field: 'countryCode',
                            operator: EQUAL,
                            value: countryCode ? countryCode:fetchCountryCode
                        }
                    ]
                  }}
                  required={stateRequired}
                >
                </CngLookupAutocompleteField>
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.street}>
                <CngTextField
                  name="street"
                  label={translatedTextsObject.street+" *" }
                  disabled={disabled}    
                  inputProps={
                    {maxLength: 1000, minLength:10}
                  }
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.city}>
                <CngTextField
                  name="city"
                  label={translatedTextsObject.city }
                  inputProps={
                    {maxLength: 255}
                  }
                  disabled={disabled}    
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.stateOther}>
                <CngTextField
                  name="stateOther"
                  label={translatedTextsObject.stateOther }
                  disabled={disabled}    
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.postCode}>
                <CngTextField
                  name="postcode"
                  label={translatedTextsObject.postCode+" *" }
                  inputProps={
                    {maxLength: 20, minLength:3}
                  }
                  disabled={disabled}    
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.type}>
                <CngLookupAutocompleteField
                  name="addressTypeId"
                  label={translatedTextsObject.type  +" *"}
                  disabled={disabled}    
                  lookupProps={{
                    url: SCOAdminApiUrls.GET_CODE_MASTER_LIST,
                    label: 'name',
                    value: 'id',
                    filters: [
                        {
                            field: 'codeType',
                            operator: EQUAL,
                            value: 'CEC_ADDRESS_TYPE'
                        }
                    ]
                  }}
                >
                </CngLookupAutocompleteField>
              </CngGridItem>
              </Grid>
              </CardContent>
              </Card>
              </Grid>
              <Grid item xs={12}>
              <Card>
          
          <CngGridItem xs={12} sm={12} shouldHide={mode === 'add' ? true: false}>
          <CngSimpleCardHeader title={translatedTextsObject.person} />
            <CardContent>
              <CngLocalModeDialogFormTable
                fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
                disabled={disabled}
                showNotification={showNotification}
                propertyName="person"
                tableColumns={[
                  {
                    title: translatedTextsObject.firstName,
                    field: "firstName",
                  },
                  {
                    title: translatedTextsObject.lastName,
                    field: "lastName"
                  },
                  {
                    title: translatedTextsObject.email,
                    field: "email"
                  },
                  {
                    title: translatedTextsObject.isPrimary,
                    field: "isPrimary",
                    type: "boolean"
                  }
                ]}
                formProperties={PersonFormProperties}
                initialData={DEFAULT_INITIAL_VALUES.person}
                //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
              />
            </CardContent>
          </CngGridItem>
        </Card>
        </Grid>
              
    </Grid>
    
	)
}

function toServerDataFormat(localData) {
  const reqData = { "account" : [{"accountNumber" : localData.accountNumber ,
  "displayName" : localData.displayName,
  "status" : localData.status,
  "address": {"city":localData.city,
  "street":localData.street,"state":localData.stateId,"stateOther":localData.stateOther,
  "country":localData.country,
  "postcode":localData.postcode,
  "type":localData.addressTypeId},
  "person": localData.person }]
    } 
return DataFlattener.unflatten(reqData);
  }

function toClientDataFormat(serverData) { 
  const reqData = { "accountNumber" : serverData.accountNumber ,
  "displayName" : serverData.displayName,
  "status" : serverData.status,
  "secretKey" : serverData.secretKey,
  "city":serverData.city,
  "street":serverData.street,
  "stateId":serverData.stateId,
  "stateOther":serverData.stateOther,
  "country":serverData.country,
  "postcode":serverData.postcode,
  "addressTypeId":serverData.addressTypeId,
  "person": serverData.person
    } 
   
  return reqData;
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})



export default FormProperties
