import React from 'react'
import { Grid, Card, CardContent } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import CalistaAdvisoryKey from 'src/constants/locale/key/CalistaAdvisory'
import makeValidationSchema from './CiaCountry_MakeValidationSchema'

const {
	form: { field: { CngSwitchField, CngTextField } },
	CngGridItem,
} = components

//* Field default values -------------------------------------------------------
const DEFAULT_INITIAL_VALUES = Object.freeze({
	code: "",
	name: "",
	active: false
})

//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
	initialValues: { ...DEFAULT_INITIAL_VALUES },
	makeValidationSchema: makeValidationSchema
}

//* Fields function ------------------------------------------------------------
function Fields({ disabled, isEditable, showNotification, shouldHideMap, id }) {

	const { translate } = useTranslation(Namespace.CALISTA_ADVISORY)
	const translatedTextsObject = makeTranslatedTextsObject()

	//* Translation function ---------------------------------------------------
	function makeTranslatedTextsObject() {

		// fields
        let code = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.COUNTRY_CODE)
        let name = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.COUNTRY_NAME)
		let active = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.ACTIVE)

		return {
			code,
			name,
			active
		}
	}

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Card>
					<CardContent>
						<Grid container spacing={3}>
							<CngGridItem xs={12} shouldHide={shouldHideMap.code}>
								<CngTextField name="code"
									label={translatedTextsObject.code}
									disabled={disabled}
								/>
							</CngGridItem>

							<CngGridItem xs={12} shouldHide={shouldHideMap.name}>
								<CngTextField name="name"
									label={translatedTextsObject.name}
									disabled={disabled}
								/>
							</CngGridItem>

							<CngGridItem xs={12} shouldHide={shouldHideMap.active}>
								<CngSwitchField name="active"
									label={translatedTextsObject.active}
									disabled={!isEditable}
								/>
							</CngGridItem>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
		//* End JSX ------------------------------------------------------------

	)
	//* End of function --------------------------------------------------------
}

function toClientDataFormat(serverData) {
	let localData = serverData;
	return localData;
}

function toServerDataFormat(localData) {
	return localData;
}

const FormProperties = Object.freeze({
	formikProps: FORMIK_PROPS,
	Fields: Fields,
	toClientDataFormat: toClientDataFormat,
	toServerDataFormat: toServerDataFormat
})

export default FormProperties
