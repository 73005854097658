import {CIAApiUrls} from "../../../apiUrls";

export const COLOR = {
  origin: '#F4BE5E',
  destination: '#7CE7AC'
}

export const exportReport = (data, fileType, moduleType, securedSendRequest) => {

  // set loading on
  let urlLink = "";
  let fileExtension = fileType;
  let fileDomainName = "";
  let hscode = ""
  if(data.hscode === undefined)
    hscode = data.importHsCode != null ? data.importHsCode : data.exportHsCode
  else
    hscode = data.hscode

  if (moduleType === "eAdvisory") {
    fileDomainName = "eAdvisory_Result_[" + hscode + "]_[";
    if (fileType === "pdf" && data.hscode === undefined) {
      urlLink = CIAApiUrls.EADVISORY_EXPORT_PDF_V2;
    } else if (fileType === "pdf") {
      urlLink = CIAApiUrls.EADVISORY_EXPORT_PDF;
    } else if (fileType === "xls" && data.hscode === undefined) {
      urlLink = CIAApiUrls.EADVISORY_EXPORT_EXCEL_V2;
    } else if (fileType === "xls") {
      urlLink = CIAApiUrls.EADVISORY_EXPORT_EXCEL;
    }
  } else if (moduleType === "landedcost") {
    fileDomainName = "Landed_Cost_Result_[" + data.request.importHSCode + "]_[";
    if (fileType === "pdf") {
      urlLink = CIAApiUrls.LCC_EXPORT_PDF;
    } else if (fileType === "xls") {
      urlLink = CIAApiUrls.LCC_EXPORT_EXCEL;
    }
  }

  console.log(urlLink)
  console.log(data)
  securedSendRequest.execute(
      "POST",
      urlLink,
      data,
      (reportResponse) => {
        console.log("success")
        console.log(reportResponse);
        let blob = reportResponse.data
        let timeStamp = new Date().toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
        let fileName = fileDomainName + timeStamp + "]." + fileExtension;

        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);

        //console.log(link)
        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      },
      (error) => {
        console.log(error)
      },
      onComplete,
      { responseType: "blob" }

  )
  // set loading off
}

function onComplete() {
    console.log("complete")
}