import React from 'react'
import { useServices } from 'cng-web-lib'
import { Grid, Avatar, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

// FontAwesome imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fal as FontAwesomeRegular } from '@fortawesome/pro-light-svg-icons'
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core'
import {exportReport} from "../helper";

FontAwesomeLibrary.add(FontAwesomeRegular)


const useStyles = makeStyles(theme => ({
    text: {
        fontSize: '20px',
        fontWeight: '700'
    },
    module_title: {
        color: '#8996AF',
        fontSize: '14px'
    },
    transaction_date: {
        color: '#8996AF',
        fontSize: '14px',
        textAlign: 'right'
    },
    search_term: {
        color: '#5E81F4',
        fontSize: '16px',
        textTransform: 'uppercase'
    },
    description: {
        color: '#1C1D21',
        textAlign: 'left',
        font: 'normal normal normal 16px/23px Lato',
        letterSpacing: '0.16px',
        opacity: '1',
        paddingTop: '20px'
    },
    country_style: {
        font: 'normal normal bold 16px/19px Lato',
        color: '#1C1D21',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '150px',
        display: 'inline-block',
        verticalAlign: 'bottom'
    },
    from_to_style: {
        font: 'normal normal 16px/19px Lato',
        color: '#1C1D21',
        display: 'inline-block',
        verticalAlign: 'bottom'
    },
    flags: {
        height: '1.2em', width: '1.2em',
        display: 'inline-block',
        verticalAlign: 'bottom'
    },
    date_cell: {
        textAlign: 'right'
    },
    export_btn: {
        background: '#FFFFFF',
        marginRight: '5px',
        display: 'inline-block'
    },
    box_grid: {
        border: '1px solid #F5F5FA',
        boxShadow: '0px 2px 10px #00000005',
        borderRadius: "12px",
        padding: theme.spacing(1),
        margin: theme.spacing(0.5, 0.5),
        background: '#FFFFFF 0% 0% no-repeat padding-box'
    }
}))

function TransactionRow({ transaction }) {

    const classes = useStyles()

    const { securedSendRequest } = useServices()

    //* return JSX -------------------------------------------------------------
    return (
        //* Start JSX ----------------------------------------------------------

        <Grid container className={classes.box_grid}>
            <Grid style={{ 'width': '20%' }}>
                <Grid className={classes.module_title}>{transaction.module_name}</Grid>
                <Grid className={classes.search_term}>{transaction.search_term}</Grid>
            </Grid>

            <Grid style={{ 'width': '40%' }}>
                <Grid className={classes.description}>{transaction.description}</Grid>
            </Grid>

            <Grid className={classes.date_cell} style={transaction.isAllowExport ? { 'width': '30%' } : { 'width': '37%' }}>
                <Grid className={classes.module_title}>{transaction.transaction_date}</Grid>

                <Grid style={transaction.isBothCountry ? {} : { display: 'none' }}>
                    <Grid className={classes.from_to_style}>&nbsp; From  &nbsp; </Grid>
                    <Grid style={{ 'display': 'inline-block', 'vertical-align': 'bottom' }}>
                        <Avatar className={classes.flags}
                            src={`${process.env.PUBLIC_URL}/static/images/countries/${transaction.from_country_code}.svg`}
                            alt={`${transaction.from_country_name} flag`}>
                            {transaction.from_country_code}
                        </Avatar>
                    </Grid>
                    <Grid item xs={5} className={classes.country_style}> &nbsp; {transaction.from_country_name}</ Grid>
                    <Grid className={classes.from_to_style}>  &nbsp; To &nbsp;  </Grid>
                    <Grid style={{ 'display': 'inline-block', 'vertical-align': 'bottom' }}>
                        <Avatar className={classes.flags}
                            src={`${process.env.PUBLIC_URL}/static/images/countries/${transaction.to_country_code}.svg`}
                            alt={`${transaction.to_country_name} flag`}>
                            {transaction.to_country_code}
                        </Avatar>
                    </Grid>
                    <Grid item xs={5} className={classes.country_style}> &nbsp; {transaction.to_country_name} </ Grid>
                </Grid>

                <Grid style={transaction.isBothCountry ? { display: 'none' } : {}}>
                    <Grid style={{ 'display': 'inline-block', 'vertical-align': 'bottom' }}>
                        <Avatar className={classes.flags}
                            src={`${process.env.PUBLIC_URL}/static/images/countries/${transaction.to_country_code}.svg`}
                            alt={`${transaction.to_country_name} flag`}>
                            {transaction.to_country_code}
                        </Avatar>
                    </Grid>
                    <Grid item xs={5} className={classes.country_style}>&nbsp; {transaction.to_country_name}</ Grid>
                </Grid>
            </Grid>

            <Grid style={transaction.isAllowExport ? { 'width': '10%' } : { display: 'none' }}>
                {/* <Grid style={}> */}
                <IconButton className={classes.export_btn} onClick={() => exportReport(transaction.response_content, 'xls', transaction.module_code, securedSendRequest)} >
                    <FontAwesomeIcon style={{ 'fontSize': '18px' }} icon={['fal', 'file-excel']} />
                </IconButton>
                <IconButton className={classes.export_btn} onClick={() => exportReport(transaction.response_content, 'pdf', transaction.module_code, securedSendRequest)} >
                    <FontAwesomeIcon style={{ 'fontSize': '18px' }} icon={['fal', 'file-pdf']} />
                </IconButton>
                {/* </Grid> */}
            </Grid>
        </Grid>

        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

TransactionRow.propTypes = {
    title: PropTypes.string,
}

export default TransactionRow