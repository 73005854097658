import { Card, CardContent, Grid } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import { useLocation, useParams } from 'react-router-dom'
import ConnectorTranslationText from '../ConnectorTranslationText'
import FormProperties from './FormProperties'
import ConnectorAppsApiUrls from '../../../../apiUrls/cec-admin/connector-config/ConnectorAppsApiUrls'
import AccordionHeaderComponent from '../../../common/ui/AccordionHeaderComponent'
import ConnectorEndpointsCrudTable from '../endpoints/ConnectorEndpointsCrudTable'
import ConnectorCredentialsCrudTable from '../credentials/ConnectorCredentialsCrudTable'
import ConnectorMappingsCrudTable from '../mappings/ConnectorMappingsCrudTable'

const {
  button: {
    CngSecondaryButton
  }, form: {
    CngEditForm, CngEditFormButtonSection
  }, CngGridItem, CngTabs
} = components


function EditPage({ history, showNotification }) {


  const { connectorAppId } = useParams()
  const location = useLocation()

  const {
    connectorCredentials,
    connectorDetails,
    connectorEndpoints,
    connectorMappings
  } = ConnectorTranslationText()

  const onTabChange = indexOfTheActiveTab => {
    history.replace({
      pathname: location.pathname,
      state: { indexOfTheActiveTab: indexOfTheActiveTab }
    })
  }

  const getIndexOfActiveTab = () => {
    return location.state && location.state.indexOfTheActiveTab ? location.state.indexOfTheActiveTab : 0
  }


  return (

    <Grid container spacing={1}>
      <CngGridItem item xs={12}>
        <CngTabs activeTabIndex={getIndexOfActiveTab()} onChange={onTabChange}
                 headerColor='primary'
                 tabs={[{
                   tabName: connectorDetails, tabContent: (<Grid container spacing={3}>

                       <Grid item xs={12}>

                         <Card>
                           <CardContent>

                             <CngEditForm
                               fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                               showNotification={showNotification}
                               bodySection={<FormProperties.Fields
                                 disabled={false}
                                 showNotification={showNotification}
                                 id={connectorAppId}
                               />}
                               renderButtonSection={() => (<CngEditFormButtonSection>
                                   <CngGridItem>
                                     <CngSecondaryButton
                                       onClick={() => {
                                         history.goBack()
                                       }}>
                                       Back
                                     </CngSecondaryButton>
                                   </CngGridItem>
                                 </CngEditFormButtonSection>

                               )}
                               formikProps={{
                                 ...FormProperties.formikProps
                               }}
                               toClientDataFormat={FormProperties.toClientDataFormat}
                               toServerDataFormat={FormProperties.toServerDataFormat}
                               fetch={{
                                 url: `${ConnectorAppsApiUrls.GET}`
                               }}
                               update={{
                                 url: ConnectorAppsApiUrls.UPDATE
                               }}
                               id={connectorAppId}
                             />

                           </CardContent>
                         </Card>

                       </Grid>

                       <Grid item xs={12}>
                         <Card>
                           <CardContent>
                             <Grid container>
                               <AccordionHeaderComponent
                                 title={connectorEndpoints} hideMandatory />

                             </Grid>

                             <Grid container spacing={3}>
                               <Grid item xs={12}>

                                 <ConnectorEndpointsCrudTable
                                   connectorAppId={parseInt(connectorAppId)}
                                   isViewOnly={false}
                                   showNotification={showNotification}
                                 />

                               </Grid>
                             </Grid>

                           </CardContent>
                         </Card>
                       </Grid>

                       <Grid item xs={12}>

                         <Card>
                           <CardContent>
                             <Grid container>
                               <AccordionHeaderComponent
                                 title={connectorCredentials} hideMandatory />

                             </Grid>

                             <Grid container spacing={3}>
                               <Grid item xs={12}>
                                 <ConnectorCredentialsCrudTable
                                   connectorAppId={parseInt(connectorAppId)}
                                   isViewOnly={false}
                                   showNotification={showNotification}
                                 />
                               </Grid>
                             </Grid>

                           </CardContent>
                         </Card>
                       </Grid>

                     </Grid>

                   )
                 }, {
                   tabName: connectorMappings, tabContent: (<Grid container spacing={3}>

                     <Grid item xs={12}>

                       <Card>
                         <CardContent>
                           <Grid container>
                             <AccordionHeaderComponent
                               title={connectorMappings} hideMandatory />

                           </Grid>

                           <Grid container spacing={3}>
                             <Grid item xs={12}>
                               <ConnectorMappingsCrudTable
                                 connectorAppId={parseInt(connectorAppId)}
                                 isViewOnly={false}
                                 showNotification={showNotification}
                               />
                             </Grid>
                           </Grid>

                         </CardContent>
                       </Card>
                     </Grid>

                   </Grid>)
                 }]}
        />
      </CngGridItem>
    </Grid>)
}

export default EditPage