import React from 'react'
import PropTypes from 'prop-types'
import { components } from 'cng-web-lib'
import ConnectorTranslationText from '../ConnectorTranslationText'
import ConnectorMappingApiUrls from '../../../../apiUrls/cec-admin/connector-config/ConnectorMappingApiUrls'
import ConnectorMappingsFormProperties from './ConnectorMappingsFormProperties'
import { useHistory } from 'react-router-dom'
import CecAdminPathMap from '../../../../paths/PathMap_CeCAdmin'

const {
  table: {
    CngServerModeDialogFormTable
  }
} = components


const ConnectorMappingsCrudTable = ({ connectorAppId, isViewOnly, showNotification }) => {

  const history = useHistory()
  const { GET, CREATE, DELETE } = ConnectorMappingApiUrls


  const onEditButtonClick = (event, record) => {
    history.push({
      pathname: CecAdminPathMap.CONNECTOR_MAPPING + '/edit/' + record['id'],
      state: { connectorAppId: connectorAppId, mappingId: record['id'] }
    })

  }

  const onAddButtonClick = () => {
    history.push({
      pathname: CecAdminPathMap.CONNECTOR_MAPPING + '/add',
      state: { connectorAppId: connectorAppId }
    })
  }

  const onViewButtonClick = (event, record) => {
    history.push({
      pathname: CecAdminPathMap.CONNECTOR_MAPPING + '/view/' + record['id'],
      state: { connectorAppId: connectorAppId, mappingId: record['id'] }
    })
  }

  const {
    connectorMappingName,
    connectorMappingInputSource,
    connectorMappingOutputSource
  } = ConnectorTranslationText()


  const columnsOfConnectorEndpoints = [{
    field: 'name', title: connectorMappingName
  }, {
    field: 'inputSource', title: connectorMappingInputSource
  }, {
    field: 'outputSource', title: connectorMappingOutputSource
  }]


  return (

    <CngServerModeDialogFormTable
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      disabled={isViewOnly}
      showNotification={showNotification}
      tableColumns={columnsOfConnectorEndpoints}
      pageSize={5}
      formProperties={{
        ...ConnectorMappingsFormProperties, formikProps: {
          ...ConnectorMappingsFormProperties.formikProps, initialValues: {
            ...ConnectorMappingsFormProperties.formikProps.initialValues, appId: connectorAppId
          }
        }
      }}
      toClientDataFormat={(serverData) => {
        return serverData
      }}
      toServerDataFormat={(localDatum) => {
        return localDatum
      }}
      fetch={{
        url: GET, body: {
          appId: connectorAppId
        }
      }}
      create={{
        url: CREATE, body: {
          appId: connectorAppId
        }
      }}
      del={{ url: DELETE }}
      onEditButtonClick={onEditButtonClick}
      onAddButtonClick={onAddButtonClick}
      onViewButtonClick={onViewButtonClick}
      idAccessor='mappingId'
    />


  )
}

ConnectorMappingsCrudTable.propTypes = {
  showNotification: PropTypes.func, connectorAppId: PropTypes.number, isViewOnly: PropTypes.bool
}

export default ConnectorMappingsCrudTable


