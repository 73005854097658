import React from 'react'
import { BasePage as CngBasePage } from 'cng-web-lib'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_CECADMIN'
import TranslationText from './TranslationText'
import QuotationTablePage from "./Quotation_TablePage"
import QuotationViewPage from "./Quotation_ViewPage"
import QuotationEditPage from "./Quotation_EditPage"

function ModuleTitle() {
    const translatedTextsObject = TranslationText()
    return translatedTextsObject.moduleTitle
}

function WrappedTablePage(props) {
    const breadcrumbNameMap = useBreadcrumbNameMap()
    return (
        <CngBasePage
            moduleTitle={ModuleTitle()}
            renderPage={(showSnackbar) => (
                <QuotationTablePage showNotification={showSnackbar} {...props} />
            )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
        />
    )
}

function WrappedViewPage(props) {
    const breadcrumbNameMap = useBreadcrumbNameMap()
    return (
        <CngBasePage
            moduleTitle={ModuleTitle()}
            renderPage={(showSnackbar) => (
                <QuotationViewPage showNotification={showSnackbar} {...props} />
            )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
        />
    )
}

function WrappedEditPage(props) {
    const breadcrumbNameMap = useBreadcrumbNameMap()
    return (
        <CngBasePage
            moduleTitle={ModuleTitle()}
            renderPage={(showSnackbar) => (
                <QuotationEditPage showNotification={showSnackbar} {...props} />
            )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
        />
    )
}

export {
    WrappedTablePage as TablePage,
    WrappedViewPage as ViewPage,
    WrappedEditPage as EditPage
}

