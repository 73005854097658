import { BasePage as CngBasePage } from 'cng-web-lib'
import React from 'react'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_CECADMIN'
import CommonCityTranslationText from './CommonCityTranslationText'

import TablePage from './TablePage'
import CreatePage from './CreatePage'
import EditPage from './EditPage'
import ViewPage from './ViewPage'



//* Module Title ---------------------------------------------------------------
function ModuleTitle() {
      const translatedTextsObject = CommonCityTranslationText();
      const moduleTitle = translatedTextsObject.title;
      return moduleTitle;
}

function WrappedTablePage(props) {
      const breadcrumbNameMap = useBreadcrumbNameMap()
      return (
            <CngBasePage
                  moduleTitle={ModuleTitle()}
                  renderPage={(showSnackbar) => (
                        <TablePage showNotification={showSnackbar} {...props} />
                  )}
                  extraCngBreadcrumbNameMap={breadcrumbNameMap}
            />
      )
}



//* Wrapped Add Page ----------------------------------------------------------
function WrappedAddPage(props) {
      const breadcrumbNameMap = useBreadcrumbNameMap()
      return (
            <CngBasePage
                  moduleTitle={ModuleTitle()}
                  renderPage={(showSnackbar) => (
                        <CreatePage showNotification={showSnackbar} {...props} />
                  )}
                  extraCngBreadcrumbNameMap={breadcrumbNameMap}
            />
      )
}



//* Wrapped Edit Page ----------------------------------------------------------
function WrappedEditPage(props) {
      const breadcrumbNameMap = useBreadcrumbNameMap()
      return (
            <CngBasePage
                  moduleTitle={ModuleTitle()}
                  renderPage={(showSnackbar) => (
                        <EditPage showNotification={showSnackbar} {...props} />
                  )}
                  extraCngBreadcrumbNameMap={breadcrumbNameMap}
            />
      )
}


//* Wrapped View Page ----------------------------------------------------------
function WrappedViewPage(props) {
      const breadcrumbNameMap = useBreadcrumbNameMap()
      return (
            <CngBasePage
                  moduleTitle={ModuleTitle()}
                  renderPage={(showSnackbar) => (
                        <ViewPage showNotification={showSnackbar} {...props} />
                  )}
                  extraCngBreadcrumbNameMap={breadcrumbNameMap}
            />
      )
}




export {
      WrappedTablePage as TablePage,
      WrappedAddPage as AddPage,
      WrappedEditPage as EditPage,
      WrappedViewPage as ViewPage
}