import React from 'react'
import { Yup } from 'cng-web-lib'

const ValidationSchema = () => {

  return Yup.object({
    name: Yup.string().required('Required/Mandatory').max(4000, 'Variable name cannot be more than 4000 characters.'),
    value: Yup.string().required('Required/Mandatory').max(4000, 'Variable name cannot be more than 4000 characters.')

  })
}

export default ValidationSchema

