import React from 'react'
import { Yup } from 'cng-web-lib'

const ValidationSchema = () => {

  return Yup.object({
    contactName: Yup.string().max(255, 'Name cannot be more than 255 characters.'),
    phoneNo: Yup.string().max(255, 'Phone cannot be more than 255 characters.'),
    faxNo: Yup.string().max(255, 'Fax cannot be more than 255 characters.'),
    email: Yup.string().email('Invalid email address.').max(255, 'Email cannot be more than 255 characters.'),
    companyName: Yup.string().max(255, 'Company Name cannot be more than 255 characters.'),
    taxNo: Yup.string().max(255, 'Tax number cannot be more than 255 characters.'),
    address: Yup.string().max(1000, 'Address cannot be more than 1000 characters.'),
    city: Yup.string().max(225, 'City cannot be more than 255 characters.'),
    postcode: Yup.string().max(20, 'Post code cannot be more than 20 characters.')
  })
}

export default ValidationSchema

