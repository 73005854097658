import React from 'react'
import PropTypes from 'prop-types'
import { components } from 'cng-web-lib'
import TranslationText from './TranslationText'
import ShipmentProviderPartyMappingUrls
  from '../../../../apiUrls/cec-admin/shipment-provider/ShipmentProviderPartyMappingUrls'
import FormProperties from './FormProperties'

const {
  table: {
    CngServerModeDialogFormTable, useFetchCustomLookup, useDefaultNotification
  }
} = components

const CrudTable = ({ shipmentProviderId, isViewOnly, showNotification }) => {

  const { partyName } = TranslationText()
  const { GET, CREATE, DELETE } = ShipmentProviderPartyMappingUrls
  const fetchCustomLookup = useFetchCustomLookup()
  const notification = useDefaultNotification(showNotification)

  const columnsOfShipmentProviderMappings = [{
    field: 'partyId',
    title: partyName,
    filtering: false,
    customLookup: () => {
      return fetchCustomLookup(
        `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
        {},
        'content',
        'name',
        'id',
        (error) => notification.error(error.message)
      )
    }
  }
  ]


  return (

    <CngServerModeDialogFormTable
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      disabled={isViewOnly}
      authoriseActions={{
        execute: (actions) => {
          return actions.filter(action => action.name === 'add' || action.name === 'delete')
        }
      }}
      showNotification={showNotification}
      tableColumns={columnsOfShipmentProviderMappings}
      pageSize={5}
      formProperties={{
        ...FormProperties, formikProps: {
          ...FormProperties.formikProps, initialValues: {
            ...FormProperties.formikProps.initialValues, shipmentProviderId: shipmentProviderId
          }
        }
      }}
      toClientDataFormat={(serverData) => {
        return serverData
      }}
      toServerDataFormat={(localDatum) => {
        return localDatum
      }}
      fetch={{
        url: GET, body: {
          shipmentProviderId: shipmentProviderId
        }
      }}
      create={{
        url: CREATE, body: {
          shipmentProviderId: shipmentProviderId
        }
      }}
      del={{ url: DELETE }}
      idAccessor='id'
    />


  )
}

CrudTable.propTypes = {
  showNotification: PropTypes.func, shipmentProviderId: PropTypes.number, isViewOnly: PropTypes.bool
}

export default CrudTable


