import { components } from 'cng-web-lib';
import React from 'react';
import CommonCityMappingApiUrls from 'src/apiUrls/cec-admin/CommonCityMappingApiUrls'
import pathMap from 'src/paths/PathMap_CeCAdmin'
import FormProperties from './CommonCityMapping_FormProperties';

const {
    form: {
        CngAddForm
    }
} = components

function CreatePage({ history, showNotification }) {
    return (

        <CngAddForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            shouldDisableEnterToSubmit
            showNotification={showNotification}
            history={history}
            bodySection={
                <FormProperties.Fields
                    disabled={false}
                    showNotification={showNotification}
                />
            }
            formikProps={FormProperties.formikProps}
            create={{
                url: CommonCityMappingApiUrls.CREATE,
                successRedirect: pathMap.COMMON_CITY_MAPPING
            }}
        />
    )

}

export default CreatePage