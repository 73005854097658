import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent, Grid } from '@material-ui/core'
import AccordionHeaderComponent from '../../../common/ui/AccordionHeaderComponent'
import ConnectorMappingCrudTable from './CrudTable'
import TranslationText from './TranslationText'
import ConnectorType from '../../../../constants/cec/ConnectorType'

const ConnectorConfigurationPanel = ({
                                       shipmentProviderId,
                                       showNotification,
                                       isViewOnly,
                                       connectorType,
                                       connectorTypeId
                                     }) => {

  const getTitleText = () => {
    let title
    switch (connectorType) {
      case ConnectorType.ShipperApplication:
        title = TranslationText().courierConnectorConfigurations
        break
      case ConnectorType.CustomerApplication:
        title = TranslationText().customerConnectorConfigurations
        break
      case ConnectorType.FulfilmentApplication:
        title = TranslationText().fulfilmentConnectorConfigurations
        break
      default:
        title = ''
    }
    return title
  }

  return (
    <Card>
      <CardContent>
        <Grid container>
          <AccordionHeaderComponent
            title={getTitleText()} hideMandatory />

        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}>

            <ConnectorMappingCrudTable
              shipmentProviderId={parseInt(shipmentProviderId)}
              isViewOnly={isViewOnly}
              showNotification={showNotification}
              connectorType={connectorType}
              connectorTypeId={connectorTypeId}
            />

          </Grid>
        </Grid>

      </CardContent>
    </Card>
  )
}

ConnectorConfigurationPanel.propTypes = {
  showNotification: PropTypes.func,
  shipmentProviderId: PropTypes.string,
  isViewOnly: PropTypes.bool,
  connectorType: PropTypes.string
}

export default ConnectorConfigurationPanel