import React, {useState} from 'react'
import { components, constants, Yup, useServices } from 'cng-web-lib'
import TranslationText from './TranslationText'
import {Card, CardContent, Divider, Grid, Box} from '@material-ui/core'
import {DropzoneAreaBase} from "material-ui-dropzone";
import SCOAdminApiUrls from "../../../apiUrls/SCOAdminApiUrls";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DocumentInfoDecoratorComponent from "../../common/ui/DocumentInfoDecoratorComponent";
import Paper from "@material-ui/core/Paper";
import {useFormContext} from "react-hook-form";
import QuotationUrls from "../../../apiUrls/cec-admin/quotation/QuotationUrls"


const {
    form: {
        field: {
            CngTextField, CngDateField, CngSelectField, CngLookupAutocompleteField
        },
    }, button: { CngPrimaryButton },
    card: { CngSimpleCardHeader },
    CngGridItem
} = components

function Fields({ ...props }) {

    const { securedSendRequest } = useServices()
    const { getValues } = useFormContext()
    const { disabled, showNotification, edit } = props
    const { NotificationType, filter: { EQUAL } } = constants


    const {
        party,
        uuid,
        quotationRefNo,
        status,
        requestInfo,
        createdAt,
        updatedAt,
        quotationSummary,
        quotationInfo,
    } = TranslationText()

    const [pendingDocs, setPendingDocs] = useState(null)
    const [btnDisabled, setBtnDisabled] = useState(false)

    const onAddFile = (fileObjs) => {
        setPendingDocs(fileObjs)
    }

    const handleUpdate = () => {
        console.log("update quotation")
        setBtnDisabled(true)

        let formData = new FormData();

        formData.append('uuid', getValues('requestUuid'))
        formData.append('status', getValues('status'))
        formData.append('quotationRefNo', getValues('quotationRefNo'))
        if(pendingDocs != null)
            pendingDocs.forEach(doc => formData.append('files', doc.file))
        else
            formData.append('files', null)

        console.log(formData)

        securedSendRequest.execute("POST", QuotationUrls.QUOTATION_UPDATE, formData,
            (data) => {
                console.log(data)
                showNotification(NotificationType.SUCCESS, "Update Quotation Success'")
            },
            (error) => {
                showNotification(NotificationType.ERROR, "Error updating quotation")
            },
            () => {
                setBtnDisabled(false)
            }
        )
    }

    const handleDownload = () => {

        const element = document.createElement('a');
        const file = new Blob([getValues('requestInfo')], { type: 'application/json' });
        element.href = URL.createObjectURL(file);
        element.download = "RequestQuotation("+getValues('requestUuid')+").json";
        document.body.appendChild(element); // Required for Firefox
        element.click();
        document.body.removeChild(element); // Cleanup

        showNotification(NotificationType.SUCCESS, "Download Success'")
    }

    const handleSubmit = () => {
        console.log("Submit send quotation")
        setBtnDisabled(true)

        let formData = new FormData();

        formData.append('uuid', getValues('requestUuid'))
        formData.append('status', getValues('status'))
        formData.append('quotationRefNo', getValues('quotationRefNo'))
        if(pendingDocs != null)
            pendingDocs.forEach(doc => formData.append('files', doc.file))
        else
            formData.append('files', null)

        console.log(formData)

        securedSendRequest.execute("POST", QuotationUrls.QUOTATION_SEND, formData,
            (data) => {
                console.log(data)
                showNotification(NotificationType.SUCCESS, "Send Quotation Success'")
            },
            (error) => {
                showNotification(NotificationType.ERROR, "Error sending quotation")
            },
            () => {
                setBtnDisabled(false)
            }
        )
    }

    return (<>
            <Grid container spacing={3}>
                <CngGridItem xs={12} sm={12}>
                    <Card>
                        <CngSimpleCardHeader title={quotationSummary} />
                        <Divider />
                        <CardContent>
                            <Grid container>
                                <Grid container spacing={2}>
                                    <CngGridItem xs={12} sm={6}>
                                        <CngLookupAutocompleteField
                                            name='partyId'
                                            label={party}
                                            disabled={disabled}
                                            required
                                            lookupProps={{
                                                url: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
                                                label: 'name',
                                                value: 'id'
                                            }}
                                        />
                                    </CngGridItem>

                                    <CngGridItem xs={12} sm={6}>
                                        <CngTextField
                                            name='requestUuid'
                                            label={uuid}
                                            disabled={disabled}
                                            required
                                        />
                                    </CngGridItem>

                                    <CngGridItem xs={12} sm={6}>
                                        <CngDateField
                                            name="createdDate"
                                            label={createdAt}
                                            format='YYYY-MM-DDTHH:mm:ss'
                                            disabled={disabled}
                                        />
                                    </CngGridItem>

                                    <CngGridItem xs={12} sm={6}>
                                        <CngDateField
                                            name="updatedDate"
                                            label={updatedAt}
                                            format='YYYY-MM-DDTHH:mm:ss'
                                            disabled={disabled}
                                        />
                                    </CngGridItem>

                                    <CngTextField
                                        name='requestInfo'
                                        label={requestInfo}
                                        disabled={disabled}
                                        hidden={true}
                                    />

                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </CngGridItem>

                { edit &&
                <CngGridItem xs={12} sm={12}>
                    <Card>
                        <CngSimpleCardHeader title={quotationInfo} />
                        <Divider />
                        <CardContent>
                            <Grid container>
                                <Grid container spacing={2}>
                                    <CngGridItem xs={12} sm={6}>
                                        <CngLookupAutocompleteField
                                            name='status'
                                            label={status}
                                            disabled={false}
                                            required
                                            lookupProps={{
                                                url: SCOAdminApiUrls.GET_CODE_MASTER_LIST,
                                                label: 'name',
                                                value: 'id',
                                                filters: [
                                                    {
                                                        field: 'codeType',
                                                        operator: EQUAL,
                                                        value: 'RATE_QUOTATION_STATUS'
                                                    }
                                                ]
                                            }}
                                        >
                                        </CngLookupAutocompleteField>
                                    </CngGridItem>

                                    <CngGridItem xs={12} sm={6}>
                                        <CngTextField
                                            name='quotationRefNo'
                                            label={quotationRefNo}
                                            disabled={false}
                                        />
                                    </CngGridItem>

                                    <CngGridItem xs={12} sm={12}>
                                        <DropzoneAreaBase
                                            acceptedFiles={[
                                                'application/pdf',
                                                'application/json']}
                                            maxFileSize={10485760}
                                            onAdd={(fileObjs) => onAddFile(fileObjs)}
                                        />
                                    </CngGridItem>

                                    { pendingDocs != null &&
                                    pendingDocs.map((obj) => (
                                        <CngGridItem key={obj.file.name} xs={12} sm={6}>
                                            <Box component={Paper}>
                                                <DocumentInfoDecoratorComponent name={obj != null ? obj.file.name : '-'} size={obj != null ? obj.file.size : 0} />
                                            </Box>
                                        </CngGridItem>
                                    ))}

                                    <CngGridItem xs={12} sm={7}>
                                        <CngPrimaryButton
                                            size='large'
                                            children='Update'
                                            disabled={btnDisabled}
                                            endIcon={btnDisabled && <FontAwesomeIcon icon={['fal', 'spinner-third']} fixedWidth spin />}
                                            onClick={()=>handleUpdate()}
                                        />
                                        <CngPrimaryButton
                                            size='large'
                                            children='Download Request Info'
                                            style={{marginLeft: '8px'}}
                                            disabled={btnDisabled}
                                            endIcon={btnDisabled && <FontAwesomeIcon icon={['fal', 'spinner-third']} fixedWidth spin />}
                                            onClick={()=>handleDownload()}
                                        />
                                    </CngGridItem>

                                    <CngGridItem xs={12} sm={5} style={{ textAlign: 'end' }}>
                                        <CngPrimaryButton
                                            size='large'
                                            children='Send'
                                            disabled={btnDisabled}
                                            endIcon={btnDisabled && <FontAwesomeIcon icon={['fal', 'spinner-third']} fixedWidth spin />}
                                            onClick={()=>handleSubmit()}
                                        />
                                    </CngGridItem>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </CngGridItem> }
            </Grid>
        </>

    )
}


function toClientDataFormat(serverData) {
    return serverData
}

function toServerDataFormat(localData) {
    return localData
}

const DEFAULT_INITIAL_VALUES = {
    partyId:"",
    requestUuid:"",
    quotationRefNo:"",
    status:"",
    requestInfo:"",
    createdDate:"",
    updatedDate:""
}

const ValidationSchema = () => {
    return Yup.object({

    })
}

const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES }, makeValidationSchema: ValidationSchema
}

const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties