import CodeMaintainanceApiUrls from 'src/apiUrls/CodeMaintainanceApiUrls'
import React from 'react'

function fetchCodeMaintainance(fetchRecords, codeMType, filters, dropdown = false, onSuccess) {

    console.log('fetchCodeMaintainance...')
    let returnData = []

    fetchRecords.execute(
        CodeMaintainanceApiUrls.MAINTAINANCE_CODE,
        { 
          customData: { codeMType: codeMType },
          filters: filters
        },
        (data) => {
          if(dropdown){
            let dropdownItems = data.content.map((datum) => ({
                text:datum['descriptionEn'],
                value: datum["code"]
            }));

            returnData = dropdownItems
          }else{
            returnData = data.content
          }

          onSuccess(returnData)
        },
        (error) => {
            console.log('fetchCodeMaintainance : ' + error)
            // alert('fetchCodeMaintainance : ' + error)
            // onSuccess(returnData)
        }
    )
}

const CodeMaintainanceApi = Object.freeze({
    fetchCodeMaintainance
})

export default CodeMaintainanceApi;