import FormProperties from './FormProperties'
import ShipmentProviderUrls from '../../../../../apiUrls/cec-admin/shipment-provider/ShipmentProviderUrls'
import React from 'react'
import { components } from 'cng-web-lib'

const {
  form: {
    CngViewForm
  }
} = components

function ViewForm({ showNotification, shipmentProviderId }) {

  return (

    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      showNotification={showNotification}
      renderBodySection={() => <FormProperties.Fields
        disabled={true}
        showNotification={showNotification}
      />}
      formikProps={{
        ...FormProperties.formikProps
      }}
      toClientDataFormat={FormProperties.toClientDataFormat}
      toServerDataFormat={FormProperties.toServerDataFormat}
      fetch={{
        id: parseInt(shipmentProviderId),
        url: `${ShipmentProviderUrls.GET}`
      }}
    />

  )
}

export default ViewForm