import { Yup } from 'cng-web-lib'

function makeValidationSchema(translate) {

	return Yup.object({
		tasIsTasParty: Yup.boolean(),
		tasIsSearchRestricted: Yup.boolean(),
		tasSubscriptionTypeCode: Yup.string().nullable(),
		tasRestrictedCountryCodes: Yup.string(),
		tasOutofRestrictedCountrySearchAllowed: Yup.boolean(),
		tasTransactionLimitType: Yup.string().nullable(),
		tasTransactionLimitValue: Yup.number().integer().min(1).max(99999),
		tasHsClassificationLimitValue: Yup.number().integer().min(-1).max(99999),
		tasComplianceDetailLimitValue: Yup.number().integer().min(-1).max(99999),
		tasLandedCostLimitValue: Yup.number().integer().min(-1).max(99999),
		tasTransLimitFromDate: Yup.date().nullable(),
		tasTransLimitToDate: Yup.date().nullable(),
		tasComplianceRestricted: Yup.boolean(),
		tasCiaSubscriptionTypeCode: Yup.string().nullable(),
		tasCiaSubscriptionStatusCode: Yup.string().nullable(),
		tasTransLimitUsage: Yup.number().integer().min(0).max(2147483647)
	})
}

export default makeValidationSchema
