import { Card, CardContent, Grid } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import { useLocation, useParams } from 'react-router-dom'
import ConnectorTranslationText from '../ConnectorTranslationText'
import FormProperties from './FormProperties'
import ConnectorAppsApiUrls from '../../../../apiUrls/cec-admin/connector-config/ConnectorAppsApiUrls'
import AccordionHeaderComponent from '../../../common/ui/AccordionHeaderComponent'
import ConnectorEndpointsCrudTable from '../endpoints/ConnectorEndpointsCrudTable'
import ConnectorCredentialsCrudTable from '../credentials/ConnectorCredentialsCrudTable'
import ConnectorMappingsCrudTable from '../mappings/ConnectorMappingsCrudTable'

const {
  button: {
    CngSecondaryButton
  }, form: {
    CngViewForm
  }, CngGridItem, CngTabs
} = components


function ViewPage({ history, showNotification }) {

  const { connectorAppId } = useParams()
  const location = useLocation()

  const {
    connectorCredentials,
    connectorDetails,
    connectorEndpoints,
    connectorMappings
  } = ConnectorTranslationText()

  const onTabChange = indexOfTheActiveTab => {
    history.replace({
      pathname: location.pathname,
      state: { indexOfTheActiveTab: indexOfTheActiveTab }
    })
  }

  const getIndexOfActiveTab = () => {
    return location.state && location.state.indexOfTheActiveTab ? location.state.indexOfTheActiveTab : 0
  }


  return (

    <Grid container spacing={1}>
      <CngGridItem item xs={12}>
        <CngTabs
          activeTabIndex={getIndexOfActiveTab()} onChange={onTabChange}
          headerColor='primary'
          tabs={[{
            tabName: connectorDetails, tabContent: (<Grid container spacing={3}>

                <Grid item xs={12}>

                  <Card>
                    <CardContent>

                      <CngViewForm
                        fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                        showNotification={showNotification}
                        bodySection={<FormProperties.Fields
                          disabled={true}
                          showNotification={showNotification}
                        />}
                        formikProps={{
                          ...FormProperties.formikProps
                        }}
                        toClientDataFormat={FormProperties.toClientDataFormat}
                        toServerDataFormat={FormProperties.toServerDataFormat}
                        fetch={{
                          id: parseInt(connectorAppId),
                          url: `${ConnectorAppsApiUrls.GET}`
                        }}
                      />

                      <Grid container spacing={3}>

                        <CngGridItem xs={12} sm={12}>
                          <CngSecondaryButton
                            onClick={() => {
                              history.goBack()
                            }}>
                            Back
                          </CngSecondaryButton>
                        </CngGridItem>
                      </Grid>

                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Grid container>
                        <AccordionHeaderComponent
                          title={connectorEndpoints} hideMandatory />

                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item xs={12}>

                          <ConnectorEndpointsCrudTable
                            connectorAppId={parseInt(connectorAppId)}
                            isViewOnly={true}
                            showNotification={showNotification}
                          />

                        </Grid>
                      </Grid>

                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12}>

                  <Card>
                    <CardContent>
                      <Grid container>
                        <AccordionHeaderComponent
                          title={connectorCredentials} hideMandatory />

                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <ConnectorCredentialsCrudTable
                            connectorAppId={parseInt(connectorAppId)}
                            isViewOnly={true}
                            showNotification={showNotification}
                          />
                        </Grid>
                      </Grid>

                    </CardContent>
                  </Card>
                </Grid>

              </Grid>

            )
          }, {
            tabName: connectorMappings, tabContent: (<Grid container spacing={3}>

              <Grid item xs={12}>

                <Card>
                  <CardContent>
                    <Grid container>
                      <AccordionHeaderComponent
                        title={connectorMappings} hideMandatory />

                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <ConnectorMappingsCrudTable
                          connectorAppId={parseInt(connectorAppId)}
                          isViewOnly={true}
                          showNotification={showNotification}
                        />
                      </Grid>
                    </Grid>

                  </CardContent>
                </Card>
              </Grid>

            </Grid>)
          }]}
        />
      </CngGridItem>
    </Grid>)
}

export default ViewPage