import React from 'react'
import { Card, CardContent, Divider, Grid } from '@material-ui/core'
import { components, constants, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import CalistaAdvisoryFtaMappingApiUrls from 'src/apiUrls/cia-admin/CalistaAdvisoryFtaMappingApiUrls'
import CalistaAdvisoryKey from 'src/constants/locale/key/CalistaAdvisory'

const {
    card: { CngSimpleCardHeader },
    table: { CngCrudTable, useDefaultNotification }
} = components
const { locale: { key: { UiComponentKeys } } } = constants

function TablePage(props) {

    const { location: { pathname }, showNotification } = props
    const notification = useDefaultNotification(showNotification)
    const { translate } = useTranslation([
        Namespace.UI_COMPONENT, Namespace.CALISTA_ADVISORY
    ])
    const translatedTextsObject = makeTranslatedTextsObject()

    function makeTranslatedTextsObject() {
        let title = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.SUB_MODULE_TITLE.CALISTA_ADVISORY_FTA_MAPPING)

        let tableTitle = translate(
            Namespace.UI_COMPONENT,
            UiComponentKeys.Table.TITLE,
            { nameTitleised: title }
        )

        // columns
        let importCountry = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.IMPORT_COUNTRY)
        let exportCountry = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.EXPORT_COUNTRY)
		let ftaNameTmp = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.FTA_TMP)
		let ftaNameAmberroad = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.FTA_AMBERROAD)

		return {
            tableTitle,
			importCountry,
			exportCountry,
			ftaNameTmp,
			ftaNameAmberroad
		}
    }

    // * Table Columns
    const columns = [
        { field: 'importCountry', title: translatedTextsObject.importCountry },
        { field: 'exportCountry', title: translatedTextsObject.exportCountry },
        { field: 'ftaNameTmp', title: translatedTextsObject.ftaNameTmp },
        { field: 'ftaNameAmberroad', title: translatedTextsObject.ftaNameAmberroad }
    ];

    //* return JSX
    return (

        //* Start JSX
        <Card>
            <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
            <Divider />
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} style={{ wordBreak: "break-word" }}>
                        <CngCrudTable {...props}
                            notification={notification}

                            // table fetch and data details
                            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                            fetch={{ url: CalistaAdvisoryFtaMappingApiUrls.GET }}
                            idAccessor='id'
                            columns={columns}

                            // view route
                            viewRoute={`${pathname}/view`}

                            // edit route
                            editRoute={`${pathname}/edit`}

                            // add route
                            addRoute={`${pathname}/add`}

                            // delete
                            del={{ url: CalistaAdvisoryFtaMappingApiUrls.DELETE }}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        //* End JSX
    );
    //* End of function
}

export default TablePage