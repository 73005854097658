import React, { useEffect, useState } from 'react'

import Card from '@material-ui/core/Card'
import LoadingScreen from 'src/views/common/ui/LoadingScreen'
import ReactIframeResizer from 'react-iframe-resizer-super'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import {  securedSendRequest, SendRequest } from 'cng-web-lib'

const useCardStyles = makeStyles({
  root: {
    display: 'flex',
    height: '100%'
  }
})

const useCardMediaStyles = makeStyles({
  media: {
    border: 'none'
  }
})

function CngCustomPage({ fetchPageRequest, iframeResizerOptions, ...props  }) {
  const cardClasses = useCardStyles()
  // const { securedSendRequest, sendRequest } = useServices()
  const send = new SendRequest()

  const [loading, setLoading] = useState(true)
  const [srcDoc, setSrcDoc] = useState()
  const [errorMessage, setErrorMessage] = useState()

  const defaultResizerOptions = {
    // log: true,
    // autoResize: true,
    checkOrigin: false,
    // resizeFrom: 'parent',
    heightCalculationMethod: 'lowestElement'
    // initCallback: () => {
    //   console.log('ready!')
    // },
    // resizedCallback: () => {
    //   console.log('resized!')
    // }
  }

  const resizerOptions = {
    ...defaultResizerOptions,
    ...iframeResizerOptions
  }

  const {
    defaultProps: { fetchPageRequest: defaultFetchPageRequest }
  } = CngCustomPage

  useEffect(onMount, [])

  function onMount() {
    function onSuccessWrapper(response) {
      setSrcDoc(response.data)
      setLoading(false)

      const { onSuccess } = fetchPageRequest
      if (onSuccess) {
        onSuccess(response)
      }
    }

    function onErrorWrapper(error) {
      setErrorMessage(error.message)
      setLoading(false)

      const { onError } = fetchPageRequest
      if (onError) {
        onError(error)
      }
    }

    send.execute(
      fetchPageRequest.method || defaultFetchPageRequest.method,
      fetchPageRequest.url,
      fetchPageRequest.data || defaultFetchPageRequest.data,
      onSuccessWrapper,
      onErrorWrapper,
      fetchPageRequest.onComplete,
      //fetchPageRequest.config || defaultFetchPageRequest.config
      {
        headers: {Authorization: "Bearer "+ getToken()}
      }
    )
  }

  function getToken(){
    const key = `oidc.user:${process.env.REACT_APP_OIDC_CLIENT_AUTHORITY}:CNG-FRAMEWORK`
    const nonSsoKey='userToken'
    if(localStorage.getItem(key)){
                                                   
      let token = JSON.parse(localStorage.getItem(key))
      console.log('token', token.access_token) 
      return token.access_token

    }else if(localStorage.getItem(nonSsoKey)){
      let token = JSON.parse(localStorage.getItem(nonSsoKey))
        console.log('token', token) 
        return token
    }
  }

  if (loading) {
    return <LoadingScreen />
  }

  if (errorMessage) {
    return <Typography variant='h2' color='error' />
  }

  return (
    <Card classes={cardClasses} style={props.cardStyle}>
      <ReactIframeResizer
        iframeResizerOptions={resizerOptions}
        content={srcDoc}
        {...props}
      />
    </Card>
  )
}

CngCustomPage.defaultProps = {
  fetchPageRequest: {
    method: 'get',
    data: {},
    config: {}
  }
}

export default CngCustomPage
