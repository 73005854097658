import React from 'react'
import { useParams } from 'react-router-dom'
import { Card, CardContent, Divider, Grid } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import CiaKeys from 'src/constants/locale/key/CalistaAdvisory'
import FormProperties from './CiaAdminMassRecommend_FormProperties'
import CiaAdminMassRecommendApiUrls from 'src/apiUrls/cia-admin/CiaAdminMassRecommendApiUrls'

import RequestRowTable from './CiaAdminMassRecommend_RequestRowTable'

const {
	card: { CngSimpleCardHeader },
	form: { CngViewForm }
} = components

function ViewPage({ history, showNotification }) {

	const { id } = useParams()

	// Translations ------------------------------------------------------------
	const ciaNamespace = Namespace.CALISTA_ADVISORY
	const { translate } = useTranslation(ciaNamespace, Namespace.UI_COMPONENT)

	let CiaFieldKeys = CiaKeys.FIELDS

	const Labels = {
		REQUEST_INFO: translate(ciaNamespace, CiaFieldKeys.REQUEST_INFO),
		REQUEST_ROW_DETAILS: translate(ciaNamespace, CiaFieldKeys.REQUEST_ROW_DETAILS)
	}

	//* return JSX -------------------------------------------------------------
	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Card>
					<CngSimpleCardHeader title={Labels.REQUEST_INFO} />
					<Divider />

					<CardContent>
						<CngViewForm
							fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
							history={history}
							showNotification={showNotification}
							bodySection={
								<FormProperties.Fields
									disabled={true}
									showNotification={showNotification}
									id={id}
								/>
							}
							formikProps={FormProperties.formikProps}
							toClientDataFormat={FormProperties.toClientDataFormat}
							toServerDataFormat={FormProperties.toServerDataFormat}
							fetch={{
								url: CiaAdminMassRecommendApiUrls.GET_REQUEST,
								id: id
							}}
						/>
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12}>
				<Card>
					<CngSimpleCardHeader title={Labels.REQUEST_ROW_DETAILS} />
					<Divider />
					<CardContent>
						<RequestRowTable />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	)

	//* End of function --------------------------------------------------------
}

export default ViewPage