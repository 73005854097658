import React, { useState, useRef } from 'react'
import { Grid, makeStyles, Card, CardContent } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import CalistaAdvisoryKeys from 'src/constants/locale/key/CalistaAdvisory'

import FilterPanel from './components/filter-panel/FilterPanel'
import DateFilter from './components/filter-panel/DateFilter'
import TransactionTypeFilter from './components/filter-panel/TransactionTypeFilter'
import moment from 'moment'
import AdminMgmt_LeftPanel from './components/AdminMgmt_LeftPanel'

const useStyles = makeStyles(theme => ({
    text: {
        fontSize: '20px',
        fontWeight: '700'
    }
}))

function AdminMgmtIndexPage({ ...props }) {
    const { enqueueSnackbar } = useSnackbar()

    // Translations ------------------------------------------------------------
    const { translate } = useTranslation(Namespace.CALISTA_ADVISORY)

    const CiaAdminKeys = CalistaAdvisoryKeys.CIA_ADMIN_MANAGEMENT

    const Labels = {
        DATE: translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKeys.FIELDS.DATE),
        PLEASE_SELECT_TRANSACTION_TYPE: translate(Namespace.CALISTA_ADVISORY, CiaAdminKeys.FILTERS.PLEASE_SELECT_TRANSACTION_TYPE)
    }

    //* ------------------------------------------------------------------------
    const [clearAllFilterClickedTime, setClearAllFilterClickedTime] = useState(new Date())
    const [dateFilter, setDateFilter] = useState('WEEK')
    const [transactionTypeFilter, setTransactionTypeFilter] = useState(['ALL'])

    const [selectedStartingDate, setStartingDateChange] = useState(new Date())
    const [selectedEndingDate, setEndingDateChange] = useState(new Date())

    const [filterData, setFilterData] = useState({
        dateFilter: 'WEEK',
        transactionTypeFilter: ['ALL']
    })

    const fromDate = useRef()
    const toDate = useRef()
    const isForInfiniteList = useRef();


    const applyFilterClickHandler = (event) => {

        // Validations ---------------------------------------------------------
        let hasError = false

        // dateFilter is Radio button. Will always have value. No need to validate.

        // Transaction Type
        if (Boolean(TransactionTypeFilter.length) == false) {
            enqueueSnackbar(Labels.PLEASE_SELECT_TRANSACTION_TYPE, { variant: 'error' })
            hasError = true
        }

        if (dateFilter === 'CUSTOM_RANGE') {
            if (selectedEndingDate < selectedStartingDate) {
                console.log('endDate earliear than start date')
                enqueueSnackbar('endDate earliear than start date', { variant: 'error' })
                hasError = true
            }
        }


        // Apply
        if (hasError == false) {
            getDateValue()
            isForInfiniteList.current = false

            let newFilterData = {
                dateFilter: dateFilter,
                transactionTypeFilter: transactionTypeFilter,
                from_date: fromDate.current,
                to_date: toDate.current,
                forInfiniteUse: isForInfiniteList.current

            }

            setFilterData(newFilterData)

        }

        return (false)
    }

    const getDateValue = () => {
        var today = moment(new Date());
        toDate.current = today.format("YYYY-MM-DD")

        switch (dateFilter) {
            case 'ALL':
                fromDate.current = moment(new Date('2018-01-01')).format("YYYY-MM-DD")
                break
            case 'WEEK':
                fromDate.current = today.add(-7, 'day').format("YYYY-MM-DD")
                break;
            case 'DAYS_30':
                fromDate.current = today.add(-30, 'day').format("YYYY-MM-DD")
                break;
            case 'DAYS_60':
                fromDate.current = today.add(-60, 'day').format("YYYY-MM-DD")
                break;
            case 'CUSTOM_RANGE':
                toDate.current = moment(selectedEndingDate).format("YYYY-MM-DD")
                fromDate.current = moment(selectedStartingDate).format("YYYY-MM-DD")
                break;
            default:
            //do nothing

        }

    }



    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container spacing={3}>
            <Grid item xs={9}>
                <Card>
                    <CardContent>
                        <AdminMgmt_LeftPanel
                            filterData={filterData}
                            selectedStartingDate={selectedStartingDate}
                            selectedEndingDate={selectedEndingDate}
                            partyId={props.partyId}
                        />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={3} container spacing={3} alignContent='flex-start'>
                <Grid item xs={12}>
                    <FilterPanel
                        onClearAllClicked={(event) => {
                            setClearAllFilterClickedTime(new Date())
                            return (false)
                        }}
                        onApplyClicked={applyFilterClickHandler}
                    >
                        <DateFilter
                            title={Labels.DATE}
                            clearAllFilterClickedTime={clearAllFilterClickedTime}
                            onChange={(value) => {
                                setDateFilter(value)
                            }}
                            setStartingDateChange={setStartingDateChange}
                            setEndingDateChange={setEndingDateChange}
                        />
                        <TransactionTypeFilter
                            clearAllFilterClickedTime={clearAllFilterClickedTime}
                            onChange={(value) => {
                                setTransactionTypeFilter(value)
                            }}
                        />
                    </FilterPanel>
                </Grid>
            </Grid>
        </Grid>
        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default AdminMgmtIndexPage
