import React from 'react';
import { constants, useTranslation } from 'cng-web-lib'

import Namespace from "../../../constants/locale/Namespace";
import CecDoUomMappingKeys from 'src/constants/locale/key/CecDoUomMapping'

const NAMESPACE = Namespace.CEC_DO_UOM_MAPPING;

const {
    locale: {
        key: {
            UiComponentKeys,
        },
    },
} = constants

const TranslationText = () => {

    const { translate } = useTranslation([NAMESPACE])

    const moduleTitle = translate(NAMESPACE, CecDoUomMappingKeys.CEC_DO_UOM_MAP_TITLE);

    const weightUom = translate(NAMESPACE, CecDoUomMappingKeys.CEC_DO_UOM_MAP_WEIGHT)
    const volumetricWeightUom = translate(NAMESPACE, CecDoUomMappingKeys.CEC_DO_UOM_MAP_VOLWEIGHT)
    const packageDimensionUom = translate(NAMESPACE, CecDoUomMappingKeys.CEC_DO_UOM_MAP_PACKDIMENSION)

    let cecDoUomMapping = translate(NAMESPACE, CecDoUomMappingKeys.CEC_DO_UOM_MAP_TITLE)


    return {
        moduleTitle,
        weightUom,
        volumetricWeightUom,
        packageDimensionUom,
        cecDoUomMapping
    }
}

export default TranslationText;


