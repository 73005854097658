import {
    Avatar,
    Box,
    Chip,
    makeStyles
  } from '@material-ui/core'
import { components } from 'cng-web-lib'
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form'

const {
    form: { field: { CngLookupAutocompleteField, } },
} = components

const useStyles = makeStyles(theme => ({
    flags: {
      height: '1.2em', width: '1.2em',
      display: 'inline-block'
    },
    label: {
      overflowWrap: 'anywhere'
    }
  }))

const CountryListSelector = ({ id, countryList, setCountryList, url, ...props }) => {
    const classes = useStyles()
    const { setValue } = useFormContext()

    const handleDelete = (value) => {
        setCountryList(countryList.filter((country) => country !== value))
    }

    useEffect(() => {
        setValue(props.name, countryList.join())
    }, [countryList])

    return (
        <CngLookupAutocompleteField
            {...props}
            size='small'
            disableCloseOnSelect={true}
            renderOption={item => (
                <>
                    <Box ml={-1.75} mr={.75}>
                        <Avatar className={classes.flags}
                            src={`${process.env.PUBLIC_URL}/static/images/countries/${item.value}.svg`}
                            alt={`${item.label} flag`}
                            >{item.value}</Avatar>
                    </Box>
                    <Box className={classes.label}>{item.label || item.value}</Box>
                </>
            )}
            lookupProps={{
                label: 'name',
                value: 'code',
                dataAccessor: 'countryList',
                url: url,
                customData: {
                    party_id: id
                }
            }}
            onChange={(e, val) => {
                if(val && !countryList.includes(val.value))
                {
                    setCountryList([...countryList, val.value])
                    setValue(props.name, countryList.join() + val? "," + val.value:'')
                }
            }}
            textFieldProps={{
                InputProps: {
                    customStartAdornment: (startAdornment) =>
                    countryList.length > 0 &&
                        countryList.map((country) => {
                                return <Chip 
                                            key={country} 
                                            onDelete={()=>handleDelete(country)} 
                                            label={country}
                                            avatar={<Avatar className={classes.flags}
                                                    src={`${process.env.PUBLIC_URL}/static/images/countries/${country}.svg`}
                                                    alt={`${country} flag`}
                                                />} 
                                        />
                        })
                }
            }}
            defaultValue=""
        />
    )
}

export default CountryListSelector