import { Yup, constants } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import CalistaAdvisoryKeys from 'src/constants/locale/key/CalistaAdvisory'

const {
	locale: {
	  key: {
		CommonValidationMessageKeys
	  },
	},
  } = constants


const countryRegex = /^[A-Za-z]{2}$/
function makeValidationSchema(translate) {
	let requiredMessage = translate(Namespace.COMMON_VALIDATION_MSG, CommonValidationMessageKeys.REQUIRED)
	let maxLengthMessage = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKeys.ERRORS.FTA_MAX_LENGTH_255)
	let countryAlpha2ErrorMessage = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKeys.ERRORS.COUNTRY_ALPHA_2)

	return Yup.object({
		importCountry: Yup.string().matches(countryRegex, countryAlpha2ErrorMessage).required(requiredMessage),
        exportCountry: Yup.string().matches(countryRegex, countryAlpha2ErrorMessage).required(requiredMessage),
		ftaNameTmp: Yup.string().max(255, maxLengthMessage).required(requiredMessage),
		ftaNameAmberroad: Yup.string().max(255, maxLengthMessage).required(requiredMessage)
	})
}

export default makeValidationSchema
