import {
  Box,
  Button,
} from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';

const FormBody = ({ tabInfo, clickAction, disable }) => {
  //this is component of button, as one part of tab
  //when user stays in the tab, the button will be outlined, otherwise will not
  return (
    <Box>
      <Button
        onClick={clickAction}
        variant={tabInfo.tabClicked ? 'outlined' : 'text'}
        classes={{
          root: clsx(
            tabInfo.tabClicked ? 'ng-button-outline' : 'ls_ng-button-text'
          )
        }}
        disabled={tabInfo.tabClicked ? false : disable}
      >
        {tabInfo.tabText +
          (tabInfo.tabCount != null ? '(' + tabInfo.tabCount + ')' : '')}
      </Button>
    </Box>
  )
}

const GeneralButtonTabComponent = Object.freeze({
  FormBody: FormBody
})

export default GeneralButtonTabComponent
