import React, { useEffect, useRef, useState } from 'react'
import { constants, useServices } from 'cng-web-lib'

import CodeMaintainanceApiUrls from 'src/apiUrls/CodeMaintainanceApiUrls'

function useFetchCodeMaintainance(codeMType, filters, dropdown = false, onSuccess, onComplete, onError) {
    const { fetchRecords } = useServices();

    const returnData = useRef([])
    // returnData.current = [{text : 'No Option', value: ''}]

    useEffect(() => {
        fetchRecords.execute(
            CodeMaintainanceApiUrls.MAINTAINANCE_CODE,
            { 
              customData: { codeMType: codeMType },
              filters: filters
            },
            (data) => {
              // console.log('PortViewField : ' + JSON.stringify(data))
              /*data massage here*/
              if(dropdown){
                let dropdownItems = data.content.map((datum) => ({
                    text:datum['descriptionEn'],
                    value: datum["code"]
                }));

                returnData.current = dropdownItems
                // setReturnData(dropdownItems)
              }else{
                returnData.current = data.content
                // setReturnData(data.content)
              }

              // use onSuccess passed from parent component to set the response data
              onSuccess(returnData.current)

            },
            // (success) => {
              
            // },
            (error) => {
                console.log('useCodeMaintainance : ' + error)
                alert(error)
                // onSuccess(returnData.current)
            }
        )
    }, []);
    return returnData.current;
    // return returnData
};

export default useFetchCodeMaintainance;