import React from 'react'
import { Yup } from 'cng-web-lib'

const ValidationSchema = () => {

  return Yup.object({
    input: Yup.string().required('Required/Mandatory.').max(255, 'Input cannot be more than 255 characters.'),
    output: Yup.string().required('Required/Mandatory.').max(255, 'Output cannot be more than 255 characters.'),
    priority: Yup.object().shape({
      priority: Yup.number().positive().integer('Priority must be an integer.').typeError('Priority must be a positive integer')
        .required('Required/Mandatory').min(1, 'Priority cannot be lesser than 1.').max(100, 'Priority cannot be greater than 100.')
    })
  })
}

export default ValidationSchema

