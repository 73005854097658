import FormProperties from './FormProperties'
import ShipmentProviderUrls from '../../../../../apiUrls/cec-admin/shipment-provider/ShipmentProviderUrls'
import React from 'react'
import { components } from 'cng-web-lib'

const {
  form: {
    CngAddForm
  }
} = components

function AddForm({ history, showNotification, setShipmentProviderId }) {

  return (

    <CngAddForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      renderBodySection={() => <FormProperties.Fields
        showNotification={showNotification}
      />}
      formikProps={FormProperties.formikProps}
      toClientDataFormat={FormProperties.toClientDataFormat}
      toServerDataFormat={FormProperties.toServerDataFormat}
      create={{
        url: ShipmentProviderUrls.CREATE,
        onPostSubmitSuccess: (datum) => {
          setShipmentProviderId(datum.id)
        }
      }}
    />

  )

}

export default AddForm