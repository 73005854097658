import { Card, CardContent, Grid } from '@material-ui/core'
import { components, constants } from 'cng-web-lib'

import React from 'react'
import ConnectorAppsApiUrls from '../../../../apiUrls/cec-admin/connector-config/ConnectorAppsApiUrls'
import ConnectorTranslationText from '../ConnectorTranslationText'

const {
  table: {
    CngCrudTable, useDefaultNotification
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }, filter: { EQUAL }
} = constants

function TablePage(props) {
  const {
    location: { pathname }, showNotification
  } = props

  const notification = useDefaultNotification(showNotification)

  const translatedTextObject = ConnectorTranslationText()

  const columns = [{
    field: 'name', title: translatedTextObject.connectorName
  }, {
    field: 'code', title: translatedTextObject.connectorCode
  }]

  return (<Card>
    <CardContent>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CngCrudTable
            {...props}
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            columns={columns}
            fetch={{ url: ConnectorAppsApiUrls.GET }}
            addRoute={`${pathname}/add`}
            viewRoute={`${pathname}/view`}
            editRoute={`${pathname}/edit`}
            del={{ url: ConnectorAppsApiUrls.DELETE }}
            idAccessor='id'
            notification={notification}
          />
        </Grid>
      </Grid>
    </CardContent>
  </Card>)
}

export default TablePage
