import React, { useRef } from 'react'
import { components } from 'cng-web-lib'
import { Card, CardContent } from '@material-ui/core'
import TranslationText from '../../TranslatedText'
import FormProperties from './CeCPartyConfig_WebhookFormProperties'
import PartyConfigUrls from "../../../../../apiUrls/cec-admin/party-config/PartyConfigUrls";
import SCOAdminApiUrls from "../../../../../apiUrls/SCOAdminApiUrls";


const {
    table: {
        DateRangeFilter: CngDateRangeFilter,
        CngServerModeDialogFormTable, useFetchCustomLookup, useDefaultNotification
    }
} = components


const CrudTable = ({ partyId, isViewOnly, showNotification }) => {

    const fetchCustomLookup = useFetchCustomLookup()
    const translatedTextObject = TranslationText()
    const notification = useDefaultNotification(showNotification)

    const cngTableRef = useRef()

    const columns = [
        {
            field: 'webhookTypeId',
            title: translatedTextObject.webhookType,
            customLookup: () => {
                return fetchCustomLookup(
                    `${SCOAdminApiUrls.GET_CODE_MASTER_LIST}`,
                    {
                        codeType: 'CEC_WEBHOOK_TYPE'
                    },
                    'content',
                    'name',
                    'id',
                    (error) => notification.error(error.message)
                )
            }
        },
        {
            field: 'webhookURL',
            title: translatedTextObject.webhookURL
        },
        {
            field: 'createdDate',
            title: translatedTextObject.createdAt,
            type: 'datetime',
            filterComponent: CngDateRangeFilter,
        },
        {
            field: 'createdBy',
            title: translatedTextObject.createdBy,
            customLookup: () => {
                return fetchCustomLookup(
                    `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user/detail/get`,
                    {
                        "partyId": partyId
                    },
                    'content',
                    'userProfile.loginId', //label accessor
                    'id', //value accessor
                    (error) => console.error(error)
                )
            }
        },
        {
            field: 'updatedDate',
            title: translatedTextObject.updatedAt,
            type: 'datetime',
            filterComponent: CngDateRangeFilter,
        },
        {
            field: 'updatedBy',
            title: translatedTextObject.updatedBy,
            customLookup: () => {
                return fetchCustomLookup(
                    `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user/detail/get`,
                    {
                        "partyId": partyId
                    },
                    'content',
                    'userProfile.loginId', //label accessor
                    'id', //value accessor
                    (error) => console.error(error)
                )
            }
        }
    ]

    return (
        <Card>
            <CardContent>
                <CngServerModeDialogFormTable
                    fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                    disabled={isViewOnly}
                    showNotification={showNotification}
                    tableColumns={columns}
                    pageSize={10}
                    formProperties={{
                        ...FormProperties, formikProps: {
                            ...FormProperties.formikProps, initialValues: {
                                ...FormProperties.formikProps.initialValues, partyId : partyId
                            }
                        }
                    }}
                    toClientDataFormat={(serverData) => {
                        return serverData
                    }}
                    toServerDataFormat={(localDatum) => {
                        return localDatum
                    }}
                    fetch={{
                        url: PartyConfigUrls.CEC_WEBHOOK_GET, body: {
                            partyId: partyId
                        }
                    }}
                    create={{
                        url: PartyConfigUrls.CEC_WEBHOOK_CREATE, body: {
                            partyId: partyId,
                        },
                        onSuccess: () => {
                            notification.success("Create record has succeeded.")
                            if(cngTableRef.current.onQueryChange)
                                cngTableRef.current.onQueryChange()
                        }
                    }}
                    update={{ url: PartyConfigUrls.CEC_WEBHOOK_UPDATE,
                        onSuccess: () => {
                            notification.success("Update record has succeeded.")
                            if(cngTableRef.current.onQueryChange)
                                cngTableRef.current.onQueryChange()
                        }
                    }}
                    del={{ url: PartyConfigUrls.CEC_WEBHOOK_DELETE,
                        onSuccess: () => {
                            notification.success("Delete record has succeeded.")
                            if(cngTableRef.current.onQueryChange)
                                cngTableRef.current.onQueryChange()
                        }
                    }}
                    idAccessor='id'
                    tableRef = {cngTableRef}
                />
            </CardContent>
        </Card>

    )
}


export default CrudTable


