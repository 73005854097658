import FormProperties from './FormProperties'
import CecAdminPathMap from '../../../../../paths/PathMap_CeCAdmin'
import ShipmentProviderUrls from '../../../../../apiUrls/cec-admin/shipment-provider/ShipmentProviderUrls'
import React from 'react'
import { components } from 'cng-web-lib'

const {
  button: {
    CngSecondaryButton
  }, form: {
    CngEditForm, CngEditFormButtonSection
  }, CngGridItem
} = components


function EditForm({ history, showNotification, shipmentProviderId }) {

  return (

    <CngEditForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      showNotification={showNotification}
      renderBodySection={() => <FormProperties.Fields
        disabled={false}
        showNotification={showNotification}
        id={shipmentProviderId}
      />}
      renderButtonSection={() => (<CngEditFormButtonSection>
          <CngGridItem>
            <CngSecondaryButton
              onClick={() => {
                history.push(CecAdminPathMap.SHIPMENT_PROVIDER_LIST_VIEW)
              }}>
              Back
            </CngSecondaryButton>
          </CngGridItem>
        </CngEditFormButtonSection>

      )}
      formikProps={{
        ...FormProperties.formikProps
      }}
      toClientDataFormat={FormProperties.toClientDataFormat}
      toServerDataFormat={FormProperties.toServerDataFormat}
      fetch={{
        url: `${ShipmentProviderUrls.GET}`
      }}
      update={{
        url: ShipmentProviderUrls.UPDATE
      }}
      id={shipmentProviderId}
    />

  )

}

export default EditForm