import {
    Box,
    Typography,

    makeStyles
} from '@material-ui/core'
import React, { useMemo } from 'react'
import { Line } from 'react-chartjs-2';
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
    line_chart: {
        height: '400px'

    },
    text: {
        fontSize: '20px',
        fontWeight: '700'
    }
}))


const dynamicLabels = data => {
    var resultArr = [];

    if (typeof data !== 'undefined') {
        for (let i = 0; i < data.length; i++) {
            resultArr.push(data[i].transaction_date)
        }
    }
    return resultArr
}

const processedNumberList = data => {
    var resultArr = [];
    if (typeof data !== 'undefined') {
        for (let i = 0; i < data.length; i++) {
            resultArr.push(data[i].transaction_number)
        }
    }
    return resultArr
}

function TransactionChart(props) {
    const classes = useStyles()

    const labels = useMemo(() => dynamicLabels(props.hsrecommend_chart_number), [props.hsrecommend_chart_number])
    const hsRecommendNumberList = useMemo(() => processedNumberList(props.hsrecommend_chart_number), [props.hsrecommend_chart_number])
    const eAdvisoryNumberList = useMemo(() => processedNumberList(props.eadvisory_chart_number), [props.eadvisory_chart_number])
    const landedcostNumberList = useMemo(() => processedNumberList(props.landedcost_chart_number), [props.landedcost_chart_number])

    const data = {
        labels,
        datasets: [
            {
                id: 1,
                label: 'Landed cost',
                data: landedcostNumberList,
                borderColor: 'rgb(0, 75, 143)',
                backgroundColor: 'rgba(0, 75, 143, 0.8)',
            },
            {
                id: 2,
                label: 'eAdvisory',
                data: eAdvisoryNumberList,
                borderColor: 'rgb(0, 107, 201)',
                backgroundColor: 'rgba(0, 107, 201, 0.8)',
            },
            {
                id: 3,
                label: 'HS Code recomm.',
                data: hsRecommendNumberList,
                borderColor: 'rgb(101, 180, 243)',
                backgroundColor: 'rgba(101, 180, 243, 0.8)',
            },
        ],
    };


    return (
        <Box className={classes.container}>
            {props.transaction_records && (
                <Typography variant='caption' className={classes.text}>
                    Total transactions by type
                </Typography>
            )}
            <br />
            <br />
            <Line
                className={classes.line_chart}
                datasetIdKey='id'
                data={data}
            />

        </Box>

    )
}

TransactionChart.propTypes = {
    title: PropTypes.string,
}

export default TransactionChart
