import FormProperties from './FormProperties'
import ShipmentProviderDefaultPartyUrls
  from '../../../../../apiUrls/cec-admin/shipment-provider/ShipmentProviderDefaultPartyUrls'
import React from 'react'
import { components } from 'cng-web-lib'

const {
  button: {
    CngSecondaryButton
  }, form: {
    CngEditForm, CngEditFormButtonSection
  }, CngGridItem
} = components


function EditForm({ history, showNotification, contactDetailsId, partyType }) {

  return (

    <CngEditForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      showNotification={showNotification}
      renderBodySection={() => <FormProperties.Fields
        disabled={false}
        showNotification={showNotification}
        id={contactDetailsId}
        partyType={partyType}
      />}
      renderButtonSection={() => (<CngEditFormButtonSection>
          <CngGridItem>
            <CngSecondaryButton
              onClick={() => {
                history.goBack()
              }}>
              Back
            </CngSecondaryButton>
          </CngGridItem>
        </CngEditFormButtonSection>
      )}
      formikProps={{
        ...FormProperties.formikProps
      }}
      toClientDataFormat={FormProperties.toClientDataFormat}
      toServerDataFormat={FormProperties.toServerDataFormat}
      fetch={{
        url: `${ShipmentProviderDefaultPartyUrls.GET}`
      }}
      update={{
        url: ShipmentProviderDefaultPartyUrls.UPDATE
      }}
      id={contactDetailsId}
    />

  )

}

export default EditForm