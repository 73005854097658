import React from 'react'
import { useParams } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import CiaAdminMassRecommendApiUrls from 'src/apiUrls/cia-admin/CiaAdminMassRecommendApiUrls'
import CiaKeys from 'src/constants/locale/key/CalistaAdvisory'

import FormProperties from './CiaAdminMassRecommend_RequestRowFormProperties'

const {
    table: { CngServerModeDialogFormTable }
} = components

function RequestRowTable(props) {

    const { id } = useParams()
    const { showNotification } = props

    // Translations ------------------------------------------------------------
    const ciaNamespace = Namespace.CALISTA_ADVISORY
    const { translate } = useTranslation(Namespace.UI_COMPONENT, ciaNamespace)

    const CiaFieldKeys = CiaKeys.FIELDS

    const Labels = {
        ROW_REF: translate(ciaNamespace, CiaFieldKeys.ROW_REF),
        INPUT_TEXT: translate(ciaNamespace, CiaFieldKeys.INPUT_TEXT),
        COUNTRY: translate(ciaNamespace, CiaFieldKeys.COUNTRY),
        TYPE: translate(ciaNamespace, CiaFieldKeys.TYPE),
        CONFIDENCE: translate(ciaNamespace, CiaFieldKeys.CONFIDENCE),
        HS_CODE: translate(ciaNamespace, CiaFieldKeys.HS_CODE),
        STATUS: translate(ciaNamespace, CiaFieldKeys.STATUS)
    }

    //* Table Columns ----------------------------------------------------------
    const columns = [
        { field: 'rowRef', title: Labels.ROW_REF },
        { field: 'inputText', title: Labels.INPUT_TEXT, sorting: false },
        { field: 'targetCountry', title: Labels.COUNTRY },
        {
            field: 'targetClassificationType', title: Labels.TYPE,
            lookup: {
                IMPORT: 'IMPORT',
                EXPORT: 'EXPORT'
            }
        },
        { field: 'confidenceLevel', title: Labels.CONFIDENCE, type: 'numeric' },
        { field: 'hsCode', title: Labels.HS_CODE },
        {
            field: 'status', title: Labels.STATUS,
            lookup: {
                OK: 'OK',
                ERROR: 'ERROR'
            }
        },
    ];

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <CngServerModeDialogFormTable
                    fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'

                    showNotification={showNotification}
                    tableColumns={columns}

                    formProperties={FormProperties}

                    fetch={{
                        url: CiaAdminMassRecommendApiUrls.GET_REQUEST_ROW,
                        body: { requestId: id }
                    }}
                    idAccessor='id'

                    disabled={true} // To remove the create/edit/delete buttons.
                />
            </Grid>
        </Grid>
        //* End JSX ------------------------------------------------------------
    )

    //* End of function --------------------------------------------------------
}

export default RequestRowTable
