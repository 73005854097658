import React from 'react'
import { Grid } from '@material-ui/core'
import makeValidationSchema from '../../shared/MakeValidationSchema'
import ContractSettingsComponent from '../../components/ContractSettingsComponent'
import HsCodeSettingsComponent from '../../components/HsCodeSettingsComponent'
import ComplianceEAdvisorySettingsComponent from '../../components/ComplianceEAdvisorySettingsComponent'
import LandedCostSettingsComponent from '../../components/LandedCostSettingsComponent'

//* Field default values -------------------------------------------------------
const DEFAULT_INITIAL_VALUES = Object.freeze({
    ...ContractSettingsComponent.initialValues,
    ...HsCodeSettingsComponent.initialValues,
    ...ComplianceEAdvisorySettingsComponent.initialValues,
    ...LandedCostSettingsComponent.initialValues,
})

const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
    makeValidationSchema: makeValidationSchema
}

//* Fields function
function Fields({ disabled }) {
    //* return JSX
    return (

        //* Start JSX
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <ContractSettingsComponent.FormBody disabled={disabled} />
            </Grid>

            <Grid item xs={12}>
                <HsCodeSettingsComponent.FormBody disabled={disabled}/>
            </Grid>

            <Grid item xs={12}>
                <ComplianceEAdvisorySettingsComponent.FormBody disabled={disabled}/>
            </Grid>

            <Grid item xs={12}>
                <LandedCostSettingsComponent.FormBody disabled={disabled}/>
            </Grid>
        </Grid>
        //* End JSX
    )
    //* End of function
}

function toClientDataFormat(serverData) {
    return serverData;
}

function toServerDataFormat(localData) {
    return localData;
}

const CiaConfigFormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default CiaConfigFormProperties