import React from 'react'
import { components } from 'cng-web-lib'
import {Card, CardContent, Grid} from '@material-ui/core'
import TranslationText from './TranslatedText'
import PartyConfigUrls from "../../../apiUrls/cec-admin/party-config/PartyConfigUrls";

const {
    table: {
        CngCrudTable,
        useDefaultNotification
    }
} = components

function TablePage(props) {
    const {
        location: { pathname },
        showNotification
    } = props

    const notification = useDefaultNotification(showNotification)

    const translatedTextObject = TranslationText()

    const columns = [
        {
            field: 'name',
            title: translatedTextObject.partyName
        }
    ]

    return (
        <Card>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <CngCrudTable
                            {...props}
                            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                            columns={columns}
                            disabled={false}
                            fetch={{ url: PartyConfigUrls.TPR_PARTY_GET }}
                            idAccessor='id'
                            notification={notification}
                            viewRoute={`${pathname}/view`}
                            editRoute={`${pathname}/edit`}
                            addRoute={`${pathname}/add`}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default TablePage

