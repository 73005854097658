import React from 'react'
import { components } from 'cng-web-lib'
import { useParams } from 'react-router-dom'

import pathMap from 'src/paths/PathMap_CeCAdmin'
import CecDoUomMappingApiUrls from 'src/apiUrls/CecDoUomMappingApiUrls'
import FormProperties from './FormProperties'

const {
  form: {
    CngEditFormButtonSection
  },
  button: { CngSecondaryButton },
  CngGridItem
} = components

const { form: { CngEditForm } } = components

function EditPage({ history, showNotification }) {
  const { id } = useParams()

  return (

    <CngEditForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      shouldDisableEnterToSubmit
      showNotification={showNotification}
      bodySection={
        <FormProperties.Fields
          disabled={false}
          showNotification={showNotification}
          mode={"edit"}
          id={id}
        />
      }
      renderButtonSection={() => (
        <CngEditFormButtonSection>
          <CngGridItem>
            <CngSecondaryButton
              onClick={() => { history.push(pathMap.CEC_DO_UOM_MAPPING_LIST_VIEW) }}>
              {'Cancel'}
            </CngSecondaryButton>
          </CngGridItem>
        </CngEditFormButtonSection>
      )}
      formikProps={FormProperties.formikProps}
      toClientDataFormat={FormProperties.toClientDataFormat}
      toServerDataFormat={FormProperties.toServerDataFormat}
      fetch={{
        url: CecDoUomMappingApiUrls.List_DO_UOM_MAPPING
      }}
      update={{
        url: CecDoUomMappingApiUrls.EDIT_DO_UOM_MAPPING,
        successRedirect: pathMap.CEC_DO_UOM_MAPPING_LIST_VIEW
      }}
      id={id}
    />
  )

}

export default EditPage
