import React from 'react'
import { Yup } from 'cng-web-lib'

const ValidationSchema = () => {

  return Yup.object({
    name: Yup.string().required('Required/Mandatory').max(255, 'Name cannot be more than 255 characters.'),
    code: Yup.string().required('Required/Mandatory').max(255, 'Code cannot be more than 255 characters.'),
    packageTypes: Yup.string().required('Required/Mandatory')
  })
}

export default ValidationSchema

