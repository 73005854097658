import React from 'react'
import Namespace from '../../../../constants/locale/Namespace'
import ShipmentProviderKeys from '../../../../constants/locale/key/cec/ShipmentProviderKeys'
import { useTranslation } from 'cng-web-lib'

const NAMESPACE = Namespace.SHIPMENT_PROVIDERS


const TranslationText = () => {


  const { translate } = useTranslation([NAMESPACE])
  //Section Headings
  const shipperDetailsTitle = translate(NAMESPACE, ShipmentProviderKeys.SHIPPER_DETAILS_TITLE)
  const consigneeDetailsTitle = translate(NAMESPACE, ShipmentProviderKeys.CONSIGNEE_DETAILS_TITLE)

  //form fields
  const name = translate(NAMESPACE, ShipmentProviderKeys.CONTACT_NAME)
  const phone = translate(NAMESPACE, ShipmentProviderKeys.CONTACT_PHONE)
  const fax = translate(NAMESPACE, ShipmentProviderKeys.FAX)
  const email = translate(NAMESPACE, ShipmentProviderKeys.EMAIL)
  const companyName = translate(NAMESPACE, ShipmentProviderKeys.COMPANY_NAME)
  const taxNo = translate(NAMESPACE, ShipmentProviderKeys.TAX_NO)
  const address = translate(NAMESPACE, ShipmentProviderKeys.ADDRESS)
  const city = translate(NAMESPACE, ShipmentProviderKeys.CITY)
  const state = translate(NAMESPACE, ShipmentProviderKeys.STATE)
  const country = translate(NAMESPACE, ShipmentProviderKeys.COUNTRY)
  const postalCode = translate(NAMESPACE, ShipmentProviderKeys.POSTAL_CODE)
  const addressType = translate(NAMESPACE, ShipmentProviderKeys.ADDRESS_TYPE)


  return {
    shipperDetailsTitle,
    consigneeDetailsTitle,
    name,
    phone,
    fax,
    email,
    companyName,
    taxNo,
    address,
    city,
    state,
    country,
    postalCode,
    addressType
  }
}

export default TranslationText