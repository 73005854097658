import React, { useState } from 'react'
import { Card, CardContent, Grid } from '@material-ui/core'
import FormProperties from './ConnectorMappingsFormProperties'
import ConnectorMappingApiUrls from '../../../../apiUrls/cec-admin/connector-config/ConnectorMappingApiUrls'
import { components } from 'cng-web-lib'
import { useLocation } from 'react-router-dom'
import AccordionHeaderComponent from '../../../common/ui/AccordionHeaderComponent'
import MappingItemCrudTable from '../mapping-items/CrudTable'
import ConnectorTranslationText from '../ConnectorTranslationText'


const {
  button: {
    CngSecondaryButton
  }, form: {
    CngAddForm, CngEditForm, CngEditFormButtonSection
  }, CngGridItem
} = components


function AddPage({ history, showNotification }) {

  const location = useLocation()
  const connectorAppId = location.state.connectorAppId

  const [connectorMappingId, setConnectorMappingId] = useState('')
  const [mappingName, setMappingName] = useState('')

  const {
    connectorMappingItems
  } = ConnectorTranslationText()


  return (

    <Grid container spacing={1}>
      <CngGridItem item xs={12}>

        <Card>
          <CardContent>

            {connectorMappingId === ''

              ?

              <CngAddForm
                fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                history={history}
                showNotification={showNotification}
                bodySection={
                  <FormProperties.Fields
                    showNotification={showNotification}
                  />
                }
                formikProps={{
                  initialValues: { ...FormProperties.formikProps.initialValues, appId: connectorAppId },
                  makeValidationSchema: FormProperties.formikProps.makeValidationSchema
                }}
                toClientDataFormat={(serverData) => {
                  setMappingName(serverData['name'])
                  return serverData
                }}
                create={{
                  url: ConnectorMappingApiUrls.CREATE,
                  onPostSubmitSuccess: (datum) => {
                    setConnectorMappingId(datum.id)
                  }
                }}
              />


              :

              <CngEditForm
                fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                showNotification={showNotification}
                bodySection={<FormProperties.Fields
                  disabled={false}
                  showNotification={showNotification}
                  id={connectorMappingId}
                />}
                renderButtonSection={() => (<CngEditFormButtonSection>
                    <CngGridItem>
                      <CngSecondaryButton
                        onClick={() => {
                          history.goBack()
                        }}>
                        Back
                      </CngSecondaryButton>
                    </CngGridItem>
                  </CngEditFormButtonSection>

                )}
                formikProps={{
                  ...FormProperties.formikProps
                }}
                toClientDataFormat={(serverData) => {
                  setMappingName(serverData['name'])
                  return serverData
                }}
                toServerDataFormat={FormProperties.toServerDataFormat}
                fetch={{
                  url: `${ConnectorMappingApiUrls.GET}`,
                  body: {
                    'id': connectorMappingId
                  }
                }}
                update={{
                  url: `${ConnectorMappingApiUrls.UPDATE}`
                }}
                id={connectorMappingId}
              />


            }

          </CardContent>
        </Card>
      </CngGridItem>

      {connectorMappingId !== ''

        ? <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container>
                <AccordionHeaderComponent
                  title={connectorMappingItems} hideMandatory />

              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12}>

                  <MappingItemCrudTable
                    mappingId={parseInt(connectorMappingId)}
                    key={mappingName}
                    isViewOnly={false}
                    showNotification={showNotification}
                    mappingName={mappingName}
                  />

                </Grid>
              </Grid>

            </CardContent>
          </Card>
        </Grid> : null}


    </Grid>)


}

export default AddPage