import FormProperties from './FormProperties'
import React from 'react'
import { components } from 'cng-web-lib'
import ConnectorType from '../../../../../constants/cec/ConnectorType'
import Urls from '../../../../../apiUrls/cec-admin/shipment-provider/ShipmentProviderConnectorMappingUrls'

const {
  button: {
    CngSecondaryButton
  }, form: {
    CngEditForm, CngEditFormButtonSection
  }, CngGridItem
} = components

const getMappingDetails = (data) => {
  let returnValue = ''
  if (data) {
    if (data.connectorId) {
      returnValue += data.connectorId
    } else {
      returnValue += 0
    }
    if (data.credentialId) {
      returnValue += data.credentialId
    } else {
      returnValue += 0
    }
    if (data.endpointId) {
      returnValue += data.endpointId
    } else {
      returnValue += 0
    }
  }
  return returnValue
}


function EditForm({ history, showNotification, connectorRecord, connectorType, mappingId, setMappingDetails }) {

  return (

    <CngEditForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      showNotification={showNotification}
      renderBodySection={() =>
        <FormProperties.Fields
          disabled={false}
          connectorRecord={connectorRecord}
          connectorType={connectorType}
          showNotification={showNotification}
          id={mappingId}
        />
      }
      renderButtonSection={() => (<CngEditFormButtonSection>
          <CngGridItem>
            <CngSecondaryButton
              onClick={() => {
                history.goBack()
              }}>
              Back
            </CngSecondaryButton>
          </CngGridItem>
        </CngEditFormButtonSection>

      )}
      formikProps={connectorType === ConnectorType.CustomerApplication ? {
        ...FormProperties.formikPropsForCustomerConfig
      } : {
        ...FormProperties.formikPropsForCourierConfig
      }}
      toClientDataFormat={serverData => {
        setMappingDetails(getMappingDetails(serverData))
        return serverData
      }}
      toServerDataFormat={FormProperties.toServerDataFormat}
      fetch={{
        url: `${Urls.GET}`,
        body: {
          'id': mappingId
        }
      }}
      update={{
        url: `${Urls.UPDATE}`
      }}
      id={mappingId}
    />

  )

}

export default EditForm