import React, { useEffect } from 'react'
import ValidationSchema from './ValidationSchema'
import { components, constants } from 'cng-web-lib'
import TranslationText from '../TranslationText'
import { Card, CardContent, Chip, Grid } from '@material-ui/core'
import AccordionHeaderComponent from '../../../../common/ui/AccordionHeaderComponent'
import SCOAdminApiUrls from '../../../../../apiUrls/SCOAdminApiUrls'


const {
  form: {
    adapter: {
      useFormAdapter: { useField }
    },
    field: {
      CngTextField, CngLookupAutocompleteField, CngSwitchField
    }
  }, CngGridItem
} = components

const {
  filter: {
    EQUAL
  }
} = constants


function Fields({ ...props }) {

  const { disabled } = props
  const {
    shipmentProviderDetails, shipmentProviderName, shipmentProviderCode, isActive, isManagedByGeTS, packageTypes
  } = TranslationText()

  const [packageTypesField] = useField('packageTypes')


  const fixPackageTypeOrder = (packageTypesValues) => {
    const packageTypesInput = document.getElementsByName('packageTypes')[0] !== undefined ? document.getElementsByName('packageTypes')[0].parentNode : undefined
    if (packageTypesInput !== undefined) {
      for (let i = packageTypesValues.length - 1; i >= 0; i--) {
        const selector = '[data-value="' + packageTypesValues[i] + '"]'
        packageTypesInput.prepend(packageTypesInput.querySelector(selector))
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      fixPackageTypeOrder(packageTypesField.value)
    }, 1000)
  }, [])


  return (<>
      <Grid container spacing={3}>
        <CngGridItem xs={12} sm={12}>
          <Card>
            <CardContent>
              <Grid container>

                <AccordionHeaderComponent
                  title={shipmentProviderDetails} />

                <Grid container spacing={2}>
                  <CngGridItem xs={12} sm={6}>
                    <CngTextField
                      isRequired
                      name='name'
                      label={shipmentProviderName}
                      disabled={disabled}
                    />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={6}>
                    <CngSwitchField
                      name='isActive'
                      label={isActive}
                      disabled={disabled}
                    />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={6}>
                    <CngTextField
                      name='code'
                      label={shipmentProviderCode}
                      disabled={disabled}
                      isRequired
                    />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={6}>
                    <CngSwitchField
                      name='isGets'
                      label={isManagedByGeTS}
                      disabled={disabled}
                    />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={6}>
                    <CngLookupAutocompleteField
                      isRequired
                      initFetch={true}
                      multiple={true}
                      disableCloseOnSelect
                      name='packageTypes'
                      label={packageTypes}
                      disabled={disabled}
                      lookupProps={{
                        url: SCOAdminApiUrls.GET_CODE_MASTER_LIST,
                        label: 'name',
                        value: 'id',
                        filters: [
                          {
                            field: 'codeType',
                            operator: EQUAL,
                            value: 'CEC_PACKAGE_TYPE'
                          }
                        ]
                      }}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            variant='outlined'
                            data-value={option['value']}
                            data-label={option['label']}
                            key={option.key}
                            label={option.label}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                    />
                  </CngGridItem>

                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </CngGridItem>
      </Grid>
    </>

  )
}


const toClientDataFormat = serverData => serverData

const toServerDataFormat = localData => localData

const DEFAULT_INITIAL_VALUES = {
  code: '', name: '', isActive: true, isGets: true, packageTypes: []
}

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }, makeValidationSchema: ValidationSchema
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties