import { BasePage as CngBasePage } from 'cng-web-lib'
import React from 'react'
import AdminMgmtIndexPage from './cia-acct-management/AdminMgmtIndexPage'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_CIAAcctMgmt'
import AdminAcctMgmtIndexPage from "./cia-admin-acct-mgmt/AdminAcctMgmtIndexPage";
import AdminAcctMgmtCompanyIndexPage from "./cia-admin-acct-mgmt/AdminAcctMgmtCompanyIndexPage";

const MODULE_TITLE = 'CIA Account management'
const MODULE_TITLE_ADMIN = 'CIA Admin Account management'

export const AcctMgmtPage = props => {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      moduleTitle={MODULE_TITLE}
      renderPage={(showSnackbar) => (
        <AdminMgmtIndexPage />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export const AdminAcctMgmtPage = props => {
    const breadcrumbNameMap = useBreadcrumbNameMap()
    return (
        <CngBasePage
            moduleTitle={MODULE_TITLE_ADMIN}
            renderPage={(showSnackbar) => (
                <AdminAcctMgmtIndexPage />
            )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
        />
    )
}

export const AdminAcctMgmtCompanyViewPage = props => {
    const breadcrumbNameMap = useBreadcrumbNameMap()
    return (
        <CngBasePage
            moduleTitle={MODULE_TITLE_ADMIN}
            renderPage={(showSnackbar) => (
                <AdminAcctMgmtCompanyIndexPage />
            )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
        />
    )
}
