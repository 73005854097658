import React, { useEffect, useRef, useState } from 'react'
import { constants, useServices } from 'cng-web-lib'

import SharedServiceApiUrls from 'src/apiUrls/SharedServiceApiUrls.js'

function useFetchContainerType(dropdown = false, onSuccess, onComplete) {
    const { fetchRecords } = useServices();

    // useRef is not able to return value to parent component
    const returnData = useRef([])
    // useState will cause multiple call sometime
    // const [returnData, setReturnData] = useState([])

    useEffect(() => {
        fetchRecords.execute(
          SharedServiceApiUrls.GET_CONTAINER_TYPE,
            // { customData: { codeMType: CodeMaintenanceType.CONTAINER_TYPE } },
            {},
            (data) => {
              /*data massage here*/
              // alert('data' + JSON.stringify(data))
              if(dropdown){
                let dropdownItems = data.content.map((datum) => ({
                    text:datum['containerIsoType'],
                    value: datum["isoCode"]
                  }));

                returnData.current = dropdownItems
                // setReturnData(dropdownItems)
                onSuccess(dropdownItems)
              }else{
                returnData.current = data.content
                // setReturnData(data.content)
                onSuccess(data.content)
              }
                
              // use onSuccess passed from parent component to set the response data
              // onSuccess(returnData.current)

            },
            // (success) => {
              
            // },
            (error) => {
                console.log(error)
                // alert(JSON.stringify(error))
            }
        )
    }, []);
    return returnData.current;
    // return returnData
};

export default useFetchContainerType;