import { Card, CardContent, Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'
import React, { useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import AccordionHeaderComponent from '../../../common/ui/AccordionHeaderComponent'
import CrudTable from '../connector-variables/CrudTable'
import ConnectorVariablesTranslationText from '../connector-variables/TranslationText'
import ConnectorMappingEditForm from './forms/EditForm'

const {
  CngGridItem
} = components

function EditPage({ history, showNotification }) {

  const { mappingId } = useParams()
  const location = useLocation()
  const connectorRecord = location.state.connectorRecord
  const connectorType = location.state.connectorType
  const [mappingDetails, setMappingDetails] = useState()

  const {
    variables
  } = ConnectorVariablesTranslationText()

  return (

    <Grid container spacing={1}>
      <CngGridItem item xs={12}>

        <Card>
          <CardContent>

            <ConnectorMappingEditForm
              history={history}
              showNotification={showNotification}
              connectorRecord={connectorRecord}
              connectorType={connectorType}
              mappingId={mappingId}
              setMappingDetails={setMappingDetails}
            />

          </CardContent>
        </Card>

      </CngGridItem>

      <Grid item xs={12}>
        <Card>
          <CardContent>

            <Grid container>
              <AccordionHeaderComponent
                title={variables} hideMandatory />

            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12}>

                <CrudTable
                  mappingId={parseInt(mappingId)}
                  isViewOnly={false}
                  showNotification={showNotification}
                  key={mappingDetails}
                />

              </Grid>
            </Grid>

          </CardContent>
        </Card>
      </Grid>
    </Grid>)

}

export default EditPage