import React from 'react'
import { components } from 'cng-web-lib'

const {
    form: { field: { CngSelectField } },
    CngGridItem
} = components

const TransactionLimitTypeSelector = ({ name, label, disabled, setHasLimit}) => {
  const handleSelectChange = (e) => {
    switch (e.target.value)
    {
      case 'No Limit':
        setHasLimit(false);
        break;
      case 'Total Transaction':
      case 'Monthly Transaction':
        setHasLimit(true);
        break;
    }
  }
  return (
    <CngGridItem xs={12} sm={6}>
        <CngSelectField name={name}
            label={label}
            disabled={disabled}
            onChange={handleSelectChange}
            items={[
                { text: 'No Limit', value: 'No Limit' },
                { text: 'Total Transaction', value: 'Total Transaction' },
                { text: 'Monthly Transaction', value: 'Monthly Transaction' }
            ]} />
    </CngGridItem>
  )
}

export default TransactionLimitTypeSelector