import React from 'react'
import Namespace from '../../../../constants/locale/Namespace'
import ShipmentProviderKeys from '../../../../constants/locale/key/cec/ShipmentProviderKeys'
import { useTranslation } from 'cng-web-lib'

const NAMESPACE = Namespace.SHIPMENT_PROVIDERS


const TranslationText = () => {

  const { translate } = useTranslation([NAMESPACE])

  //form fields
  const connectorName = translate(NAMESPACE, ShipmentProviderKeys.CONNECTOR_NAME)
  const connectorMapping = translate(NAMESPACE, ShipmentProviderKeys.CONNECTOR_MAPPING)
  const endpointName = translate(NAMESPACE, ShipmentProviderKeys.ENDPOINT_NAME)
  const credentialName = translate(NAMESPACE, ShipmentProviderKeys.CREDENTIAL_NAME)
  const courierConnectorConfigurations = translate(NAMESPACE, ShipmentProviderKeys.COURIER_CONNECTOR_CONFIGURATIONS)
  const customerConnectorConfigurations = translate(NAMESPACE, ShipmentProviderKeys.CUSTOMER_CONNECTOR_CONFIGURATIONS)
  const fulfilmentConnectorConfigurations = translate(NAMESPACE, ShipmentProviderKeys.FULFILMENT_CONNECTOR_CONFIGURATIONS)

  return {
    connectorName,
    endpointName,
    credentialName,
    courierConnectorConfigurations,
    customerConnectorConfigurations,
    connectorMapping,
    fulfilmentConnectorConfigurations

  }
}

export default TranslationText