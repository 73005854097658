import Namespace from 'src/constants/locale/Namespace'
import { useTranslation } from 'cng-web-lib'
import CommonCityMappingKeys from 'src/constants/locale/key/cec/CommonCityMappingKeys'


const CommonCityTranslationText = () => {

      const { translate } = useTranslation([
            Namespace.UI_COMPONENT,
            Namespace.CEC_COMMON_CITY_MAP
      ])

      let title = translate(
            Namespace.CEC_COMMON_CITY_MAP,
            CommonCityMappingKeys.COMMON_CITY_MAPPING_TITLE
      )

      let countryCode = translate(
            Namespace.CEC_COMMON_CITY_MAP,
            CommonCityMappingKeys.COUNTRY_CODE
      )

      let commonCityName = translate(
            Namespace.CEC_COMMON_CITY_MAP,
            CommonCityMappingKeys.COMMON_CITY_NAME
      )

      let locCode = translate(
            Namespace.CEC_COMMON_CITY_MAP,
            CommonCityMappingKeys.LOC_CODE
      )

      let party = translate(
            Namespace.CEC_COMMON_CITY_MAP,
            CommonCityMappingKeys.PARTY
      )

      let country = translate(
            Namespace.CEC_COMMON_CITY_MAP,
            CommonCityMappingKeys.COUNTRY
      )

      let requiredField = translate(
            Namespace.CEC_COMMON_CITY_MAP,
            CommonCityMappingKeys.REQUIRED_FIELD
      )

      let cancel = translate(
            Namespace.CEC_COMMON_CITY_MAP,
            CommonCityMappingKeys.CANCEL
      )

      let back = translate(
            Namespace.CEC_COMMON_CITY_MAP,
            CommonCityMappingKeys.BACK
      )


      return {
            title,
            countryCode,
            commonCityName,
            locCode,
            party,
            country,
            requiredField,
            cancel,
            back
      }
}

export default CommonCityTranslationText