import React from 'react'
import { useParams } from 'react-router'
import { components } from 'cng-web-lib'

import FormProperties from './CiaSysConfig_FormProperties'
import CalistaAdvisorySystemConfigApiUrls from 'src/apiUrls/cia-admin/CalistaAdvisorySystemConfigApiUrls'

const { form: { CngEditForm } } = components

function EditPage({ history, showNotification}) {
    
    const { id } = useParams()

    return (
        <CngEditForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            history={history}
            showNotification={showNotification}
            bodySection={
                <FormProperties.Fields
                    showNotification={showNotification}
                    id={id}
                />
            }
            formikProps={FormProperties.formikProps}
            toClientDataFormat={FormProperties.toClientDataFormat}
            toServerDataFormat={FormProperties.toServerDataFormat}
            fetch={{ url: CalistaAdvisorySystemConfigApiUrls.GET }}
            update={{ url: CalistaAdvisorySystemConfigApiUrls.PUT }}
            id={id}
        />
    )
}

export default EditPage