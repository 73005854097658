import { Box, Button, Grid } from '@material-ui/core'
import React, { useState } from 'react'

import CalistaUiComponentTranslationText from '../CalistaUiComponentTranslationText'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

function AccordionFooterControlComponent(props) {
  const translatedTextsObject = CalistaUiComponentTranslationText()
  const [captionDisplay, setCaptionDisplay] = useState(false)

  const handleMouseOverOn = () => {
    setCaptionDisplay(true)
  }

  const handleMouseOverOff = () => {
    setCaptionDisplay(false)
  }

  return (
    <Grid
      item
      onClick={props.handleAccordionExpand}
      style={props.expanded ? { marginTop: 0 } : {}}
    >
      <Button
        variant='contained'
        color='default'
        fullWidth
        endIcon={props.expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        className={'accordion-footer'}
        onMouseEnter={handleMouseOverOn}
        onMouseLeave={handleMouseOverOff}
      >
        <Box style={{ textAlign: 'left' }}>
          <Box
            mt={!props.noOptionalFields && captionDisplay ? 0 : 1}
            fontWeight={600}
            fontSize={13}
          >
            {props.expanded && props.footerTextExpanded
              ? props.footerTextExpanded
              : props.footerText}
          </Box>
          {!props.noOptionalFields && (
            <Box
              mt={captionDisplay ? 0 : -1}
              fontSize={11}
              visibility={captionDisplay ? 'visible' : 'hidden'}
            >
              {props.expanded
                ? translatedTextsObject.hideOptionalFields
                : translatedTextsObject.showOptionalFields}
            </Box>
          )}
        </Box>
      </Button>
    </Grid>
  )
}

export default AccordionFooterControlComponent
