import React from 'react'
import { components } from 'cng-web-lib'
import {Card, CardContent, Grid} from '@material-ui/core'
import TranslationText from './TranslationText'
import QuotationUrls from "../../../apiUrls/cec-admin/quotation/QuotationUrls"
import SCOAdminApiUrls from "../../../apiUrls/SCOAdminApiUrls"

const {
    table: {
        DateRangeFilter: CngDateRangeFilter,
        CngCrudTable,
        useDefaultNotification,
        useFetchCustomLookup,
    }
} = components

function TablePage(props) {

    const fetchCustomLookup = useFetchCustomLookup()
    const {
        location: { pathname },
        showNotification
    } = props
    const notification = useDefaultNotification(showNotification)
    const translatedTextObject = TranslationText()
    const columns = [
        {
            field: 'partyId',
            title: translatedTextObject.party,
            customLookup: () => {
                return fetchCustomLookup(
                    `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
                    { },
                    'content',
                    'name',
                    'id',
                    (error) => console.error(error)
                )
            }
        },
        {
            field: 'requestUuid',
            title: translatedTextObject.uuid
        },
        {
            field: 'status',
            title: translatedTextObject.status,
            customLookup: () => {
                return fetchCustomLookup(
                    `${SCOAdminApiUrls.GET_CODE_MASTER_LIST}`,
                    {
                        codeType: 'RATE_QUOTATION_STATUS'
                    },
                    'content',
                    'name',
                    'id',
                    (error) => notification.error(error.message)
                )
            }
        },
        {
            field: 'createdDate',
            title: translatedTextObject.createdAt,
            type: 'datetime',
            filterComponent: CngDateRangeFilter,
        },
        {
            field: 'updatedDate',
            title: translatedTextObject.updatedAt,
            type: 'datetime',
            filterComponent: CngDateRangeFilter,
        },
    ]

    return (
        <Card>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <CngCrudTable
                            {...props}
                            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                            columns={columns}
                            disabled={false}
                            showRefreshButton={false}
                            fetch={{ url: QuotationUrls.QUOTATION_GET }}
                            idAccessor='id'
                            notification={notification}
                            viewRoute={`${pathname}/view`}
                            editRoute={`${pathname}/edit`}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default TablePage

