import React from 'react';
import { constants, useTranslation } from 'cng-web-lib'

import Namespace from "../../../constants/locale/Namespace";
import CecPostcodeConfigKeys from 'src/constants/locale/key/CecPostcodeConfig'

const NAMESPACE = Namespace.CEC_POSTCODE_CONFIG;

const {
    locale: {
        key: {
            UiComponentKeys,
        },
    },
} = constants

const TranslationText = () => {

    const { translate } = useTranslation([NAMESPACE])

    const moduleTitle = translate(NAMESPACE, CecPostcodeConfigKeys.CEC_POSTCODE_CONFIG_TITLE);

    const country = translate(NAMESPACE, CecPostcodeConfigKeys.CEC_POSTCODE_CONFIG_COUNTRY)
    const regex = translate(NAMESPACE, CecPostcodeConfigKeys.CEC_POSTCODE_CONFIG_REGEX)

    let cecPostcodeConfig = translate(NAMESPACE, CecPostcodeConfigKeys.CEC_POSTCODE_CONFIG_TITLE)


    return {
        moduleTitle,
        country,
        regex,
        cecPostcodeConfig
    }
}

export default TranslationText;


