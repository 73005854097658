import React from 'react'
import { Yup } from 'cng-web-lib'

const ValidationSchema = () => {

  return Yup.object({
    name: Yup.string().required('Required/Mandatory').max(255, 'Name cannot be more than 255 characters.'),
    code: Yup.string().required('Required/Mandatory').max(26, 'Code cannot be more than 26 characters.'),
    endpoint: Yup.string().required('Required/Mandatory.').max(255, 'URL cannot be more than 266 characters.')
  })
}

export default ValidationSchema

