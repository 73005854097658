import React from 'react'
import { Grid, Card, CardContent } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import CalistaAdvisoryKey from 'src/constants/locale/key/CalistaAdvisory'
import makeValidationSchema from './CiaFtaMapping_MakeValidationSchema'

const {
	form: { field: { CngSwitchField, CngTextField } },
	CngGridItem,
} = components

//* Field default values -------------------------------------------------------
const DEFAULT_INITIAL_VALUES = Object.freeze({
	importCountry: "",
	exportCountry: "",
	ftaNameTmp: "",
	ftaNameAmberroad: "",
})

//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
	initialValues: { ...DEFAULT_INITIAL_VALUES },
	makeValidationSchema: makeValidationSchema
}

//* Fields function ------------------------------------------------------------
function Fields({ disabled, isNotEditable, showNotification, shouldHideMap, id }) {

	const { translate } = useTranslation(Namespace.CALISTA_ADVISORY)
	const translatedTextsObject = makeTranslatedTextsObject()

	//* Translation function ---------------------------------------------------
	function makeTranslatedTextsObject() {

		// fields
        let importCountry = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.IMPORT_COUNTRY)
        let exportCountry = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.EXPORT_COUNTRY)
		let ftaNameTmp = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.FTA_TMP)
		let ftaNameAmberroad = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.FTA_AMBERROAD)

		return {
			importCountry,
			exportCountry,
			ftaNameTmp,
			ftaNameAmberroad
		}
	}

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Card>
					<CardContent>
						<Grid container spacing={3}>
							<CngGridItem xs={12} shouldHide={shouldHideMap.importCountry}>
								<CngTextField name="importCountry"
									label={translatedTextsObject.importCountry}
									disabled={disabled || isNotEditable}
								/>
							</CngGridItem>

							<CngGridItem xs={12} shouldHide={shouldHideMap.exportCountry}>
								<CngTextField name="exportCountry"
									label={translatedTextsObject.exportCountry}
									disabled={disabled || isNotEditable}
								/>
							</CngGridItem>

							<CngGridItem xs={12} shouldHide={shouldHideMap.ftaNameTmp}>
								<CngTextField name="ftaNameTmp"
									label={translatedTextsObject.ftaNameTmp}
									disabled={disabled}
								/>
							</CngGridItem>

							<CngGridItem xs={12} shouldHide={shouldHideMap.ftaNameAmberroad}>
								<CngTextField name="ftaNameAmberroad"
									label={translatedTextsObject.ftaNameAmberroad}
									disabled={disabled}
								/>
							</CngGridItem>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
		//* End JSX ------------------------------------------------------------

	)
	//* End of function --------------------------------------------------------
}

function toClientDataFormat(serverData) {
	let localData = serverData;
	return localData;
}

function toServerDataFormat(localData) {
	return localData;
}

const FormProperties = Object.freeze({
	formikProps: FORMIK_PROPS,
	Fields: Fields,
	toClientDataFormat: toClientDataFormat,
	toServerDataFormat: toServerDataFormat
})

export default FormProperties
