import { Yup,constants } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) { 
    let requiredMessage = translate(
      Namespace.COMMON_VALIDATION_MSG,
      CommonValidationMessageKeys.REQUIRED
    )
  return Yup.object({
    salutation: Yup.string().nullable(),
    firstName: Yup.string().required(requiredMessage).matches(/^[a-zA-Z0-9-., ]+$/,"Cannot contain special characters like )(@*:etc.").max(255,"Max length allowed is 15").min(1,"Min length required is 1").nullable(),
    lastName: Yup.string().required(requiredMessage).matches(/^[a-zA-Z0-9-., ]+$/,"Cannot contain special characters like )(@*:etc.").max(255,"Max length allowed is 15").min(1,"Min length required is 1").nullable(),
    email:  Yup.string().trim().email("Please enter a valid email address!").max(255, "Max length allowed is 255.").min(5,"Min length required is 5").required(requiredMessage),
    phone: Yup.lazy((value) => value === '' ? Yup.string() : Yup.string().nullable().max(255,"Max 255 characters are allowed").min(5,"Min 5 characters are allowed").matches("^[0-9]*$", "Phone enter valid phone number")),
    designation: Yup.string().matches(/^[a-zA-Z0-9-., ]+$/,"Cannot contain special characters like )(@*:etc.").max(255,"Max length allowed is 255").nullable(),
    isPrimary: Yup.boolean().nullable()
  })
}

export default makeValidationSchema
