import React, { useState } from 'react'
import { Step, StepLabel, Stepper } from '@material-ui/core'

import StyledStepIcon from 'src/views/vesselschedule/searchschedule/cngcomponent/StyledStepIcon'

function GeneralStepperComponent(props) {
  const steps = props.steps

  return (
    <Stepper activeStep={props.activeStep}>
      {steps.map((label, index) => {
        const stepProps = {}
        const labelProps = {}
        return (
          <Step key={label} {...stepProps}>
            <StepLabel
              StepIconComponent={StyledStepIcon}
              StepIconProps={{ Icon: Step.icon }}
              {...labelProps}
            >
              {label}
            </StepLabel>
          </Step>
        )
      })}
    </Stepper>
  )
}

export default GeneralStepperComponent
