import { Card, CardContent, Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'
import { default as React } from 'react'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import CommonCityTranslationText from './CommonCityTranslationText'
import SCOAdminApiUrls from 'src/apiUrls/SCOAdminApiUrls'

const {
    form: {
        field: {
            CngTextField,
            CngLookupAutocompleteField
        },
    },
    CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = {
    id: "",
    countryId: "",
    commonCityName: "",
    locCode: ""
}
//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES }
}

//* Fields function ------------------------------------------------------------
function Fields({ ...props }) {
    const { disabled } = props;
    const fieldsTranslatedTextObject = CommonCityTranslationText()

    return (
        <>
            <Grid container spacing={3}>
                <CngGridItem xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container>
                                <AccordionHeaderComponent title={fieldsTranslatedTextObject.title} />
                                <Grid container spacing={2}>
                                    <CngGridItem xs={12} sm={4} >
                                        <CngLookupAutocompleteField
                                            name='countryId'
                                            label={fieldsTranslatedTextObject.countryCode}
                                            disabled={disabled}
                                            required
                                            lookupProps={{
                                                url: SCOAdminApiUrls.GET_COUNTRY_LIST,
                                                label: 'code',
                                                value: 'id'
                                            }}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={4} >
                                        <CngTextField
                                            required
                                            name='commonCityName'
                                            label={fieldsTranslatedTextObject.commonCityName}
                                            disabled={disabled}
                                        />
                                    </CngGridItem>

                                    <CngGridItem xs={12} sm={4} >
                                        <CngTextField
                                            required
                                            name='locCode'
                                            label={fieldsTranslatedTextObject.locCode}
                                            disabled={disabled}
                                        />
                                    </CngGridItem>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </CngGridItem>
            </Grid>
        </>
    )
}

function toClientDataFormat(serverData) {
    return serverData;
}

function toServerDataFormat(localData) {
    return localData;
}

const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties
