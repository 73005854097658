import ConnectorTranslationText from '../ConnectorTranslationText'
import { Card, CardContent, Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'
import FormProperties from './ConnectorMappingsFormProperties'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import ConnectorMappingApiUrls from '../../../../apiUrls/cec-admin/connector-config/ConnectorMappingApiUrls'
import AccordionHeaderComponent from '../../../common/ui/AccordionHeaderComponent'
import MappingItemCrudTable from '../mapping-items/CrudTable'


const {
  button: {
    CngSecondaryButton
  }, form: {
    CngEditForm, CngEditFormButtonSection
  }, CngGridItem
} = components

function EditPage({ history, showNotification }) {

  const { mappingId } = useParams()
  const [mappingName, setMappingName] = useState('')

  const {
    connectorMappingItems
  } = ConnectorTranslationText()

  return (

    <Grid container spacing={1}>
      <CngGridItem item xs={12}>

        <Card>
          <CardContent>

            <CngEditForm
              fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
              showNotification={showNotification}
              renderBodySection={() =>
                <FormProperties.Fields
                  disabled={false}
                  showNotification={showNotification}
                  id={mappingId}
                />
              }
              renderButtonSection={() => (<CngEditFormButtonSection>
                  <CngGridItem>
                    <CngSecondaryButton
                      onClick={() => {
                        history.goBack()
                      }}>
                      Back
                    </CngSecondaryButton>
                  </CngGridItem>
                </CngEditFormButtonSection>

              )}
              formikProps={{
                ...FormProperties.formikProps
              }}
              toClientDataFormat={(serverData) => {
                setMappingName(serverData['name'])
                return serverData
              }}
              fetch={{
                url: `${ConnectorMappingApiUrls.GET}`,
                body: {
                  'id': mappingId
                }
              }}
              update={{
                url: `${ConnectorMappingApiUrls.UPDATE}`
              }}
              id={mappingId}
            />

          </CardContent>
        </Card>

      </CngGridItem>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container>
              <AccordionHeaderComponent
                title={connectorMappingItems} hideMandatory />

            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12}>

                <MappingItemCrudTable
                  mappingId={parseInt(mappingId)}
                  isViewOnly={false}
                  key={mappingName}
                  showNotification={showNotification}
                  mappingName={mappingName}
                />

              </Grid>
            </Grid>

          </CardContent>
        </Card>
      </Grid>
    </Grid>)

}

export default EditPage