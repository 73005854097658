import AddPage from './AddPage.js'
import { BasePage as CngBasePage } from 'cng-web-lib'
import EditPage from './EditPage.js'
import React from 'react'
import ViewPage from './ViewPage.js'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_CECADMIN'
import ConnectorTranslationText from '../ConnectorTranslationText'


function ModuleTitle() {
  const translatedTextsObject = ConnectorTranslationText()
  return translatedTextsObject.connectorMappings
}

function WrappedAddPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      moduleTitle={ModuleTitle()}
      renderPage={(showSnackbar) => (
        <AddPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedEditPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      moduleTitle={ModuleTitle()}
      renderPage={(showSnackbar) => (
        <EditPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedViewPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      moduleTitle={ModuleTitle()}
      renderPage={(showSnackbar) => (
        <ViewPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export {
  WrappedAddPage as AddPage,
  WrappedEditPage as EditPage,
  WrappedViewPage as ViewPage
}