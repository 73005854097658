import React from 'react'
import { Card, CardContent, Divider, Grid } from '@material-ui/core'
import { components, constants, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import CalistaAdvisoryPartyConfigApiUrls from 'src/apiUrls/CalistaAdvisoryPartyConfigApiUrls'
import CalistaAdvisoryKey from 'src/constants/locale/key/CalistaAdvisory'

const {
    card: { CngSimpleCardHeader},
    table: { CngCrudTable, useDefaultNotification}
} = components
const { locale: {key: {UiComponentKeys} } } = constants

function TablePage(props){

    const { location: { pathname }, showNotification } = props
    const notification = useDefaultNotification(showNotification)
    const { translate} = useTranslation([
        Namespace.UI_COMPONENT, Namespace.CALISTA_ADVISORY
    ])
    const translatedTextsObject = makeTranslatedTextsObject()

    function makeTranslatedTextsObject(){
        let title = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.SUB_MODULE_TITLE.CALISTA_ADVISORY_PARTY_CONFIG)

        let tableTitle = translate(
            Namespace.UI_COMPONENT,
            UiComponentKeys.Table.TITLE,
            { nameTitleised: title}
        )

        // columns
        let partyName = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.PARTY_NAME)

        return {
            tableTitle,
            partyName
        }
    }

    //* Table Columns
    const columns = [
        {field: 'name', title: translatedTextsObject.partyName}
    ];

    //* return JSX
    return (

        //* Start JSX
        <Card>
            <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
            <Divider/>
            <CardContent>
                <Grid container spacing = {3}>
                    <Grid item xs={12}>
                        <CngCrudTable {...props}
                            notification = {notification}

                            // table fetch and data details
                            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                            fetch={{ url: CalistaAdvisoryPartyConfigApiUrls.GET_FROM_TPR_PARTY}}
                            idAccessor='id'
                            columns={columns}

                            // view route
                            viewRoute={`${pathname}/view`}

                            // edit route
                            editRoute={`${pathname}/edit`}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        //* End JSX
    );
    //* End of function
}

export default TablePage