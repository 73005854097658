import React from 'react'
import { components } from 'cng-web-lib'
import FormProperties from './FormProperties'
import QuotationUrls from "../../../apiUrls/cec-admin/quotation/QuotationUrls";
import {useParams} from "react-router-dom";

const { form: { CngViewForm } } = components

function ViewForm({ showNotification }) {
    const { id } = useParams()

    return (

        //* Start JSX ----------------------------------------------------------
        <CngViewForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            showNotification={showNotification}
            bodySection={
                <FormProperties.Fields
                    disabled={true}
                    showNotification={showNotification}
                    edit={true}
                />
            }
            formikProps={FormProperties.formikProps}
            toClientDataFormat={FormProperties.toClientDataFormat}
            toServerDataFormat={FormProperties.toServerDataFormat}
            fetch={{
                url: QuotationUrls.QUOTATION_GET,
                id: id
            }}
        />
        //* End JSX ------------------------------------------------------------
    )

}

export default ViewForm
