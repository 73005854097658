import React from 'react'
import Namespace from '../../../../constants/locale/Namespace'
import ShipmentProviderKeys from '../../../../constants/locale/key/cec/ShipmentProviderKeys'
import { useTranslation } from 'cng-web-lib'

const NAMESPACE = Namespace.SHIPMENT_PROVIDERS


const TranslationText = () => {

  const { translate } = useTranslation([NAMESPACE])

  //form fields
  const partyName = translate(NAMESPACE, ShipmentProviderKeys.PARTY_NAME)
  const allowedParties = translate(NAMESPACE, ShipmentProviderKeys.ALLOWED_PARTIES)


  return {
    partyName,
    allowedParties
  }
}

export default TranslationText