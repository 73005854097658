import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  DataFlattener
} from 'cng-web-lib'

import CecPostcodeConfigApiUrls from 'src/apiUrls/CecPostcodeConfigApiUrls'
import SCOAdminApiUrls from 'src/apiUrls/SCOAdminApiUrls'
import React, { useRef } from 'react'
import TranslationText from './TranslationText'

const {
  table: {
    CngCrudTable,
    useDefaultNotification,
    useFetchCustomLookup

  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  },
} = constants

function TablePage(props,
  orderBy,
  orderDirection) {
  console.log('table page')
  const {
    location: { pathname },
    showNotification
  } = props
  const notification = useDefaultNotification(showNotification)
  const translatedTextsObject = TranslationText();
  const fetchCustomLookup = useFetchCustomLookup();
  const cngTableRef = useRef()

  const columns = [
    {
      field: "country",
      title: translatedTextsObject.country,
      customLookup: () => {
        return fetchCustomLookup(
          `${SCOAdminApiUrls.GET_COUNTRY_LIST}`,
          {
          },
          'content',
          'name', // label accessor
          'code', // value accessor
          (error) => console.error(error)
        )
      },
      defaultSort: 'asc',
    },
    {
      field: "regex",
      title: translatedTextsObject.regex,
      filtering: false,
    }
  ]


  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              columns={columns}
              idAccessor="id"
              notification={notification}
              addRoute={`${pathname}/add`}
              viewRoute={`${pathname}/view`}
              editRoute={`${pathname}/edit`}
              del={{ url: CecPostcodeConfigApiUrls.DELETE_POSTCODE_CONFIG }}
              fetch={{ url: CecPostcodeConfigApiUrls.GET_POSTCODE_CONFIG }}
              cngTaviewRoutebleRef={cngTableRef}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )

}

function toServerDataFormat(localData) {
  const reqData = {
    "data": [{
      "country": localData.country,
      "regex": localData.regex
    }]
  }
  return DataFlattener.unflatten(reqData);
}

export default TablePage
