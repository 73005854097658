import PartyInformationFormProperties from './FormProperties'
import ShipmentProviderDefaultPartyUrls
  from '../../../../../apiUrls/cec-admin/shipment-provider/ShipmentProviderDefaultPartyUrls'
import React from 'react'
import { components, useServices } from 'cng-web-lib'

const {
  form: {
    CngViewForm
  }
} = components

function ViewForm({
                    showNotification, shipmentProviderId, partyType
                  }) {

  const { fetchRecords } = useServices()

  return (

    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      showNotification={showNotification}
      renderBodySection={() => <PartyInformationFormProperties.Fields
        disabled={true}
        showNotification={showNotification}
        partyType={partyType}
      />}
      formikProps={{
        ...PartyInformationFormProperties.formikProps
      }}
      toClientDataFormat={PartyInformationFormProperties.toClientDataFormat}
      toServerDataFormat={PartyInformationFormProperties.toServerDataFormat}
      fetch={{
        onSendRequest: (onSuccess, onError, onComplete) => {
          fetchRecords.execute(ShipmentProviderDefaultPartyUrls.GET_BY_SHIPMENT_PROVIDER_ID_AND_PARTY_TYPE,
            {
              customData: {
                'shipmentProviderId': shipmentProviderId,
                'partyType': partyType
              }
            },
            onSuccess, onError, onComplete)
        }
      }}
    />
  )

}

export default ViewForm