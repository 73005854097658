import React from 'react'
import PropTypes from 'prop-types'
import { components } from 'cng-web-lib'
import TranslationText from './TranslationText'
import Urls from '../../../../apiUrls/cec-admin/shipment-provider/ShipmentProviderConnectorEndpointVariableUrls'
import FormProperties from './FormProperties'

const {
  table: {
    CngServerModeDialogFormTable, useDefaultNotification
  }
} = components


const CrudTable = ({ mappingId, isViewOnly, showNotification }) => {

  const { variableName, variableValue } = TranslationText()
  const { GET, CREATE, UPDATE, DELETE } = Urls(mappingId)

  const columnsOfTable = [{
    field: 'name', title: variableName
  }, {
    field: 'value', title: variableValue
  }]


  return (

    <CngServerModeDialogFormTable
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      disabled={isViewOnly}
      showNotification={showNotification}
      tableColumns={columnsOfTable}
      pageSize={10}
      formProperties={{
        ...FormProperties, formikProps: {
          ...FormProperties.formikProps, initialValues: {
            ...FormProperties.formikProps.initialValues, mappingId: mappingId
          }
        }
      }}
      toClientDataFormat={(serverData) => {
        return serverData
      }}
      toServerDataFormat={(localDatum) => {
        return localDatum
      }}
      fetch={{
        url: GET, body: {
          mappingId: mappingId
        }
      }}
      create={{
        url: CREATE, body: {
          mappingId: mappingId
        }
      }}
      update={{ url: UPDATE }}
      del={{ url: DELETE }}
      idAccessor='id'
    />


  )
}

CrudTable.propTypes = {
  showNotification: PropTypes.func, shipmentProviderEndpointMappingId: PropTypes.number, isViewOnly: PropTypes.bool
}

export default CrudTable


