import Namespace from 'src/constants/locale/Namespace'
import TradeManagementKeys from 'src/constants/locale/key/TradeManagement'
import { useTranslation } from 'cng-web-lib'

const CommonFtaTranslationText = () => {
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.TRADE_MANAGEMENT
  ])

  // Button labels
  let btnBackToCalculator = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.ButtonLabels.BTN_BACK_TO_CALCULATOR
  )

  let btnBackToCalculationResults = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.ButtonLabels.BTN_BACK_TO_CALCULATION_RESULTS
  )

  let btnBackToSearchActivityHistory = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.ButtonLabels.BTN_BACK_TO_SEARCH_ACTIVITY_HISTORY
  )

  let btnCalculate = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.ButtonLabels.BTN_CALCULATE
  )

  let btnClearFields = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.ButtonLabels.BTN_CLEAR_FIELDS
  )

  let btnGoBack = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.ButtonLabels.BTN_GO_BACK
  )

  let btnSearch = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.ButtonLabels.BTN_SEARCH
  )

  let btnRulesOfOrigin = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.ButtonLabels.BTN_RULES_OF_ORIGIN
  )

  let btnDutySavings = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.ButtonLabels.BTN_DUTY_SAVINGS
  )

  let btnRelatedBoM = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.ButtonLabels.BTN_RELTED_BOM
  )

  // General
  let item = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.General.ITEM
  )

  let refresh = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.General.REFRESH
  )

  let problemRetrieving = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.General.PROBLEM_RETRIEVING
  )

  let noItemsFound = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.General.NO_ITEMS_FOUND
  )

  let adjustSearch = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.General.NO_ADJUST_SEARCH
  )

  let noItemsYet = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.General.NO_ITEMS_YET
  )

  let noItemsSubmitted = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.General.NO_ITEMS_SUBMITTED
  )

  let searchInputPlaceholderLabel = translate(
    Namespace.TRADE_MANAGEMENT,
    TradeManagementKeys.General.SEARCH_INPUT_PLACEHOLDER_LABEL
  )

  return {
    btnBackToCalculator,
    btnBackToCalculationResults,
    btnBackToSearchActivityHistory,
    btnCalculate,
    btnClearFields,
    btnGoBack,
    btnSearch,
    btnRulesOfOrigin,
    btnDutySavings,
    btnRelatedBoM,
    item,
    refresh,
    problemRetrieving,
    noItemsFound,
    adjustSearch,
    noItemsYet,
    noItemsSubmitted,
    searchInputPlaceholderLabel
  }
}

export default CommonFtaTranslationText
