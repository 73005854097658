import React, { useEffect, useRef, useState } from 'react'
import { constants, useServices } from 'cng-web-lib'

import SearchScheduleApiUrls from 'src/apiUrls/SearchScheduleApiUrls.js'

function useFetchCarrier(onSuccess) {
    const { fetchRecords } = useServices();

     // useRef is not able to return value to parent component
     const returnData = useRef([])
    //  returnData.current = [{text : 'No Option', value: ''}]
     // useState will cause multiple call sometime
     // const [returnData, setReturnData] = useState([])

    useEffect(() => {
        fetchRecords.execute(
          SearchScheduleApiUrls.CARRIER_ENROLL_GET,
            { 
              
            },
            (data) => {
              /*data massage here*/
              returnData.current = data.content
              // setReturnData(data.content)

              // use onSuccess passed from parent component to set the response data
              onSuccess(returnData.current)

            },
            // (success) => {
              
            // },
            (error) => {
                console.log('useFetchCarrier : ' + error)
                alert(error)
                // onSuccess(returnData.current)
            }
        )
    }, []);
    return returnData.current;
    // return returnData
};

export default useFetchCarrier;