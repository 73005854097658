import React from 'react'
import { Grid } from '@material-ui/core'
import { components, useTranslation, Yup } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import CiaKeys from 'src/constants/locale/key/CalistaAdvisory'

const {
    form: { field: { CngTextField } },
    CngGridItem,
} = components

//* Field default values -------------------------------------------------------
const DEFAULT_INITIAL_VALUES = Object.freeze({
    rowRef: "",
    targetCountry: "",
    targetClassificationType: "",
    inputText: "",
    status: "",
    statusMessage: "",
    confidenceLevel: "",
    hsCode: "",
    hsDetails: ""
})

//* Front end validations ------------------------------------------------------
function makeValidationSchema(translate) {
    return Yup.object({
    })
}

//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
    makeValidationSchema: makeValidationSchema
}

//* Fields function ------------------------------------------------------------
function Fields({ disabled, showNotification, shouldHideMap, id }) {

    // Translations ------------------------------------------------------------
    const ciaNamespace = Namespace.CALISTA_ADVISORY
    const { translate } = useTranslation(ciaNamespace)

    const CiaFieldKeys = CiaKeys.FIELDS

    const Labels = {

        ROW_REF: translate(ciaNamespace, CiaFieldKeys.ROW_REF),
        COUNTRY: translate(ciaNamespace, CiaFieldKeys.COUNTRY),
        TYPE: translate(ciaNamespace, CiaFieldKeys.TYPE),
        INPUT_TEXT: translate(ciaNamespace, CiaFieldKeys.INPUT_TEXT),
        STATUS: translate(ciaNamespace, CiaFieldKeys.STATUS),
        STATUS_MESSAGE: translate(ciaNamespace, CiaFieldKeys.STATUS_MESSAGE),
        CONFIDENCE: translate(ciaNamespace, CiaFieldKeys.CONFIDENCE),
        HS_CODE: translate(ciaNamespace, CiaFieldKeys.HS_CODE),
        HS_DETAILS: translate(ciaNamespace, CiaFieldKeys.HS_DETAILS)
    }

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container spacing={3}>

            <CngGridItem xs={6} shouldHide={shouldHideMap.id}>
                <CngTextField name="rowRef" label={Labels.ROW_REF} disabled={disabled} />
            </CngGridItem>

            <CngGridItem xs={3} shouldHide={shouldHideMap.targetCountry}>
                <CngTextField name="targetCountry" label={Labels.COUNTRY} disabled={disabled} />
            </CngGridItem>

            <CngGridItem xs={3} shouldHide={shouldHideMap.targetClassificationType}>
                <CngTextField name="targetClassificationType" label={Labels.TYPE} disabled={disabled} />
            </CngGridItem>

            <CngGridItem xs={12} shouldHide={shouldHideMap.inputText}>
                <CngTextField name="inputText" label={Labels.INPUT_TEXT} disabled={disabled} />
            </CngGridItem>

            <CngGridItem xs={3} shouldHide={shouldHideMap.status}>
                <CngTextField name="status" label={Labels.STATUS} disabled={disabled} />
            </CngGridItem>

            <CngGridItem xs={9} shouldHide={shouldHideMap.statusMessage}>
                <CngTextField name="statusMessage" label={Labels.STATUS_MESSAGE} disabled={disabled} />
            </CngGridItem>

            <CngGridItem xs={3} shouldHide={shouldHideMap.confidenceLevel}>
                <CngTextField name="confidenceLevel" label={Labels.CONFIDENCE} disabled={disabled} />
            </CngGridItem>

            <CngGridItem xs={9} shouldHide={shouldHideMap.hsCode}>
                <CngTextField name="hsCode" label={Labels.HS_CODE} disabled={disabled} />
            </CngGridItem>

            <CngGridItem xs={12} shouldHide={shouldHideMap.hsDetails}>
                <CngTextField multiline
                    name="hsDetails" label={Labels.HS_DETAILS} disabled={disabled}
                />
            </CngGridItem>

        </Grid>

        //* End JSX ------------------------------------------------------------
    )
    //* End of function --------------------------------------------------------
}

function toClientDataFormat(serverData) {
    let localData = serverData;
    return localData;
}

function toServerDataFormat(localData) {
    return localData;
}

const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties
