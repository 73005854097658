import { Card, CardContent, Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'
import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import AccordionHeaderComponent from '../../../common/ui/AccordionHeaderComponent'
import CrudTable from '../connector-variables/CrudTable'
import ConnectorVariablesTranslationText from '../connector-variables/TranslationText'
import ConnectorMappingViewForm from './forms/ViewForm'

const {
  CngGridItem
} = components

function ViewPage({ showNotification }) {

  const { mappingId } = useParams()
  const location = useLocation()
  const connectorRecord = location.state.connectorRecord
  const connectorType = location.state.connectorType

  const {
    variables
  } = ConnectorVariablesTranslationText()

  return (

    <Grid container spacing={1}>
      <CngGridItem item xs={12}>

        <Card>
          <CardContent>

            <ConnectorMappingViewForm
              showNotification={showNotification}
              connectorRecord={connectorRecord}
              connectorType={connectorType}
              mappingId={mappingId}
            />

          </CardContent>
        </Card>

      </CngGridItem>

      <Grid item xs={12}>
        <Card>
          <CardContent>

            <Grid container>
              <AccordionHeaderComponent
                title={variables} hideMandatory />

            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12}>

                <CrudTable
                  mappingId={parseInt(mappingId)}
                  isViewOnly={true}
                  showNotification={showNotification}
                />

              </Grid>
            </Grid>

          </CardContent>
        </Card>
      </Grid>
    </Grid>)

}

export default ViewPage