import React from 'react'
import { 
    Grid,
} from '@material-ui/core'

import makeValidationSchema from '../../shared/MakeValidationSchema'
import SourceCountryList from '../../components/SourceCountryList'
import DestinationCountryList from '../../components/DestinationCountryList'

import InfoAlert from 'src/components/alert/InfoAlert'
import HsCodeList from '../../components/HsCodeList'

//* Field default values -------------------------------------------------------
const DEFAULT_INITIAL_VALUES = Object.freeze({
    ...SourceCountryList.initialValues,
    ...DestinationCountryList.initialValues,
    ...HsCodeList.initialValues,
})

const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
    makeValidationSchema: makeValidationSchema
}

//* Fields function
function Fields({ id, disabled }) {
    //* return JSX
    return (

        //* Start JSX
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <InfoAlert title="List Rule Types">
                    <br />
                    <b>ALLOW</b> = Only allow items in the list. <br />
                    <b>DENY</b> = Allow ALL, except items in the list.
                </InfoAlert>
            </Grid>

            <Grid item xs={12}>
                <SourceCountryList.FormBody id={id} disabled={disabled} />
            </Grid>

            <Grid item xs={12}>
                <DestinationCountryList.FormBody id={id} disabled={disabled} />
            </Grid>

            <Grid item xs={12}>
                <HsCodeList.FormBody id={id} disabled={disabled} />
            </Grid>

        </Grid>
        //* End JSX
    )
    //* End of function
}

function toClientDataFormat(serverData) {
    return serverData;
}

function toServerDataFormat(localData) {
    return localData;
}

const ProductListFormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default ProductListFormProperties