import React from 'react'
import { 
    Grid,
    Divider,
    Typography,
} from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import CalistaAdvisoryKey from 'src/constants/locale/key/CalistaAdvisory'
import HsCodeCrudTable from './HsCodeCrudTable'

const {
    form: { field: { CngSelectField } },
    CngGridItem
} = components

//* Field default values -------------------------------------------------------
const initialValues = Object.freeze({
    hsCodeListRuleType: "ALLOW",
})

const FormBody = ({ id, disabled }) => {
    const { translate } = useTranslation(Namespace.CALISTA_ADVISORY)
    const translatedTextsObject = makeTranslatedTextsObject()

    //* Translation function
    function makeTranslatedTextsObject() {
        let hsCodeListRuleType = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.HS_CODE_LIST_RULE_TYPE)

        return {
            hsCodeListRuleType,
        }
    }

    //* return JSX
    return (
        
        //* Start JSX
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Divider />
            </Grid>

            <Grid item xs={12}>
                <Typography variant='h5' display="block" className='font-bolder'>
                    HS Code List
                </Typography>
            </Grid>

            <CngGridItem xs={12} sm={6}>
                <CngSelectField name="hsCodeListRuleType"
                    label={translatedTextsObject.hsCodeListRuleType}
                    disabled={disabled}
                    items={[
                        { text: 'ALLOW', value: 'ALLOW' },
                        { text: 'DENY', value: 'DENY' }
                    ]}
                />
            </CngGridItem>

            <Grid item xs={12}>
                <HsCodeCrudTable partyId={id} disabled={disabled}/>
            </Grid>
        </Grid>
        //* End JSX
    )
    //* End of function
}
const HsCodeList = Object.freeze({
    initialValues: initialValues,
    FormBody: FormBody
})

export default HsCodeList