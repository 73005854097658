import React from 'react'
import ValidationSchema from './ValidationSchema'
import { components, constants } from 'cng-web-lib'
import ConnectorTranslationText from '../ConnectorTranslationText'
import { Card, CardContent, Grid } from '@material-ui/core'
import AccordionHeaderComponent from '../../../common/ui/AccordionHeaderComponent'
import SCOAdminApiUrls from '../../../../apiUrls/SCOAdminApiUrls'


const {
  form: {
    field: {
      CngTextField, CngLookupAutocompleteField
    }
  },
  CngGridItem
} = components

const {
  filter: {
    EQUAL
  }
} = constants


function Fields({ ...props }) {

  const { disabled } = props
  const {
    connectorCode,
    connectorDetails,
    connectorName,
    connectorServiceURL,
    connectorAppType
  } = ConnectorTranslationText()


  return (
    <>
      <Grid container spacing={3}>
        <CngGridItem xs={12} sm={12}>
          <Card>
            <CardContent>
              <Grid container>

                <AccordionHeaderComponent
                  title={connectorDetails} />

                <Grid container spacing={2}>
                  <CngGridItem xs={12} sm={6}>
                    <CngTextField
                      required
                      name='name'
                      label={connectorName}
                      disabled={disabled}
                    />

                  </CngGridItem>


                  <CngGridItem xs={12} sm={6}>

                    <CngLookupAutocompleteField
                      name='type'
                      label={connectorAppType}
                      disabled={disabled}
                      lookupProps={{
                        url: SCOAdminApiUrls.GET_CODE_MASTER_LIST,
                        label: 'name',
                        value: 'id',
                        filters: [
                          {
                            field: 'codeType',
                            operator: EQUAL,
                            value: 'CEC_CONNECTOR_APP_TYPE'
                          }
                        ]
                      }}
                    />

                  </CngGridItem>

                  <CngGridItem xs={12} sm={6}>
                    <CngTextField
                      required
                      name='code'
                      label={connectorCode}
                      disabled={disabled}
                    />
                  </CngGridItem>


                  <CngGridItem xs={12} sm={6}>
                    <CngTextField
                      required
                      name='webserviceUrl'
                      label={connectorServiceURL}
                      disabled={disabled}
                    />
                  </CngGridItem>

                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </CngGridItem>
      </Grid>
    </>

  )
}


function toClientDataFormat(serverData) {
  return serverData
}

function toServerDataFormat(localData) {
  return localData
}

const DEFAULT_INITIAL_VALUES = {
  name: '',
  code: '',
  webserviceUrl: '',
  type: ''
}

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: ValidationSchema
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties