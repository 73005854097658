import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  DataFlattener
} from 'cng-web-lib'

import CecDoUomMappingApiUrls from 'src/apiUrls/CecDoUomMappingApiUrls'
import SCOAdminApiUrls from 'src/apiUrls/SCOAdminApiUrls'
import React, { useRef } from 'react'
import TranslationText from './TranslationText'

const {
  table: {
    CngCrudTable,
    useDefaultNotification,
    useFetchCustomLookup

  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  },
} = constants

function TablePage(props) {

  const {
    location: { pathname },
    showNotification
  } = props
  const notification = useDefaultNotification(showNotification)
  const translatedTextsObject = TranslationText();
  const fetchCustomLookup = useFetchCustomLookup();
  const cngTableRef = useRef()

  const columns = [
    {
      field: "weightUom",
      title: translatedTextsObject.weightUom,
      customLookup: () => {
        return fetchCustomLookup(
          `${SCOAdminApiUrls.GET_CODE_MASTER_LIST}`,
          {
            "codeType": "COM_UOM_WEGH",
          },
          'content',
          'name', // label accessor
          'code', // value accessor
          (error) => console.error(error)
        )
      }
    },
    {
      field: "volumetricWeightUom",
      title: translatedTextsObject.volumetricWeightUom,
      customLookup: () => {
        return fetchCustomLookup(
          `${SCOAdminApiUrls.GET_CODE_MASTER_LIST}`,
          {
            "codeType": "COM_UOM_VOLW",
          },
          'content',
          'name', // label accessor
          'code', // value accessor
          (error) => console.error(error)
        )
      }
    },
    {
      field: "packageDimensionUom",
      title: translatedTextsObject.packageDimensionUom,
      customLookup: () => {
        return fetchCustomLookup(
          `${SCOAdminApiUrls.GET_CODE_MASTER_LIST}`,
          {
            "codeType": "COM_UOM_DIMN",
          },
          'content',
          'name', // label accessor
          'code', // value accessor
          (error) => console.error(error)
        )
      }
    },
  ]

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              columns={columns}
              idAccessor="id"
              notification={notification}
              addRoute={`${pathname}/add`}
              viewRoute={`${pathname}/view`}
              editRoute={`${pathname}/edit`}
              del={{ url: CecDoUomMappingApiUrls.DELETE_DO_UOM_MAPPING }}
              fetch={{ url: CecDoUomMappingApiUrls.GET_DO_UOM_MAPPING }}
              cngTaviewRoutebleRef={cngTableRef}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )

}

function toServerDataFormat(localData) {
  const reqData = {
    "data": [{
      "weightUom": localData.weightUom,
      "volumetricWeightUom": localData.volumetricWeightUom,
      "packageDimensionUom": localData.packageDimensionUom,
    }]
  }
  return DataFlattener.unflatten(reqData);
}

export default TablePage
