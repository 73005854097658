import React from 'react'
import { Grid, Card, CardContent } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import CalistaAdvisoryKey from 'src/constants/locale/key/CalistaAdvisory'
import makeValidationSchema from './CiaSysConfig_MakeValidationSchema'

const {
	form: { field: { CngTextField } },
	CngGridItem,
} = components

//* Field default values -------------------------------------------------------
const DEFAULT_INITIAL_VALUES = Object.freeze({
	groupName: "",
	name: "",
	displayName: "",
	value: "",
	description: "",
	remarks: ""
})

//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
	initialValues: { ...DEFAULT_INITIAL_VALUES },
	makeValidationSchema: makeValidationSchema
}

//* Fields function ------------------------------------------------------------
function Fields({ disabled, showNotification, shouldHideMap, id }) {

	const { translate } = useTranslation(Namespace.CALISTA_ADVISORY)
	const translatedTextsObject = makeTranslatedTextsObject()

	//* Translation function ---------------------------------------------------
	function makeTranslatedTextsObject() {

		// fields
		let groupName = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.CONFIG_GROUP)
		let configCode = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.CONFIG_CODE)
		let displayName = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.DISPLAY_NAME)
		let value = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.VALUE)
		let description = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.DESCRIPTION)
		let remarks = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.REMARKS)

		return {
			groupName,
			configCode,
			displayName,
			value,
			description,
			remarks
		}
	}

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Card>
					<CardContent>
						<Grid container spacing={3}>

							<CngGridItem xs={12} shouldHide={shouldHideMap.groupName}>
								<CngTextField name="groupName"
									label={translatedTextsObject.groupName}
									disabled={true}
								/>
							</CngGridItem>

							<CngGridItem xs={12} shouldHide={shouldHideMap.name}>
								<CngTextField name="name"
									label={translatedTextsObject.configCode}
									disabled={true}
								/>
							</CngGridItem>

							<CngGridItem xs={12} shouldHide={shouldHideMap.displayName}>
								<CngTextField name="displayName"
									label={translatedTextsObject.displayName}
									disabled={true}
								/>
							</CngGridItem>

							<CngGridItem xs={12} shouldHide={shouldHideMap.value}>
								<CngTextField name="value"
									label={translatedTextsObject.value}
									disabled={disabled}
									multiline={true}
								/>
							</CngGridItem>

							<CngGridItem xs={12} shouldHide={shouldHideMap.description}>
								<CngTextField name="description"
									label={translatedTextsObject.description}
									multiline={true}
									disabled={true}
								/>
							</CngGridItem>

							<CngGridItem xs={12} shouldHide={shouldHideMap.remarks}>
								<CngTextField name="remarks"
									label={translatedTextsObject.remarks}
									disabled={disabled}
									multiline={true}
								/>
							</CngGridItem>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
		//* End JSX ------------------------------------------------------------

	)
	//* End of function --------------------------------------------------------
}

function toClientDataFormat(serverData) {
	let localData = serverData;
	return localData;
}

function toServerDataFormat(localData) {
	return localData;
}

const FormProperties = Object.freeze({
	formikProps: FORMIK_PROPS,
	Fields: Fields,
	toClientDataFormat: toClientDataFormat,
	toServerDataFormat: toServerDataFormat
})

export default FormProperties
