import React from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Card, Divider, CardContent } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import CalistaAdvisoryKey from 'src/constants/locale/key/CalistaAdvisory'
import PartySummaryViewForm from './CiaPartyConfig_PartySummaryViewForm'
import CiaConfigViewForm from './tabs/CiaConfig/CiaPartyConfig_CiaConfigViewForm'
import ProductListViewForm from './tabs/ProductList/CiaPartyConfig_ProductListViewForm'

const { card: { CngSimpleCardHeader },
		CngTabs
} = components

function ViewPage({ showNotification }) {

	const { id } = useParams()
	const { translate } = useTranslation([Namespace.CALISTA_ADVISORY])
	const translatedTextsObject = makeTranslatedTextsObject()

	//* Translation function ---------------------------------------------------
	function makeTranslatedTextsObject() {

		let title = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.SUB_MODULE_TITLE.CALISTA_ADVISORY_PARTY_CONFIG)
		let partySummarySectionHeader = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.FIELDS.PARTY_SUMMARY)
		let partyConfigSectionHeader = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.SUB_MODULE_TITLE.CALISTA_ADVISORY_PARTY_CONFIG)
		let partyCiaConfigHeader = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.TABS.CIA_CONFIG)
		let partyProductListHeader = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.TABS.PRODUCT_LIST)

		return {
			title,
			partySummarySectionHeader,
			partyConfigSectionHeader,
			partyCiaConfigHeader,
			partyProductListHeader
		}
	}

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Card>
					<CngSimpleCardHeader title={translatedTextsObject.partySummarySectionHeader} />
					<Divider />
					<CardContent>
						<PartySummaryViewForm
							showNotification={showNotification}
							id={id}
						/>
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12}>
				<Card>
					<CngSimpleCardHeader title={translatedTextsObject.partyConfigSectionHeader} />
					<Divider />
					<CngTabs
						headerColor='primary'
						tabs={[
							{
								tabName: translatedTextsObject.partyCiaConfigHeader,
								tabContent: (
									<CiaConfigViewForm
										showNotification={showNotification}
										id={id}
										onFetchPreSuccess={(datum) => { 
											//do nothing
										}}
									/>
								)
							},
							{
								tabName: translatedTextsObject.partyProductListHeader,
								tabContent: (
									<ProductListViewForm 
										showNotification={showNotification}
										id={id}
									/>
								)
							}
						]}
					/>
					{/* </CardContent> */}
				</Card>
			</Grid>
		</Grid>
		//* End JSX ------------------------------------------------------------

	);
	//* End of function --------------------------------------------------------
}

export default ViewPage