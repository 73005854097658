import React from 'react'
import { BasePage as CngBasePage, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import CalistaAdvisoryKey from 'src/constants/locale/key/CalistaAdvisory'
import BreadCrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_CalistaAdvisory'
import CiaPartyConfigTablePage from './CiaPartyConfig_TablePage'
import CiaPartyConfigViewPage from './CiaPartyConfig_ViewPage'
import CiaPartyConfigEditPage from './CiaPartyConfig_EditPage'

function ModuleTitle(){
    const { translate } = useTranslation(Namespace.CALISTA_ADVISORY)
    let title = translate(Namespace.CALISTA_ADVISORY, CalistaAdvisoryKey.SUB_MODULE_TITLE.CALISTA_ADVISORY_PARTY_CONFIG)

    return (title)
}

//* Wrapped Table Page
function WrappedTablePage(props){
    const breadcrumbNameMap = BreadCrumbNameMap()
    return (
        <CngBasePage renderPage={(showSnackBar) => (
            <CiaPartyConfigTablePage showNotification={showSnackBar} {...props}/>
        )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
            moduleTitle={ModuleTitle()}
        />
    )
}

//* Wrapped View Page
function WrappedViewPage(props){
    const breadcrumbNameMap = BreadCrumbNameMap()
    return (
        <CngBasePage renderPage={(showSnackBar) =>(
            <CiaPartyConfigViewPage showNotification={showSnackBar} {...props}/>
        )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
            moduleTitle={ModuleTitle()}
        />
    )
}

function WrappedEditPage(props){
    const breadcrumbNameMap = BreadCrumbNameMap()
    return (
        <CngBasePage renderPage={(showSnackBar) => (
            <CiaPartyConfigEditPage showNotification={showSnackBar} {...props}/>
        )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
            moduleTitle={ModuleTitle()}
        />
    )
}

export {
    WrappedTablePage as TablePage,
    WrappedViewPage as ViewPage,
    WrappedEditPage as EditPage
}