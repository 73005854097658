import React from 'react'
import { Card, Divider, CardContent, Grid } from '@material-ui/core'
import { components, constants, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import CiaAdminMassRecommendApiUrls from 'src/apiUrls/cia-admin/CiaAdminMassRecommendApiUrls'
import CiaKeys from 'src/constants/locale/key/CalistaAdvisory'

const {
	card: { CngSimpleCardHeader },
	table: { CngCrudTable, useDefaultNotification, DateTimeRangeFilter, useFetchCustomLookup }
} = components
const { locale: { key: { UiComponentKeys } } } = constants

function TablePage(props) {

	const { location: { pathname }, showNotification } = props
	const notification = useDefaultNotification(showNotification)
	const fetchCustomLookup = useFetchCustomLookup()

	// Translations ------------------------------------------------------------
	const ciaNamespace = Namespace.CALISTA_ADVISORY
	const { translate } = useTranslation(ciaNamespace, Namespace.UI_COMPONENT)

	let CiaFieldKeys = CiaKeys.FIELDS

	let title = translate(Namespace.CALISTA_ADVISORY, CiaKeys.SUB_MODULE_TITLE.CIA_ADMIN_MASS_RECOMMEND)
	let tableTitle = translate(Namespace.UI_COMPONENT, UiComponentKeys.Table.TITLE, { nameTitleised: title })

	const Labels = {
		TABLE_TITLE: tableTitle,
		PARTY: translate(ciaNamespace, CiaFieldKeys.PARTY),
		STATUS: translate(ciaNamespace, CiaFieldKeys.STATUS),
		COMPLETED_COUNT: translate(ciaNamespace, CiaFieldKeys.COMPLETED_COUNT),
		TOTAL_COUNT: translate(ciaNamespace, CiaFieldKeys.TOTAL_COUNT),
		HNG_TRACKING_REF: translate(ciaNamespace, CiaFieldKeys.HNG_TRACKING_REF),
		HNG_SYNC_STATUS: translate(ciaNamespace, CiaFieldKeys.HNG_SYNC_STATUS),
		CREATED_DATE: translate(ciaNamespace, CiaFieldKeys.CREATED_DATE)
	}

	//* Table Columns ----------------------------------------------------------
	const columns = [
		{
			field: 'partyId', title: Labels.PARTY,
			customLookup: () => {
				return fetchCustomLookup(
					CiaAdminMassRecommendApiUrls.TPR_PARTY_GET,
					{},
					'content',
					'name',
					'id',
					(errorMessage) => console.error(errorMessage)
				)
			}
		},
		{
			field: 'status', title: Labels.STATUS,
			lookup: {
				PENDING: "PENDING",
				PROCESSING: "PROCESSING",
				COMPLETED: "COMPLETED",
				ERROR: "ERROR"
			}
		},
		{ field: 'totalCount', title: Labels.TOTAL_COUNT, type: 'numeric' },
		{ field: 'hngTrackingRef', title: Labels.HNG_TRACKING_REF },
		{
			field: 'hngSyncStatus', title: Labels.HNG_SYNC_STATUS,
			lookup: {
				PROCESSING: "PROCESSING",
				PENDING_DOWNLOAD: "PENDING_DOWNLOAD",
				COMPLETED: "COMPLETED"
			}
		},
		{
			field: 'createdDate', title: Labels.CREATED_DATE,
			type: "datetime", defaultSort: 'desc',
			filterComponent: DateTimeRangeFilter
		}
	];

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<Card>
			<CngSimpleCardHeader title={Labels.TABLE_TITLE} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<CngCrudTable {...props}
							notification={notification}

							// table fetch and data details
							fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
							fetch={{ url: CiaAdminMassRecommendApiUrls.GET_REQUEST }}
							idAccessor='id'
							columns={columns}

							// view route
							viewRoute={`${pathname}/view`}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
		//* End JSX ------------------------------------------------------------
	)

	//* End of function --------------------------------------------------------
}

export default TablePage
