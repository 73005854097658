import React from 'react'
import { components } from 'cng-web-lib'
import FormProperties from './CeCPartyConfig_PartySummaryFormProperties'
import PartyConfigUrls from "../../../../apiUrls/cec-admin/party-config/PartyConfigUrls";

const { form: { CngViewForm } } = components

function ViewForm({ history, showNotification, id }) {

    return (

        //* Start JSX ----------------------------------------------------------
        <CngViewForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            history={history}
            showNotification={showNotification}
            bodySection={
                <FormProperties.Fields
                    disabled={true}
                    showNotification={showNotification}
                    id={id}
                />
            }
            formikProps={FormProperties.formikProps}
            toClientDataFormat={FormProperties.toClientDataFormat}
            toServerDataFormat={FormProperties.toServerDataFormat}
            fetch={{
                url: PartyConfigUrls.TPR_PARTY_GET,
                id: id
            }}
        />
        //* End JSX ------------------------------------------------------------
    )

}

export default ViewForm
