import FormProperties from './FormProperties'
import React from 'react'
import { components } from 'cng-web-lib'
import ConnectorType from '../../../../../constants/cec/ConnectorType'
import Urls from '../../../../../apiUrls/cec-admin/shipment-provider/ShipmentProviderConnectorMappingUrls'

const {
  form: {
    CngViewForm
  }
} = components


function ViewForm({ showNotification, connectorRecord, connectorType, mappingId }) {

  return (

    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      showNotification={showNotification}
      renderBodySection={() =>
        <FormProperties.Fields
          disabled={true}
          connectorRecord={connectorRecord}
          connectorType={connectorType}
          showNotification={showNotification}
          id={mappingId}
        />
      }
      formikProps={connectorType === ConnectorType.CustomerApplication ? {
        ...FormProperties.formikPropsForCustomerConfig
      } : {
        ...FormProperties.formikPropsForCourierConfig
      }}
      toClientDataFormat={FormProperties.toClientDataFormat}
      toServerDataFormat={FormProperties.toServerDataFormat}
      fetch={{
        url: `${Urls.GET}`,
        id: parseInt(mappingId)
      }}
    />

  )

}

export default ViewForm