import React from 'react'
import Namespace from '../../../../constants/locale/Namespace'
import ShipmentProviderKeys from '../../../../constants/locale/key/cec/ShipmentProviderKeys'
import { constants, useTranslation } from 'cng-web-lib'

const NAMESPACE = Namespace.SHIPMENT_PROVIDERS

const {
  locale: {
    key: {
      UiComponentKeys
    }
  }
} = constants

const TranslationText = () => {

  const { translate } = useTranslation([NAMESPACE])

  const moduleTitle = translate(NAMESPACE, ShipmentProviderKeys.MODULE_TITLE)
  const titleShipmentProviderList = translate(NAMESPACE, ShipmentProviderKeys.SHIPMENT_PROVIDER_LIST)
  const titleShipmentProviderCreate = translate(NAMESPACE, ShipmentProviderKeys.CREATE_SHIPMENT_PROVIDER)
  const titleShipmentProviderEdit = translate(NAMESPACE, ShipmentProviderKeys.EDIT_SHIPMENT_PROVIDER)


  //Shipment Provider
  const shipmentProviderName = translate(NAMESPACE, ShipmentProviderKeys.SHIPMENT_PROVIDER_NAME)
  const shipmentProviderCode = translate(NAMESPACE, ShipmentProviderKeys.SHIPMENT_PROVIDER_CODE)
  const isActive = translate(NAMESPACE, ShipmentProviderKeys.IS_ACTIVE)
  const isManagedByGeTS = translate(NAMESPACE, ShipmentProviderKeys.IS_MANAGED_BY_GETS)
  const packageTypes = translate(NAMESPACE, ShipmentProviderKeys.PACKAGE_TYPES)

  const defaultShipperDetails = translate(NAMESPACE, ShipmentProviderKeys.SHIPPER_DETAILS_TITLE)
  const defaultConsigneeDetails = translate(NAMESPACE, ShipmentProviderKeys.CONSIGNEE_DETAILS_TITLE)

  // Tabs
  const shipmentProviderDetails = translate(NAMESPACE, ShipmentProviderKeys.SHIPMENT_PROVIDER_DETAILS)
  const connectorConfigurations = translate(NAMESPACE, ShipmentProviderKeys.CONNECTOR_CONFIGURATIONS)
  const tabLockedMessage = translate(Namespace.UI_COMPONENT, UiComponentKeys.Tabs.PLEASE_SUBMIT_FIRST, {
    title: shipmentProviderDetails
  })

  return {
    moduleTitle,
    titleShipmentProviderList,
    titleShipmentProviderCreate,
    titleShipmentProviderEdit,
    shipmentProviderName,
    shipmentProviderCode,
    isActive,
    isManagedByGeTS,
    packageTypes,
    shipmentProviderDetails,
    tabLockedMessage,
    defaultShipperDetails,
    defaultConsigneeDetails,
    connectorConfigurations
  }
}

export default TranslationText


