import { Box, Grid, Typography } from '@material-ui/core'

import CalistaUiComponentTranslationText from '../CalistaUiComponentTranslationText'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import React from 'react'
import Utils from 'src/views/common/utils/Utils'
import { components } from 'cng-web-lib'

const { CngGridItem } = components

function AccordionHeaderComponent(props) {
  const translatedTextsObject = CalistaUiComponentTranslationText()
  const fbTranslatedTextsObject = FreightBookingTranslationText()

  return (
    <Grid container xs={12}>
      <Grid xs={6}>
        <Typography variant='h5' className='font-bolder'>
          {props.title}
        </Typography>
        {props.parties ? (
          <Box fontSize={10} paddingBottom={2}>
            <span style={{ color: 'red' }}>*</span>
            {"Displayed parties are mandatory"}
          </Box>
        ) : null}
        {!props.hideMandatory ? (
          <Box fontSize={10} paddingBottom={2}>
            <span style={{ color: 'red' }}>*</span>
            {translatedTextsObject.mandatoryFieldsWithoutAsterisk}
          </Box>
        ) : null}
      </Grid>
      {props.freightBookingId ? (
        <Grid xs={6} sm={6} container justify='flex-end'>
          <Box fontSize='fontSize' fontWeight='fontWeightBold'>
            {fbTranslatedTextsObject.freightBookingId +
              ' : ' +
              Utils.getDashIfNullOrEmpty(props.freightBookingId)}
          </Box>
        </Grid>
      ) : (
        ''
      )}
    </Grid>
  )
}
export default AccordionHeaderComponent
