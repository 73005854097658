import { Box, Card, CardContent, CircularProgress, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import { useLocation, useParams } from 'react-router-dom'
import TranslationText from './TranslationText'
import PartyMappingTranslationText from '../party-mapping/TranslationText'
import PartyInformationEditForm from '../default-contact-information/forms/EditForm'
import PartyInformationAddForm from '../default-contact-information/forms/AddForm'
import ShipmentProviderEditForm from './forms/EditForm'
import ShipmentProviderDefaultPartyUrls
  from '../../../../apiUrls/cec-admin/shipment-provider/ShipmentProviderDefaultPartyUrls'
import ShipmentProviderPartyMappingCrudTable from '../party-mapping/CrudTable'
import AccordionHeaderComponent from '../../../common/ui/AccordionHeaderComponent'
import ConnectorConfigurationPanel from '../connector-mapping/ConnectorConfigurationPanel'
import ConnectorType from '../../../../constants/cec/ConnectorType'
import DefaultPartyType from '../../../../constants/cec/DefaultPartyType'
import SCOAdminApiUrls from '../../../../apiUrls/SCOAdminApiUrls'


const {
  CngGridItem, CngTabs
} = components

const {
  filter: {
    EQUAL
  },
  NotificationType
} = constants

function EditPage({ history, showNotification }) {

  const { fetchRecords } = useServices()
  const { shipmentProviderId } = useParams()
  const [shipperContactDetailsId, setShipperContactDetailsId] = useState()
  const [consigneeContactDetailsId, setConsigneeContactDetailsId] = useState()
  const [connectorAppTypes, setConnectorAppTypes] = useState(null)
  const location = useLocation()
  const {
    shipmentProviderDetails, defaultShipperDetails, defaultConsigneeDetails, connectorConfigurations
  } = TranslationText()


  const { allowedParties } = PartyMappingTranslationText()

  const onTabChange = indexOfTheActiveTab => {
    history.replace({
      pathname: location.pathname,
      state: { indexOfTheActiveTab: indexOfTheActiveTab }
    })
  }

  useEffect(() => {
    fetchRecords.execute(
      SCOAdminApiUrls.GET_CODE_MASTER_LIST,
      {
        filters: [
          {
            field: 'codeType',
            operator: EQUAL,
            value: 'CEC_CONNECTOR_APP_TYPE'
          }
        ]
      },
      (data) => {
        if (data.content) {
          setConnectorAppTypes(data.content.map(type => (({ code, id }) => ({ code, id }))(type)))
        }
      }, () => {
        showNotification(NotificationType.ERROR, 'Error while retrieving connector types.')
      }
    )
  }, [shipmentProviderId])

  const getIndexOfActiveTab = () => {
    return location.state && location.state.indexOfTheActiveTab ? location.state.indexOfTheActiveTab : 0
  }

  const ContactDetailsForm = (props) => {
    const contactId = props.contactId
    const partyType = props.partyType

    if (contactId === undefined) {
      return (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <CircularProgress />
        </Box>)
    } else if (contactId === null && DefaultPartyType.Consignee === partyType) {
      return (
        <PartyInformationAddForm
          showNotification={showNotification}
          history={history}
          isViewOnly={false}
          partyType={DefaultPartyType.Consignee}
          setConsigneeContactDetailsId={setConsigneeContactDetailsId}
          shipmentProviderId={shipmentProviderId}
        />
      )
    } else if (contactId === null && DefaultPartyType.Shipper === partyType) {
      return (
        <PartyInformationAddForm
          showNotification={showNotification}
          history={history}
          isViewOnly={false}
          partyType={DefaultPartyType.Shipper}
          setShipperContactDetailsId={setShipperContactDetailsId}
          shipmentProviderId={shipmentProviderId}
        />
      )
    } else {
      return (
        <PartyInformationEditForm
          key={contactId}
          showNotification={showNotification}
          contactDetailsId={contactId}
          history={history}
          partyType={partyType}
        />)
    }
  }


  useEffect(() => {
    fetchRecords.execute(
      ShipmentProviderDefaultPartyUrls.GET_IDS_BY_SHIPMENT_PROVIDER_ID,
      { customData: { 'shipmentProviderId': shipmentProviderId } },
      (data) => {
        if (data && data.length > 0 && data.filter(con => con.partyType === DefaultPartyType.Consignee).length > 0) {
          setConsigneeContactDetailsId(data.filter(con => con.partyType === DefaultPartyType.Consignee)[0].contactId)
        } else {
          setConsigneeContactDetailsId(null)
        }
        if (data && data.length > 0 && data.filter(con => con.partyType === DefaultPartyType.Shipper).length > 0) {
          setShipperContactDetailsId(data.filter(con => con.partyType === DefaultPartyType.Shipper)[0].contactId)
        } else {
          setShipperContactDetailsId(null)
        }
      }, () => {
        showNotification(NotificationType.ERROR, 'Error while retrieving shipper & consignee details.')
      }
    )
  }, [shipmentProviderId])

  const getConnectorTypeId = connectorType => {
    return connectorAppTypes.filter(type => type.code === connectorType).at(0)['id']
  }

  return (

    <Grid container spacing={1}>
      <CngGridItem item xs={12}>
        <CngTabs
          activeTabIndex={getIndexOfActiveTab()} onChange={onTabChange}
          headerColor='primary'
          tabs={[{
            tabName: shipmentProviderDetails, tabContent: (

              <Grid container spacing={3}>

                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <ShipmentProviderEditForm
                        shipmentProviderId={shipmentProviderId}
                        showNotification={showNotification}
                        history={history}
                      />
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Grid container>
                        <AccordionHeaderComponent
                          title={allowedParties} hideMandatory />
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <ShipmentProviderPartyMappingCrudTable
                            shipmentProviderId={parseInt(shipmentProviderId)}
                            isViewOnly={false}
                            showNotification={showNotification}
                          />
                        </Grid>
                      </Grid>

                    </CardContent>
                  </Card>
                </Grid>

              </Grid>

            )
          }, {
            tabName: defaultShipperDetails, tabContent: (

              <Grid container spacing={3}>

                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <ContactDetailsForm
                        contactId={shipperContactDetailsId}
                        partyType={DefaultPartyType.Shipper} />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>)
          }, {
            tabName: defaultConsigneeDetails, tabContent: (

              <Grid container spacing={3}>

                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <ContactDetailsForm
                        contactId={consigneeContactDetailsId}
                        partyType={DefaultPartyType.Consignee} />
                    </CardContent>
                  </Card>

                </Grid>

              </Grid>)
          }, {
            tabName: connectorConfigurations, tabContent: (<Grid container spacing={3}>

              {connectorAppTypes === null ? null :
                <>
                  <Grid item xs={12}>
                    <ConnectorConfigurationPanel showNotification={showNotification}
                                                 shipmentProviderId={shipmentProviderId}
                                                 isViewOnly={false} connectorType={ConnectorType.ShipperApplication}
                                                 connectorTypeId={getConnectorTypeId(ConnectorType.ShipperApplication)} />
                  </Grid>


                  <Grid item xs={12}>
                    <ConnectorConfigurationPanel showNotification={showNotification}
                                                 shipmentProviderId={shipmentProviderId}
                                                 isViewOnly={false} connectorType={ConnectorType.CustomerApplication}
                                                 connectorTypeId={getConnectorTypeId(ConnectorType.CustomerApplication)} />
                  </Grid>

                  <Grid item xs={12}>
                    <ConnectorConfigurationPanel showNotification={showNotification}
                                                 shipmentProviderId={shipmentProviderId}
                                                 isViewOnly={false} connectorType={ConnectorType.FulfilmentApplication}
                                                 connectorTypeId={getConnectorTypeId(ConnectorType.FulfilmentApplication)} />
                  </Grid>


                </>

              }


            </Grid>)
          }]}
        />
      </CngGridItem>
    </Grid>)
}

export default EditPage