import React from 'react'
import { Line } from 'react-chartjs-2';


export default function AreaChart(props) {
    const labels = props.graphData.dataLabel

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Line Chart',
            },
        },
    }

    const data = {
        labels ,
        datasets: [
            {
                fill: true,
                label: 'Landed cost',
                data: props.graphData.data.lcc ,
                borderColor: 'rgb(0, 75, 143)',
                backgroundColor: 'rgba(0, 75, 143, 0.8)',
            },
            {
                fill: true,
                label: 'eAdvisory',
                data: props.graphData.data.eadv,
                borderColor: 'rgb(0, 107, 201)',
                backgroundColor: 'rgba(0, 107, 201, 0.8)',
            },
            {
                fill: true,
                label: 'HS Code recomm.',
                data: props.graphData.data.hs,
                borderColor: 'rgb(101, 180, 243)',
                backgroundColor: 'rgba(101, 180, 243, 0.8)',
            },
        ],
    };

    return (
        <Line options={options} data={data} />
    )
}
