import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
    Button,
    Card,
    Divider,
    FormControlLabel,
    ListItemText,
    makeStyles,
    Paper, Radio,
    RadioGroup
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FormControl from "@material-ui/core/FormControl";
import { KeyboardDatePicker } from "@material-ui/pickers";
import CompanyDropdown from "./components/CompanyDropdown";
import AreaChart from "./components/AreaChart";
import CngBackdrop from "../../vesselschedule/searchschedule/cngcomponent/CngBackDrop";
import moment from "moment";
import { CIAApiUrls } from "../../../apiUrls";
import { useServices } from 'cng-web-lib'
import BarChart from "./components/BarChart";
import PieChart from "./components/PieChart";
import {useHistory, useLocation} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    textBold: {
        fontWeight: 'bold'
    },
    blueFont: {
        fontWeight: 'bold',
        color: '#65B4F3',
    },
    greenFont: {
        fontWeight: 'bold',
        color: '#7CE7AC',
    },
    dateStyle: {
        fontWeight: 'bold',
        marginBottom: theme.spacing(1)
    },
    marginBottom2: {
        marginBottom: theme.spacing(2),
    },
    badgeIcon: {
        width: 'fit-content',
        background: '#E2F8EDFF',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: theme.spacing(1)
    },
    cardHeader: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        columnGap: theme.spacing(2)
    },
    headerItem: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexGrow: 1
    },
    cardItem: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexBasis: '25%'
    },
    filterItem: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: theme.spacing(2),
    },
    filterHeader: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
        columnGap: theme.spacing(0.5)
    },
    filterTitle: {
        flexGrow: 1,
        maxWidth: '100%',
        flexBasis: 0,
        '& > svg': { margin: theme.spacing(0, 3) }
    },
    buttonTransform: {
        textTransform: 'none',
    },
    marginZero: {
        margin : 0
    }
}))

function AdminAcctMgmtIndexPage() {
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()

    const [loading, setLoading] = React.useState(false)
    const [selectedPartyName, setSelectedPartyName] = React.useState([])

    const [filterInput, setFilterInput] = React.useState(
        {
            dateValue: '365',
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            selectedPartyList: []
        })


    const [data, setData] = React.useState(
        {
            activePaidParty: 0,
            totalParty:0,
            monthCount:0,
            monthPercentageCount:0,
            weekCount:0,
            weekPercentageCount:0,
            ciaPartyList:[],
            allCountRecord:[]
        })

    const [graphData, setGraphData] = React.useState({ dataLabel:[], data:[] })

    const { securedSendRequest } = useServices()
    const input = location.state.input

    useEffect(() => {
        if(input !== undefined)
            setFilterInput(input)

        fetchData(input)
        history.replace({ state: {} })
    }, [])

    const fetchData = (input) => {
        setLoading(true)

        let requestInput = filterInput
        if(input !== undefined)
            requestInput = input

        let url = CIAApiUrls.GET_ADMIN_MGMT_DETAIL_V2

        let startDate = requestInput.startDate
        let endDate = moment(requestInput.endDate).add(1, 'd')

        if(requestInput.dateValue != 'custom') {
            startDate = getDateByValue(requestInput.dateValue)
            endDate = moment()
        }

        let inputField = {
            partyIds: requestInput.selectedPartyList.map(party => party.id),
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD')
        }

        securedSendRequest.execute("POST", url, inputField,
            (response) => {
                console.log(response)
                setData( {...data, ...response.data} )
                if(response.data.companyView)
                    getBarData(response.data, startDate, endDate)
                else
                    getGraphData(response.data, startDate, endDate)
            },
            (error) => {
                console.error(error)
                setLoading(false)
            },
            () => {  setLoading(false) }
        )
    }

    const getDateByValue = (value) => {
        return moment().subtract(parseInt(value), 'd')
    }

    const getTotalCount = (module) => {
        let totalCount = 0

        if(module === 'all') {
            data.allCountRecord.forEach(r => totalCount += r.countDetail.reduce((sum, obj) => sum + obj.count, 0))
        } else {
            let moduleCount = data.allCountRecord.find(r => r.moduleCode === module)
            if(moduleCount !== undefined && moduleCount.countDetail !== undefined)
                totalCount = moduleCount.countDetail.reduce((sum, obj) => sum + obj.count, 0)
        }

        return totalCount
    }

    const getBarData = (resData, startDate, endDate) => {

        let dateFormat = getDateFormat(moment(startDate), moment(endDate))
        let intervals = getTimeInterval(moment(startDate), moment(endDate), dateFormat, 15)

        let hsData = []
        let lccData = []
        let eadvData = []
        let allData = []
        let partyNameList = []

        resData.ciaPartyList.filter(party => party.countRecord != null).forEach(party => {
            party.countRecord.forEach(r => {
                let countArray = []
                countArray.push(getCountByDate(r, intervals[0], intervals[0], dateFormat))
                for(let i = 0 ; i < intervals.length-1; i++) {
                    countArray.push(getCountByDate(r, intervals[i], intervals[i+1], dateFormat))
                }

                if(r.moduleCode === 'lcc')
                    lccData.push( { party : party.name, count : countArray } )
                else if(r.moduleCode === 'hs')
                    hsData.push( { party : party.name, count : countArray } )
                else if(r.moduleCode === 'eadv')
                    eadvData.push( { party : party.name, count : countArray } )

            })

            allData.push( { party : party.name, count: hsData.find(data => data.party == party.name).count.map((element, index) => element + lccData.find(data => data.party == party.name).count[index] + eadvData.find(data => data.party == party.name).count[index])} )
            partyNameList.push(party.name)
        })

        let barData = { all : allData, hs : hsData, lcc : lccData, eadv : eadvData }

        setSelectedPartyName(partyNameList)
        setGraphData({ dataLabel:intervals, data:barData } )

    }

    const getGraphData = (resData, startDate, endDate) => {

        let dateFormat = getDateFormat(moment(startDate), moment(endDate))
        let intervals = getTimeInterval(moment(startDate), moment(endDate), dateFormat, 15)

        let graphCountData = { lcc : [], hs : [], eadv : [] }

        resData.allCountRecord.forEach(r => {
            let countArray = []

            countArray.push(getCountByDate(r, intervals[0], intervals[0], dateFormat))
            for(let i = 0 ; i < intervals.length-1; i++) {
                countArray.push(getCountByDate(r, intervals[i], intervals[i+1], dateFormat))
            }

            if(r.moduleCode === 'lcc')
                graphCountData = { ...graphCountData, lcc: countArray }
            else if(r.moduleCode === 'hs')
                graphCountData = { ...graphCountData, hs: countArray }
            else if(r.moduleCode === 'eadv')
                graphCountData = { ...graphCountData, eadv: countArray }
        })

        setGraphData({ dataLabel:intervals, data:graphCountData } )
    }

    const getDateFormat = (resultStartDate, resultEndDate) => {
        let format = 'DD MMM YY'
        if(resultEndDate.diff(resultStartDate, "days") > 90)
            format = 'MMM YY'

        return format
    }

    const getTimeInterval = (resultStartDate, resultEndDate, format, numberOfIntervals) => {
        let diff = resultEndDate.unix() - resultStartDate.unix()

        let intervalLength = diff/(numberOfIntervals-1);
        let intervals = [];
        intervals.push(moment.unix(resultStartDate.unix()).format(format))

        for(let i = 1 ; i <= numberOfIntervals-2; i++)
            intervals.push(moment.unix(resultStartDate.unix()+i*intervalLength).format(format))

        intervals.push(moment(resultEndDate).subtract(1, 'days').format(format))

        return [...new Set(intervals)]
    }

    const getCountByDate = (countData, startDate, endDate, format) => {
        let sum = countData.countDetail.reduce((total, obj) => {
            let countDate = moment(obj.date).format(format)
            if(startDate == endDate && countDate == startDate)
                return total + obj.count
            if(format === 'DD MMM YY' && moment(countDate, format).isBetween(moment(startDate, format), moment(endDate, format), null, '(]'))
                return total + obj.count
            if(format === 'MMM YY' && moment(countDate, format).isBetween(moment(startDate, format), moment(endDate, format), 'month', '(]'))
                return total + obj.count
            return total
        }, 0)

        return sum
    }

    const handleChange = (event) => {
        setFilterInput( {...filterInput, dateValue: event.target.value} )
    }

    const clearAll = () => {
        setFilterInput({ dateValue: '365', startDate: moment().format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD'), selectedPartyList: [] })
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={9}>
                <Box className={classes.cardHeader}>
                    <Box className={classes.headerItem} component={Card} px={3} py={1.5} >
                        <ListItemText classes={{ primary: classes.textBold }} primary="Active Paid Parties" secondary="In past year" />
                        <Typography className={classes.blueFont} variant="h2">{data.activePaidParty}</Typography>
                    </Box>

                    <Box className={classes.headerItem} component={Card} px={3} py={1.5} >
                        <ListItemText classes={{ primary: classes.textBold }} primary="Total CIA Parties" secondary="In past year" />
                        <Typography className={classes.blueFont} variant="h2">{data.totalParty}</Typography>
                    </Box>

                    <Box className={classes.headerItem} component={Card} px={3} py={1.5} >
                        <ListItemText classes={{ primary: classes.textBold }} primary="Last month’s transactions" secondary="" />
                        <Typography className={classes.greenFont} variant="h2">{data.monthCount}</Typography>
                        <Box component={Paper} px={1} my={1} className={classes.badgeIcon}>
                            { data.monthPercentage >= 0 ?
                                <FontAwesomeIcon icon={['fal', 'circle-arrow-up']} /> :
                                <FontAwesomeIcon icon={['fal', 'circle-arrow-down']} />
                            }
                            <Typography sx={{ marginLeft: '4px' }} variant="body2" >{ data.monthPercentage }%</Typography>
                        </Box>
                        <ListItemText primary="" secondary="From previous month" />
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={3}>
                <Box className={classes.cardItem} component={Card} px={3} py={1.5} >
                    <ListItemText classes={{ primary: classes.textBold }} primary="Last 7 days’ transactions" secondary="" />
                    <Typography className={classes.greenFont} variant="h2">{data.weekCount}</Typography>
                    <Box component={Paper} px={1} my={1} className={classes.badgeIcon}>
                        { data.weekPercentage >= 0 ?
                            <FontAwesomeIcon icon={['fal', 'circle-arrow-up']} /> :
                            <FontAwesomeIcon icon={['fal', 'circle-arrow-down']} />
                        }
                        <Typography variant="body2" >{ data.weekPercentage }%</Typography>
                    </Box>
                    <ListItemText primary="" secondary="From previous 7 days" />
                </Box>
            </Grid>
            { !data.companyView ?
                <Grid item xs={9}>
                    <Box className={classes.cardHeader}>
                        <Box className={classes.cardItem} component={Card} px={3} py={1.5} >
                            <ListItemText classes={{ primary: classes.textBold }} primary="HS Smart search transactions" secondary="" />
                            <Typography className={classes.greenFont} variant="h2">{ getTotalCount('hs') }</Typography>
                        </Box>
                        <Box className={classes.cardItem} component={Card} px={3} py={1.5} >
                            <ListItemText classes={{ primary: classes.textBold }} primary="eAdvisory transactions" secondary="" />
                            <Typography className={classes.greenFont} variant="h2">{ getTotalCount('eadv') }</Typography>
                        </Box>
                        <Box className={classes.cardItem} component={Card} px={3} py={1.5} >
                            <ListItemText classes={{ primary: classes.textBold }} primary="Landed cost transactions" secondary="" />
                            <Typography className={classes.greenFont} variant="h2">{ getTotalCount('lcc') }</Typography>
                        </Box>
                        <Box className={classes.cardItem} component={Card} px={3} py={1.5} >
                            <ListItemText classes={{ primary: classes.textBold }} primary="Total transactions" secondary="" />
                            <Typography className={classes.greenFont} variant="h2">{ getTotalCount('all') }</Typography>
                        </Box>
                    </Box>
                    <Box className={classes.cardItem} component={Card} mt={2} px={3} py={3} >
                        <Typography className={classes.dateStyle} variant="h4" >Total transactions by type</Typography>
                        <AreaChart graphData={graphData}/>
                    </Box>
                </Grid> :
                <Grid item xs={9}>
                    <Box className={classes.cardHeader}>
                        <Box className={classes.cardItem} component={Card} px={3} py={3} >
                            <Typography className={classes.dateStyle} variant="h5" >Total transactions</Typography>
                            <PieChart graphData={ {'dataLabel' : selectedPartyName, 'data' : graphData.data} } module={'all'}/>
                        </Box>
                        <Box className={classes.cardItem} component={Card} px={3} py={3} >
                            <Typography className={classes.dateStyle} variant="h5" >Toal HS Smart search transactions</Typography>
                            <PieChart graphData={ {'dataLabel' : selectedPartyName, 'data' : graphData.data} } module={'hs'}/>
                        </Box>
                        <Box className={classes.cardItem} component={Card} px={3} py={3} >
                            <Typography className={classes.dateStyle} variant="h5" >Total Landed cost transactions</Typography>
                            <PieChart graphData={ {'dataLabel' : selectedPartyName, 'data' : graphData.data} } module={'lcc'}/>
                        </Box>
                        <Box className={classes.cardItem} component={Card} px={3} py={3} >
                            <Typography className={classes.dateStyle} variant="h5" >Total eAdvisory transactions</Typography>
                            <PieChart graphData={ {'dataLabel' : selectedPartyName, 'data' : graphData.data} } module={'eadv'}/>
                        </Box>
                    </Box>

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Box className={classes.cardItem} component={Card} mt={2} px={3} py={3} >
                                <Typography className={classes.dateStyle} variant="h5" >Total transactions</Typography>
                                <BarChart graphData={graphData} module={'all'}/>
                            </Box>
                        </Grid>
                        <Grid item xs={6} >
                            <Box className={classes.cardItem} component={Card} mt={2} px={3} py={3} >
                                <Typography className={classes.dateStyle} variant="h5" >HS Smart search transactions</Typography>
                                <BarChart graphData={graphData} module={'hs'}/>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box className={classes.cardItem} component={Card} mt={2} px={3} py={3} >
                                <Typography className={classes.dateStyle} variant="h5" >Landed cost transactions</Typography>
                                <BarChart graphData={graphData} module={'lcc'}/>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box className={classes.cardItem} component={Card} mt={2} px={3} py={3} >
                                <Typography className={classes.dateStyle} variant="h5" >eAdvisory transactions</Typography>
                                <BarChart graphData={graphData} module={'eadv'}/>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            }

            <Grid item xs={3}>
                <Box className={classes.filterItem} component={Card} px={3} py={1.5}>
                    <Box className={classes.filterHeader} >
                        <Box className={classes.filterTitle}>
                            <Typography className={classes.textBold} variant="h4">Filters</Typography>
                        </Box>
                        <Button className={classes.buttonTransform} variant="outlined" color="primary" onClick={ () => clearAll() } >Clear all</Button>
                        <Button className={classes.buttonTransform} variant="contained" color="primary" onClick={ () => fetchData() } >Apply</Button>
                    </Box>
                    <Divider />
                    <FormControl>
                        <Typography className={classes.dateStyle} variant="h4" >Date</Typography>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={filterInput.dateValue}
                            onChange={handleChange}
                        >
                            <FormControlLabel value="7" control={<Radio />} label="Past week" />
                            <FormControlLabel value="30" control={<Radio />} label="Past month" />
                            <FormControlLabel value="90" control={<Radio />} label="Past semester" />
                            <FormControlLabel value="365" control={<Radio />} label="Past year" />
                            <FormControlLabel value="custom" control={<Radio />} label="Custom range" />
                        </RadioGroup>
                    </FormControl>
                    {filterInput.dateValue === 'custom' &&
                    <Box>
                        <KeyboardDatePicker
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            label="Starting Date"
                            format="YYYY-MM-DD"
                            value={filterInput.startDate}
                            InputAdornmentProps={{ position: "end" }}
                            onChange={ date => setFilterInput({...filterInput, startDate: date}) }
                            maxDate={filterInput.endDate}
                        />
                        <Box className={classes.marginBottom2} />
                        <KeyboardDatePicker
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            label="Ending Date"
                            format="YYYY-MM-DD"
                            value={filterInput.endDate}
                            InputAdornmentProps={{ position: "end" }}
                            onChange={ date => setFilterInput({...filterInput, endDate: date}) }
                            minDate={filterInput.startDate}
                            disableFuture
                        />
                    </Box> }
                    <Divider />
                    <Typography className={classes.dateStyle} variant="h4" >Company</Typography>
                    <CompanyDropdown filterInput={filterInput} setFilterInput={setFilterInput} partyList={data.ciaPartyList} />
                </Box>
            </Grid>

            <CngBackdrop loading={loading} />
        </Grid>

    )
}

export default AdminAcctMgmtIndexPage