import React from 'react'
import { components } from 'cng-web-lib'

import CiaConfigEditFormProperties from './CiaPartyConfig_CiaConfigEditFormProperties'
import CalistaAdvisoryConfigApiUrls from 'src/apiUrls/CalistaAdvisoryPartyConfigApiUrls'

const { form: {CngEditForm}} = components

function CiaConfigEditForm({history, showNotification, id, onFetchPreSuccess = () => {console.log("CiaConfigEditForm")}}){
    return(
        //* Start JSX
        <CngEditForm
			fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
			history={history}
			showNotification={showNotification}
			bodySection={
				<CiaConfigEditFormProperties.Fields
					showNotification={showNotification}
					id={id}
				/>
			}
			formikProps={CiaConfigEditFormProperties.formikProps}
			toClientDataFormat={CiaConfigEditFormProperties.toClientDataFormat}
			toServerDataFormat={CiaConfigEditFormProperties.toServerDataFormat}
			fetch={{ url: CalistaAdvisoryConfigApiUrls.GET,
			onPreSuccess: onFetchPreSuccess }}
			update={{ url: CalistaAdvisoryConfigApiUrls.PUT }}
			id={id}
		/>
    )
    //* End of Function
}

export default CiaConfigEditForm