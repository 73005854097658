import FormProperties from './FormProperties'
import ShipmentProviderDefaultPartyUrls
  from '../../../../../apiUrls/cec-admin/shipment-provider/ShipmentProviderDefaultPartyUrls'
import React from 'react'
import { components } from 'cng-web-lib'
import DefaultPartyType from '../../../../../constants/cec/DefaultPartyType'

const {
  button: {
    CngSecondaryButton
  }, form: {
    CngAddForm, CngEditFormButtonSection
  }, CngGridItem
} = components

function AddForm({
                   history,
                   showNotification,
                   partyType,
                   shipmentProviderId,
                   setShipperContactDetailsId,
                   setConsigneeContactDetailsId,
                   isViewOnly
                 }) {

  return (

    <CngAddForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <FormProperties.Fields
          disabled={isViewOnly}
          showNotification={showNotification}
          partyType={partyType}
        />
      }
      formikProps={{
        initialValues: {
          ...FormProperties.formikProps.initialValues,
          partyType: partyType,
          shipmentProviderId: shipmentProviderId
        },
        makeValidationSchema: FormProperties.formikProps.makeValidationSchema
      }}
      toClientDataFormat={FormProperties.toClientDataFormat}
      toServerDataFormat={FormProperties.toServerDataFormat}
      renderButtonSection={() => (
        isViewOnly ? null :
          <CngEditFormButtonSection>
            <CngGridItem>
              <CngSecondaryButton
                onClick={() => {
                  history.goBack()
                }}>
                Back
              </CngSecondaryButton>
            </CngGridItem>
          </CngEditFormButtonSection>
      )}
      create={{
        url: ShipmentProviderDefaultPartyUrls.CREATE,
        body: {
          partyType: partyType
        },
        onPostSubmitSuccess: (datum) => {
          if (partyType === DefaultPartyType.Consignee) {
            setConsigneeContactDetailsId(datum.id)
          } else if (partyType === DefaultPartyType.Shipper) {
            setShipperContactDetailsId(datum.id)
          }
        }
      }}
    />

  )

}

export default AddForm